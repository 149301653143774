import React, {ChangeEventHandler, FC, FocusEventHandler, useState, useEffect, useMemo} from "react";
import {FormikErrors} from "formik";
import Manifest, {
    ConnectionPoolType,
    ConnectionPoolUrl,
    ManifestBuildProp,
    MartiniProperties,
    ApiTypes,
    getConnectionPoolOptions,
    connectionPoolTypeToLabel
} from "../../../core/observables/Manifest";
import {Button, ButtonGroup, createStyles, TextField, FormControlLabel, Checkbox} from "@material-ui/core";
import Select from "react-select";
import {makeStyles} from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import useSession from "../../session/useSession";
import { NegroniIcon } from "../../icon/NegronIIcon";

const buildManifestFormStyles = makeStyles(theme => createStyles({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        },
    },
    properties: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1)
        },
    },
    groupStyles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    groupBadgeStyles: {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    },
    inputStyle: {
        width: '98%',
    },
}));

enum ConnectionPoolTypeLabel {
    PostgreSQL = 'PostgreSQL',
    MySQL = 'MySQL',
    HSQL = 'Hyper SQL',
    MSSQL = 'Microsoft SQL Server',
    THIN = 'Oracle Thin',
    OCI8 = 'Oracle OCI 8',
    OCI9 = 'Oracle OCI 9+',
}

interface BuildManifestFormProps {
    manifest: Manifest;
    values: MartiniProperties;
    setFieldValue?: (name: string, value: any, validate?: boolean) => void;
    handleBlur?: FocusEventHandler;
    handleChange?: ChangeEventHandler;
    errors?: FormikErrors<MartiniProperties>;
}

const BuildManifestForm: FC<BuildManifestFormProps> = ({manifest, values, setFieldValue, handleBlur, handleChange, errors}) => {
    const classes = buildManifestFormStyles();
    const [connectionTypeOptions, setConnectionTypeOptions] = useState<any>([]);
    const [validConnectionUrl, setValidConnectionUrl] = useState(false);
    const [recommendProperty, setRecommendProperty] = useState<string>('');
    const [entitiesList, setEntitiesList] = useState<string[]>([]);
    const session = useSession();
    const limits = session.limits();

    const formatGroupLabel = (data: any) => (
        <div className={classes.groupStyles}>
            <h2>{data.label}</h2>
            <span className={classes.groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    useEffect(() => {
        const finalApiOpts = [] as any;

        setConnectionTypeOptions(getConnectionPoolOptions(limits.allowedDb));

        Object.entries(ApiTypes)
            .map(([_, value]) => (finalApiOpts.push({label: value, value})));
    }, [])

    const setKeyProp = (key: string, index: number) => {
        const props = values?.packageProperties.customProperties;
        props[index] = {name: key, value: props[index].value};
        setFieldValue?.('packageProperties.customProperties', [...props]);
    }

    const setValueProp = (value: string, index: number) => {
        const props = values?.packageProperties.customProperties;
        if (props) {
            props[index] = {name: props[index].name, value} as ManifestBuildProp;
            setFieldValue?.('packageProperties.customProperties', [...props]);
        }
    }

    const handleAddProperty = () => {
        if (values?.packageProperties?.customProperties) {
            setFieldValue?.('packageProperties.customProperties', [...values.packageProperties.customProperties, {
                name: '',
                value: ''
            }] as ManifestBuildProp[]);
        }
    }

    const handleRemoveProperty = (index: number) => {
        if (values?.packageProperties?.customProperties) {
            setFieldValue?.('packageProperties.customProperties', values.packageProperties.customProperties.filter((prop, i) => i !== index));
        }
    }

    const needInfo = (connectionType: ConnectionPoolType) => {
        return connectionType === ConnectionPoolType.HSQL ||
        connectionType === ConnectionPoolType.MYSQL;
    }

    const propFields = useMemo(() => {
        const props = values?.packageProperties?.customProperties;
        return props?.map((prop, index) => {
            return <div className={classes.properties} key={index}>
                <TextField
                    style={{flexGrow: 1}}
                    margin='normal'
                    label='Name'
                    value={prop.name}
                    onChange={e => setKeyProp(e.target.value, index)}
                />
                <TextField
                    style={{flexGrow: 1}}
                    margin='normal'
                    label='Value'
                    value={prop.value}
                    onChange={e => setValueProp(e.target.value, index)}
                />
                <div>
                    <ButtonGroup>
                        {(props.length - 1) === index && <Button onClick={handleAddProperty}>
                            <NegroniIcon color='action' iconClass="add-square-icon" />
                        </Button>}
                        {index !== 0 && <Button onClick={_ => handleRemoveProperty(index)}>
                            <NegroniIcon iconClass="delete-icon" />
                        </Button>}
                    </ButtonGroup>
                </div>
            </div>
        });
    }, [values?.packageProperties.customProperties]);

    const isExisting = (entityName: string) => entitiesList.indexOf(entityName) !== -1;

    function handleEntityClick(entityName: string) {
        const selectedIndex = entitiesList.indexOf(entityName)
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(entitiesList, entityName);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(entitiesList.slice(1));
        } else if (selectedIndex === entitiesList.length - 1) {
            newSelected = newSelected.concat(entitiesList.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                entitiesList.slice(0, selectedIndex),
                entitiesList.slice(selectedIndex + 1),
            );
        }

        setEntitiesList(newSelected);
        setFieldValue?.('configurationBuild.serviceConfiguration.sotEntities', newSelected, true);
    }

    useEffect(() => {
        if (values?.packageProperties.connectionPoolInfo.type) {
            setFieldValue?.('packageProperties.connectionPoolInfo.url', ConnectionPoolUrl[values.packageProperties.connectionPoolInfo.type], true);
        }
    }, [values?.packageProperties.connectionPoolInfo.type]);

    useEffect(() => {
        switch(values?.packageProperties.connectionPoolInfo.type) {
            case ConnectionPoolType.HSQL:
                setValidConnectionUrl(values?.packageProperties.connectionPoolInfo.url.includes('sql.syntax_mys=true'));
                setRecommendProperty('sql.syntax_mys=true');
                break;
            case ConnectionPoolType.MYSQL:
                setValidConnectionUrl(values?.packageProperties.connectionPoolInfo.url.includes(`sessionVariables=sql_mode='ANSI'`));
                setRecommendProperty("sessionVariables=sql_mode='ANSI'");
                break;
            default:
                setValidConnectionUrl(true);
                break;
        }
    }, [values?.packageProperties.connectionPoolInfo.url]);

    return <form action='#' className={classes.root}>
        {(needInfo(values?.packageProperties.connectionPoolInfo.type) && !validConnectionUrl) && <Alert severity="info">
            We recommend adding this property <strong>{recommendProperty}</strong> to your connection URL
        </Alert>}
        <Select
            autoFocus
            options={connectionTypeOptions}
            placeholder='Connection Type'
            id='packageProperties.connectionPoolInfo.type'
            name='packageProperties.connectionPoolInfo.type'
            value={values?.packageProperties.connectionPoolInfo.type && ({
                label: connectionPoolTypeToLabel(values?.packageProperties.connectionPoolInfo.type),
                value: values?.packageProperties.connectionPoolInfo.type,
                parent: null,
            })}
            onChange={(option: any) => {
                setFieldValue?.('packageProperties.connectionPoolInfo.type', option?.value, true);
            }}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={option => {
                return <div style={{display: 'flex'}}>
                    {option?.parent !== null ? <div style={{paddingLeft: '10px'}}>
                        {option.label}
                    </div> : <div>
                        {option.label}
                    </div>}
                </div>;
            }}
            menuPortalTarget={document.body}
            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
            menuPlacement='auto'
            maxMenuHeight={200}
        />
        <TextField
            margin='dense'
            label='Connection Url'
            type='url'
            name='packageProperties.connectionPoolInfo.url'
            id='packageProperties.connectionPoolInfo.url'
            value={values?.packageProperties.connectionPoolInfo.url}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!errors?.packageProperties?.connectionPoolInfo?.url}
            helperText={errors?.packageProperties?.connectionPoolInfo?.url && errors?.packageProperties?.connectionPoolInfo?.url}
            className={classes.inputStyle}
        />
        <TextField
            margin='dense'
            label='Connection Username'
            name='packageProperties.connectionPoolInfo.username'
            id='packageProperties.connectionPoolInfo.username'
            value={values?.packageProperties.connectionPoolInfo.username}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!errors?.packageProperties?.connectionPoolInfo?.username}
            helperText={errors?.packageProperties?.connectionPoolInfo?.username && errors?.packageProperties?.connectionPoolInfo?.username}
            className={classes.inputStyle}
        />
        <TextField
            margin='dense'
            label='Connection Password'
            type='password'
            name='packageProperties.connectionPoolInfo.password'
            id='packageProperties.connectionPoolInfo.password'
            value={values?.packageProperties.connectionPoolInfo.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors?.packageProperties?.connectionPoolInfo?.password}
            helperText={errors?.packageProperties?.connectionPoolInfo?.password && errors?.packageProperties?.connectionPoolInfo?.password}
            className={classes.inputStyle}
        />
        <TextField
            margin='dense'
            label='Database Name'
            name='packageProperties.connectionPoolInfo.databaseName'
            id='packageProperties.connectionPoolInfo.databaseName'
            value={values?.packageProperties.connectionPoolInfo.databaseName}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!errors?.packageProperties?.connectionPoolInfo?.databaseName}
            helperText={errors?.packageProperties?.connectionPoolInfo?.databaseName && errors?.packageProperties?.connectionPoolInfo?.databaseName}
            className={classes.inputStyle}
        />
        <h4 style={{margin: ' 5px'}}>API Configuration</h4>
            <FormControlLabel
                className={classes.inputStyle}
                control={<Checkbox checked={values?.configurationBuild?.apiConfiguration?.type.includes(ApiTypes.REST) && limits.rs} 
                onChange={(e) => {
                    const { checked } = e.target;
                    const { type } = values.configurationBuild.apiConfiguration;
                            
                    if (checked) {
                      type.push(ApiTypes.REST);
                    } else {
                      values.configurationBuild.apiConfiguration.type = type.filter(
                        (type) => type !== ApiTypes.REST
                      );
                    }
                    setFieldValue?.("configurationBuild.apiConfiguration.type", values.configurationBuild.apiConfiguration.type, true);
                  }}
                inputProps={{'aria-label': 'Select REST API type'}}/>}
                name='configurationBuild.apiConfiguration.type'
                label="REST API"
                disabled={!limits.rs}
            />
            <FormControlLabel
                className={classes.inputStyle}
                control={<Checkbox checked={values?.configurationBuild?.apiConfiguration?.type.includes(ApiTypes.SOAP)} 
                onChange={(e) => {
                    const { checked } = e.target;
                    const { type } = values.configurationBuild.apiConfiguration;
                    
                    if (checked) {
                      type.push(ApiTypes.SOAP);
                    } else {
                      values.configurationBuild.apiConfiguration.type = type.filter(
                        (type) => type !== ApiTypes.SOAP
                      );
                    }
                    setFieldValue?.("configurationBuild.apiConfiguration.type", values.configurationBuild.apiConfiguration.type, true);
                }}
                inputProps={{'aria-label': 'Select SOAP API type'}}/>}
                name='configurationBuild.apiConfiguration.type'
                label="SOAP API"
            />
            <FormControlLabel
                className={classes.inputStyle}
                control={<Checkbox checked={values?.configurationBuild?.apiConfiguration?.type.includes(ApiTypes.GRAPHQL)} 
                onChange={(e) => {
                    const { checked } = e.target;
                    const { type } = values.configurationBuild.apiConfiguration;
                    
                    if (checked) {
                      type.push(ApiTypes.GRAPHQL);
                    } else {
                      values.configurationBuild.apiConfiguration.type = type.filter(
                        (type) => type !== ApiTypes.GRAPHQL
                      );
                    }
                    setFieldValue?.("configurationBuild.apiConfiguration.type", values.configurationBuild.apiConfiguration.type, true);
                }}
                inputProps={{'aria-label': 'Select GRAPHQL API type'}}/>}
                name='configurationBuild.apiConfiguration.type'
                label="GRAPHQL API"
                disabled={!limits.gqls}
            />
        <h4 style={{margin: ' 5px'}}>SQL Configuration</h4>
        <FormControlLabel
            className={classes.inputStyle}
            control={<Checkbox
                checked={values?.configurationBuild?.sqlConfiguration?.applyAutoIncrement}
                onChange={handleChange}
                name="configurationBuild.sqlConfiguration.applyAutoIncrement"/>}
            label="Apply Auto-Increment"
        />
        <FormControlLabel
            className={classes.inputStyle}
            control={<Checkbox
                checked={values?.configurationBuild?.sqlConfiguration?.applyCoalesce}
                onChange={handleChange}
                name="configurationBuild.sqlConfiguration.applyCoalesce"/>}
            label="Apply Coalesce"
        />
        <FormControlLabel
            className={classes.inputStyle}
            control={<Checkbox
                checked={values?.configurationBuild?.sqlConfiguration?.applyLimitOffset}
                onChange={handleChange}
                name="configurationBuild.sqlConfiguration.applyLimitOffset"/>}
            label="Apply Limit & Offset"
        />
        <FormControlLabel
            className={classes.inputStyle}
            control={<Checkbox
                checked={values?.configurationBuild?.sqlConfiguration?.applyJoin}
                onChange={handleChange}
                name="configurationBuild.sqlConfiguration.applyJoin"/>}
            label="Apply Relationship Join"
        />
        <h4 style={{margin: ' 5px'}}>Service Configuration</h4>
        <FormControlLabel
            className={classes.inputStyle}
            control={<Checkbox
                checked={values?.configurationBuild?.serviceConfiguration?.applyMultiTenancy}
                onChange={handleChange}
                name="configurationBuild.serviceConfiguration.applyMultiTenancy"/>}
            label="Apply Multi-Tenancy"
        />
        <FormControlLabel
            className={classes.inputStyle}
            control={<Checkbox
                checked={values?.configurationBuild?.serviceConfiguration?.applyCustomField}
                onChange={handleChange}
                name="configurationBuild.serviceConfiguration.applyCustomField"/>}
            label="Apply Custom Fields"
        />
        {values?.configurationBuild?.serviceConfiguration?.applySot ? <>
            <h4 style={{margin: ' 5px'}}>Select Source of Truth Entities</h4>
            {manifest.entityPaths.map(entity => (
                <FormControlLabel
                    className={classes.inputStyle}
                    control={<Checkbox
                        checked={isExisting(entity.name)}
                        onClick={_ => {
                            handleEntityClick(entity.name)
                        }}
                        name={"configurationBuild.serviceConfiguration.sotEntities"}/>}
                    label={entity.name}
                />
            ))}
        </> : <></>}
        <h4 style={{margin: ' 5px'}}>Properties</h4>
        {propFields}
    </form>;
}

export default BuildManifestForm;