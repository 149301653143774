import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon,} from "@material-ui/core";
import useAuth from "./useAuth";

interface SessionExpiredDialogProps {
    open: boolean;
    onClose: () => void;
}

const SessionExpiredDialog: React.FC<SessionExpiredDialogProps> = ({open, onClose}) => {
    const {logout} = useAuth();

    const handleLogin = () => {
        onClose();
        logout();
        window.location.href = "/"
    };

    return <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='xs'
    >
        <DialogTitle id="alert-dialog-title">Session Expired</DialogTitle>
        <DialogContent dividers style={{display: 'flex', alignItems: 'center'}}>
            <Icon fontSize='large'
                  style={{marginRight: '5px', color: 'var(--warning-color)'}}>warning</Icon> Your user session has
            expired or is invalid. Try to login again.
        </DialogContent>
        <DialogActions>
            <Button
                disableElevation
                color='secondary'
                variant='contained'
                onClick={handleLogin}
            >
                Login
            </Button>
        </DialogActions>
    </Dialog>
};

export default SessionExpiredDialog;