import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Clear, FilterList } from "@material-ui/icons";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { CancelTokenSource } from "axios";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import * as yup from "yup";
import { CDMManager } from "../../core/CDMManager";
import { NAME_PATTERN } from "../../core/constants";
import { DataType } from "../../core/dataType";
import { Filter } from "../../core/Filter";
import Entity, { CreateEntityType } from "../../core/observables/Entity";
import Manifest from "../../core/observables/Manifest";
import TypeAttribute from "../../core/observables/TypeAttribute";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import { Position } from "../ContextMenu";
import { NegroniIcon } from "../icon/NegronIIcon";
import {
  PaginatedSelect,
  SelectGroupOption,
  SelectOption,
} from "../select/PaginatedSelect";
import useService from "../service/useService";
import { ManageEntityTabValue } from "./ManageEntityDialog";
import { EntityNode } from "./tree/EntityTree";

const EntityWizardStyles = makeStyles(() =>
  createStyles({
    groupStyles: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    groupBadgeStyles: {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center",
    },
    selectStyle: {
      width: "100%",
    },
    boxModal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "400",
      border: "2px solid #000",
      boxShadow: "24",
      p: "4",
      minHeight: "350px",
      minWidth: "550px",
    },
  })
);

export interface EntityWizardProp {
  open: boolean;
  onClose: () => void;
  onCreateEntityViaWizard: (
    entity: Entity,
    manifest: Manifest | undefined,
    duplicateEntityId: number | undefined,
    version: string | undefined
  ) => Promise<string | undefined>;
  joyrideRunning?: boolean;
  joyrideIndex?: number;
  indexTrigger?: (index: number) => void;
  joyrideTrigger?: boolean;
  endTour?: () => void;
  viewMode: string;
  orgLabel?: string;
}

export const EntityWizard: React.FC<EntityWizardProp> = ({
  open,
  onClose,
  onCreateEntityViaWizard,
  joyrideRunning,
  joyrideIndex,
  indexTrigger,
  joyrideTrigger,
  endTour,
  viewMode,
  orgLabel,
}) => {
  const { entityService, manifestService, navigatorService } = useService();
  const classes = EntityWizardStyles();
  const [entity, setEntity] = useState<Entity>(
    new Entity(-1, "new_entity", "", CreateEntityType.TEMPLATE, -1)
  );
  const [manifest, setManifest] = useState<Manifest>();
  const [page, setPage] = useState<Number>(1);
  const [, setNameInput] = useState<HTMLInputElement>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>();
  const [finishError, setFinishError] = useState<string | null>();
  const [duplicateCoreEntity, setDuplicateCoreEntity] = useState(false);
  const [typeAttributes, setTypeAttributes] = useState<TypeAttribute[]>([]);
  const [selectedTypeAttributes, setSelectedTypeAttributes] = useState<
    TypeAttribute[]
  >([]);
  const [, setPosition] = useState<Position | null>(null);
  const [, setSelection] = useState<TypeAttribute | null>(null);
  const [tablePage] = useState(0);
  const [rowsPerPage] = useState(100);
  const [typeAttributeName, setTypeAttributeName] =
    useState("new_type_attribute");
  const [doNotAddTypeAttribute, setDoNotAddTypeAttribute] = useState(false);
  const [dataType, setDataType] = useState<DataType>(DataType.STRING);
  const dataTypeOptions = Object.values(DataType).map((value) => ({
    label: value,
    value,
  }));
  const [, setEntityTree] = useState<EntityNode>({
    id: -1,
    name: "Core",
    type: "folder",
  });
  const [, setDuplicatedEntityTypeAttributes] = useState<TypeAttribute[]>([]);
  const [, setFetchingDuplicatedEntity] = useState(false);
  const [duplicateEntityId, setDuplicateEntityId] = useState<number>(-1);

  const storedNodes = JSON.parse(
    localStorage.getItem(CDMManager.NEGRONI_CORE_SCHEMA_KEY) || "{}"
  );
  let typeAttributeList: TypeAttribute[] = [];
  const [targetVersion, setTargetVersion] = useState<string>("");
  const [filter, setFilter] = React.useState<Filter>(Filter.RESOLVED);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [folderFilter, setFolderFilter] = useState(false);
  const [folderFilterValue, setFolderFilterValue] = useState<string>("");
  const [pathFilter, setPathFilter] = useState(false);
  const [pathFilterValue, setPathFilterValue] = useState<string>("");
  const [updateAddEntity, setUpdateAddEntity] = useState(0);
  const [tab, setTab] = useState<ManageEntityTabValue>(
    ManageEntityTabValue.ADD_ENTITY
  );

  const handleFolderFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFolderFilter(event.target.checked);
    setFolderFilterValue("");
    refreshAddEntity();
  };

  useEffect(() => {
    if (localStorage.getItem(CDMManager.NEGRONI_CORE_SCHEMA_KEY) !== null) {
      setEntityTree(navigatorService.constructEntityNavigator(storedNodes, ""));
    }
  }, []);

  useEffect(() => {
    if (open) {
      setPage(1);
      setEntity(
        new Entity(-1, "new_entity", "", CreateEntityType.TEMPLATE, -1)
      );
      setManifest(undefined);
      setLoading(false);
      setError(undefined);
      setDuplicateCoreEntity(false);
      setTypeAttributes([]);
      setSelectedTypeAttributes([]);
      setTypeAttributeName("new_type_attribute");
      setDataType(DataType.STRING);
      setDuplicatedEntityTypeAttributes([]);
      setFetchingDuplicatedEntity(false);
      setDuplicateEntityId(-1);
      setDoNotAddTypeAttribute(false);
      setTargetVersion("");
      typeAttributeList = [];
    }
  }, [open]);

  useEffect(() => {
    if (joyrideIndex) {
      if (joyrideIndex === 4) {
        setPage(3);
      }
      if (joyrideIndex === 6) {
        setPage(4);
      }
      if (joyrideIndex === 8) {
        addTypeAttribute(typeAttributeName);
      }
    }
  }, [joyrideIndex]);

  useEffect(() => {
    validateName(entity.name);
  }, [entity.name]);

  useEffect(() => {
    validateName(typeAttributeName);
  }, [typeAttributeName]);

  useEffect(() => {
    if (
      typeAttributes.find((attribute) => attribute.name === typeAttributeName)
    ) {
      setDoNotAddTypeAttribute(true);
    } else {
      setDoNotAddTypeAttribute(false);
    }
  }, [typeAttributeName]);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.checked ? Filter.RESOLVED : Filter.PRIVATE);
    if (filter === Filter.PRIVATE) {
      resetFilter();
    }
  };

  const resetFilter = () => {
    setAdvanceFilter(false);
    setFolderFilter(false);
    setFolderFilterValue("");
    setPathFilter(false);
    setPathFilterValue("");
    refreshAddEntity();
  };

  const refreshAddEntity = () => {
    setUpdateAddEntity(updateAddEntity + 1);
  };

  const hideFilter = () => {
    setAdvanceFilter(false);
  };

  const getEntities = (
    page: number = 0,
    limit: number = 20,
    search: string = "",
    cancelTokenSource?: CancelTokenSource
  ): Promise<PaginatedResponse<SelectGroupOption[] | SelectOption[]>> => {
    return entityService
      .getEntities(
        filter,
        limit,
        page,
        search,
        -1,
        folderFilterValue,
        pathFilterValue,
        cancelTokenSource?.token
      )
      .then((response) => {
        return {
          ...response,
          result: response.result.map((entity) => ({
            label: entity.name,
            value: entity,
          })),
        } as PaginatedResponse<SelectOption[]>;
      });
  };

  const getCoreRelativePath = (location: string): string => {
    location = location.replace("cdm:/core/", "");
    let splitLocation = location.split("/");
    splitLocation.splice(splitLocation.length - 1, 1);
    return splitLocation.join("/");
  };

  const handleAdvanceFilter = () => setAdvanceFilter(!advanceFilter);

  const nextPage = () => {
    if (page === 1) {
      if (duplicateCoreEntity) {
        setPage(2);
      } else {
        setPage(3);
        if (joyrideRunning && indexTrigger) {
          indexTrigger(3);
        }
      }
    }
    if (page === 2) {
      setPage(3);
    }
    if (page === 3) {
      setPage(4);
      if (joyrideRunning && indexTrigger) {
        indexTrigger(5);
      }
    }
  };

  const backPage = () => {
    if (page === 2) setPage(1);
    if (page === 3) {
      if (duplicateCoreEntity) {
        setPage(2);
      } else {
        setPage(1);
      }
    }
    if (page === 4) {
      setPage(3);
    }
  };

  const handleDuplicateCoreEntity = () => {
    setDuplicateCoreEntity(!duplicateCoreEntity);
  };

  const handleLoadManifestOptions = useCallback(
    (
      page: number,
      limit: number,
      search?: string,
      cancelTokenSource?: CancelTokenSource
    ): Promise<PaginatedResponse<SelectOption[] | SelectGroupOption[]>> => {
      return manifestService
        .getManifests(
          Filter.PRIVATE,
          undefined,
          limit,
          page,
          search,
          cancelTokenSource?.token
        )
        .then((response) => ({
          ...response,
          result: response.result.map((manifest) => ({
            label: manifest.name,
            value: manifest,
          })),
        }));
    },
    []
  );

  const isSelected = (typeAttribute: TypeAttribute) =>
    selectedTypeAttributes.indexOf(typeAttribute) !== -1;

  function handleContextMenu(
    e: React.MouseEvent<HTMLTableRowElement>,
    typeAttribute: TypeAttribute
  ) {
    setSelection(typeAttribute);

    e.preventDefault();
    setPosition({
      mouseX: e.clientX,
      mouseY: e.clientY,
    });
  }

  function handleClick(typeAttribute: TypeAttribute) {
    const selectedIndex = selectedTypeAttributes.indexOf(typeAttribute);
    let newSelected: TypeAttribute[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTypeAttributes, typeAttribute);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTypeAttributes.slice(1));
    } else if (selectedIndex === selectedTypeAttributes.length - 1) {
      newSelected = newSelected.concat(selectedTypeAttributes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTypeAttributes.slice(0, selectedIndex),
        selectedTypeAttributes.slice(selectedIndex + 1)
      );
    }

    setSelectedTypeAttributes(newSelected);
  }

  function deleteEntities(typeAttributes: TypeAttribute[]) {
    typeAttributeList = typeAttributes;
    typeAttributes.forEach((typeAttribute) => {
      const deleteIndex = typeAttributeList.indexOf(typeAttribute, 0);
      typeAttributeList.splice(deleteIndex, 1);
    });
    setSelectedTypeAttributes([]);
    setTypeAttributes([...typeAttributeList]);
  }

  function handleDeleteButton() {
    if (selectedTypeAttributes.length > 0) {
      deleteEntities(selectedTypeAttributes);
    }
  }

  const addTypeAttribute = (typeAttributeName: String) => {
    if (
      typeAttributes.findIndex(
        (attribute) => attribute.name === typeAttributeName
      ) === -1
    ) {
      setTypeAttributes([
        ...typeAttributes,
        {
          name: typeAttributeName,
          dataType: dataType,
        } as TypeAttribute,
      ]);
      setDoNotAddTypeAttribute(true);
    }
  };

  const validateName = (name: String) => {
    try {
      yup
        .string()
        .required()
        .min(Entity.MIN_NAME_LENGTH)
        .max(Entity.MAX_NAME_LENGTH)
        .matches(
          NAME_PATTERN,
          "this must only contain alphanumeric and no underscore consecutively"
        )
        .validateSync(name);
      setError("");
    } catch (e) {
      setError(e.message);
    }
  };

  const handleNextOrFinish = () => {
    if (page === 4) {
      setLoading(true);
      entity.typeAttributes = [...typeAttributes];
      onCreateEntityViaWizard(
        entity,
        manifest,
        duplicateEntityId,
        targetVersion
      ).then((error: string | undefined) => {
        setLoading(false);
        if (error && error.length > 0) {
          setFinishError(error);
        } else {
          onClose();
        }
        if (joyrideRunning && endTour) {
          endTour();
        }
      });
    } else {
      nextPage();
    }
  };

  const handlePathFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPathFilter(event.target.checked);
    setPathFilterValue("");
    refreshAddEntity();
  };

  const handleCoreEntityChange = (entity: any) => {
    if (!entity) return setError("this is a required field");
    setEntity(entity);
  };

  return joyrideTrigger ? (
    <Modal
      id="negroni-entity-wizard-dialog"
      isOpen={open}
      onRequestClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.boxModal}>
        <DialogTitle id="form-dialog-title">Create Entity</DialogTitle>
        <DialogContent
          id="negroni-entity-wizard-content"
          dividers
          style={{ minHeight: "350px", minWidth: "550px" }}
        >
          {page === 1 ? (
            <TabContext value={ManageEntityTabValue.CREATE_EXCLUSIVE}>
              <Paper elevation={0} square>
                <TabList
                  value={ManageEntityTabValue.CREATE_EXCLUSIVE}
                  onChange={(e, value) => {
                    setTab(value);
                    setError(null);
                  }}
                  variant="fullWidth"
                >
                  <Tab
                    value={ManageEntityTabValue.ADD_ENTITY}
                    label="Choose Existing Entity"
                  />
                  <Tab
                    value={ManageEntityTabValue.CREATE_EXCLUSIVE}
                    label="Create Local Entity"
                  />
                </TabList>
              </Paper>
              <TabPanel value={ManageEntityTabValue.ADD_ENTITY}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <DialogContent
                    dividers
                    style={{
                      overflowY: "hidden",
                      maxWidth: "500px",
                      width: "500px",
                      minHeight: "400px",
                    }}
                  >
                    {viewMode === "Admin" && filter === Filter.PRIVATE ? (
                      <p className="dialog-label">
                        Select an entity from <b>{orgLabel}</b> organisation to
                        add in <b>{manifest?.name}</b> manifest.
                      </p>
                    ) : (
                      <p className="dialog-label">
                        Select an entity to add in <b>{manifest?.name}</b>{" "}
                        manifest.
                      </p>
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filter === Filter.RESOLVED}
                          onChange={handleFilter}
                          inputProps={{
                            "aria-label": "Select custom entities",
                          }}
                        />
                      }
                      label="Show Core Entities"
                    />
                    <FormControl
                      variant="filled"
                      fullWidth={true}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div style={{ width: "100%" }}>
                        <PaginatedSelect
                          autoFocus={tab === ManageEntityTabValue.ADD_ENTITY}
                          getOptions={getEntities}
                          valueMapper={(value) =>
                            value
                              ? { label: value.name, value: value.value }
                              : null
                          }
                          onValueChanged={handleCoreEntityChange}
                          update={updateAddEntity}
                          formatOptionLabel={(option) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {option.label}
                                {filter === Filter.RESOLVED &&
                                  option.value?.path && (
                                    <small
                                      style={{ color: "var(--label-1-color)" }}
                                    >
                                      {getCoreRelativePath(option?.value?.path)}
                                    </small>
                                  )}
                              </div>
                            );
                          }}
                          hideFilter={hideFilter}
                        />
                      </div>
                      {filter === Filter.RESOLVED ? (
                        <Tooltip
                          title={
                            advanceFilter
                              ? "Hide Advance Filters"
                              : "Advance Filters"
                          }
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginLeft: "10px" }}
                            onClick={handleAdvanceFilter}
                          >
                            {advanceFilter ? <Clear /> : <FilterList />}
                          </Button>
                        </Tooltip>
                      ) : undefined}
                    </FormControl>
                    <FormHelperText error={!!error && error.length > 0}>
                      {error}
                    </FormHelperText>
                    {advanceFilter && filter === Filter.RESOLVED ? (
                      <FormControl
                        variant="filled"
                        fullWidth={true}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={folderFilter}
                                onChange={handleFolderFilter}
                                inputProps={{
                                  "aria-label": "Filter by folder",
                                }}
                              />
                            }
                            label="By Folder"
                          />
                          {folderFilter ? (
                            <TextField
                              value={folderFilterValue}
                              onChange={(e) => {
                                setFolderFilterValue(e.target.value);
                                refreshAddEntity();
                              }}
                              type="text"
                              autoFocus
                              fullWidth={true}
                            />
                          ) : undefined}
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={pathFilter}
                                onChange={handlePathFilter}
                                inputProps={{ "aria-label": "Filter by path" }}
                              />
                            }
                            label="By Path"
                          />
                          {pathFilter ? (
                            <TextField
                              value={pathFilterValue}
                              onChange={(e) => {
                                setPathFilterValue(e.target.value);
                                refreshAddEntity();
                              }}
                              type="text"
                              autoFocus
                              fullWidth={true}
                            />
                          ) : undefined}
                        </div>
                      </FormControl>
                    ) : undefined}
                  </DialogContent>
                </form>
              </TabPanel>
              <TabPanel value={ManageEntityTabValue.CREATE_EXCLUSIVE}>
                <DialogContent
                  dividers
                  style={{
                    overflowY: "hidden",
                    maxWidth: "500px",
                    width: "500px",
                    minHeight: "400px",
                  }}
                >
                  <p className="dialog-label">
                    Create a local entity to add in <b>{manifest?.name}</b>{" "}
                    manifest.
                  </p>
                  <TextField
                    autoFocus={true}
                    inputRef={setNameInput}
                    margin="dense"
                    id="entityName"
                    label="Entity Name"
                    fullWidth
                    color="primary"
                    variant="filled"
                    value={entity.name}
                    onChange={(e) =>
                      setEntity(
                        new Entity(
                          -1,
                          e.target.value,
                          "",
                          CreateEntityType.EXCLUSIVE,
                          -1
                        )
                      )
                    }
                    error={!!error}
                    helperText={error}
                  />
                </DialogContent>
              </TabPanel>
            </TabContext>
          ) : (
            <></>
          )}
          {page === 3 ? (
            <div>
              <p className="dialog-label">
                Select a manifest to where the entity will be injected
              </p>
              <FormControl variant="filled" fullWidth={true}>
                <PaginatedSelect
                  getOptions={handleLoadManifestOptions}
                  valueMapper={(value) =>
                    value ? { label: value.name, value: value.value } : null
                  }
                  placeholder={
                    joyrideRunning ? manifest?.name : "Select Manifest"
                  }
                  defaultValue={manifest}
                  onValueChanged={setManifest}
                  formatOptionLabel={(option) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {option.label}
                      </div>
                    );
                  }}
                  autoFocus={false}
                />
              </FormControl>
            </div>
          ) : (
            <></>
          )}

          {page === 4 ? (
            <div>
              <Grid container>
                <Grid item xs={10}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Type Attribute Name"
                    fullWidth
                    variant="filled"
                    value={typeAttributeName}
                    onChange={(e) => setTypeAttributeName(e.target.value)}
                    helperText={error}
                    error={!!error}
                  />
                </Grid>
                <Grid item xs={2} style={{ placeSelf: "center" }}>
                  <Tooltip title={"Add Type Attribute"}>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        addTypeAttribute(typeAttributeName);
                        if (joyrideRunning && indexTrigger) {
                          indexTrigger(6);
                        }
                      }}
                      disabled={
                        (!!error && error.length > 0) || doNotAddTypeAttribute
                      }
                    >
                      <Add />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
              <Select
                isClearable
                isSearchable
                placeholder="Data Type"
                value={{ label: dataType, value: dataType }}
                onChange={(option: any) =>
                  setDataType(option?.value || DataType.STRING)
                }
                options={dataTypeOptions}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPlacement="auto"
                maxMenuHeight={200}
              />
              {typeAttributes.length > 0 ? (
                <TableContainer style={{ width: "100%" }}>
                  {selectedTypeAttributes.length > 0 && (
                    <Toolbar
                      style={{ marginTop: 10 }}
                      className="table-toolbar"
                      variant="dense"
                    >
                      <Typography
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                      >
                        {selectedTypeAttributes.length} Selected
                      </Typography>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={handleDeleteButton}
                          color="inherit"
                        >
                          <NegroniIcon iconClass="delete-icon" color="#fff" />
                        </IconButton>
                      </Tooltip>
                    </Toolbar>
                  )}
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "5%" }} />
                        <TableCell style={{ width: "50%" }}>Name</TableCell>
                        <TableCell style={{ width: "45%" }}>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {typeAttributes
                        .filter((typeAttribute) =>
                          typeAttribute.name.toLowerCase()
                        )
                        .slice(
                          tablePage * rowsPerPage,
                          tablePage * rowsPerPage + rowsPerPage
                        )
                        .map((typeAttribute, index) => (
                          <TableRow
                            onClick={(_) => {
                              handleClick(typeAttribute);
                            }}
                            hover
                            key={index}
                            style={{ cursor: "pointer" }}
                            onContextMenu={(e) =>
                              handleContextMenu(e, typeAttribute)
                            }
                            selected={
                              !!selectedTypeAttributes.find(
                                (ta) => ta.name === entity.name
                              )
                            }
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isSelected(typeAttribute)}
                                inputProps={{
                                  "aria-label": "Select type attribute",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                {typeAttribute.name}
                              </Grid>
                            </TableCell>
                            <TableCell>{typeAttribute.dataType}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    padding: "10px",
                  }}
                >
                  No Attributes to display
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions id="negroni-entity-wizard-actions">
          <Button onClick={onClose} style={{ marginRight: "auto" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={page === 1}
            onClick={backPage}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={
              (loading ||
              (!!error && error.length > 0) ||
              (page === 2 && duplicateEntityId === -1) ||
              (page === 3 && manifest === undefined)) &&
              (typeAttributes.length < 1 && page === 4)
            }
            disableElevation
            onClick={handleNextOrFinish}
            startIcon={loading && <CircularProgress size={18} />}
          >
            {page === 4 ? "Finish" : "Next"}
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  ) : (
    <Dialog
      id="negroni-entity-wizard-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="form-dialog-title">Create Entity</DialogTitle>
      <DialogContent
        id="negroni-entity-wizard-content"
        dividers
        style={{ minHeight: "350px", minWidth: "550px" }}
      >
        {page === 1 ? (
          <TabContext value={tab}>
            <Paper elevation={0} square>
              <TabList
                value={tab}
                onChange={(e, value) => {
                  setTab(value);
                  setError(null);
                }}
                variant="fullWidth"
              >
                <Tab
                  value={ManageEntityTabValue.ADD_ENTITY}
                  label="Choose Existing Entity"
                />
                <Tab
                  value={ManageEntityTabValue.CREATE_EXCLUSIVE}
                  label="Create Local Entity"
                />
              </TabList>
            </Paper>
            <TabPanel value={ManageEntityTabValue.ADD_ENTITY}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <DialogContent
                  dividers
                  style={{
                    overflowY: "hidden",
                    maxWidth: "500px",
                    width: "500px",
                    minHeight: "400px",
                  }}
                >
                  {viewMode === "Admin" && filter === Filter.PRIVATE ? (
                    <p className="dialog-label">
                      Select an entity from <b>{orgLabel}</b> organisation to
                      add in <b>{manifest?.name}</b> manifest.
                    </p>
                  ) : (
                    <p className="dialog-label">
                      Select an entity to add in <b>{manifest?.name}</b>{" "}
                      manifest.
                    </p>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filter === Filter.RESOLVED}
                        onChange={handleFilter}
                        inputProps={{ "aria-label": "Select custom entities" }}
                      />
                    }
                    label="Show Core Entities"
                  />
                  <FormControl
                    variant="filled"
                    fullWidth={true}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "100%" }}>
                      <PaginatedSelect
                        autoFocus={tab === ManageEntityTabValue.ADD_ENTITY}
                        getOptions={getEntities}
                        valueMapper={(value) =>
                          value
                            ? { label: value.name, value: value.value }
                            : null
                        }
                        onValueChanged={handleCoreEntityChange}
                        update={updateAddEntity}
                        formatOptionLabel={(option) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {option.label}
                              {filter === Filter.RESOLVED &&
                                option.value?.path && (
                                  <small
                                    style={{ color: "var(--label-1-color)" }}
                                  >
                                    {getCoreRelativePath(option?.value?.path)}
                                  </small>
                                )}
                            </div>
                          );
                        }}
                        hideFilter={hideFilter}
                      />
                    </div>
                    {filter === Filter.RESOLVED ? (
                      <Tooltip
                        title={
                          advanceFilter
                            ? "Hide Advance Filters"
                            : "Advance Filters"
                        }
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginLeft: "10px" }}
                          onClick={handleAdvanceFilter}
                        >
                          {advanceFilter ? <Clear /> : <FilterList />}
                        </Button>
                      </Tooltip>
                    ) : undefined}
                  </FormControl>
                  <FormHelperText error={!!error && error.length > 0}>
                    {error}
                  </FormHelperText>
                  {advanceFilter && filter === Filter.RESOLVED ? (
                    <FormControl
                      variant="filled"
                      fullWidth={true}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={folderFilter}
                              onChange={handleFolderFilter}
                              inputProps={{ "aria-label": "Filter by folder" }}
                            />
                          }
                          label="By Folder"
                        />
                        {folderFilter ? (
                          <TextField
                            value={folderFilterValue}
                            onChange={(e) => {
                              setFolderFilterValue(e.target.value);
                              refreshAddEntity();
                            }}
                            type="text"
                            autoFocus
                            fullWidth={true}
                          />
                        ) : undefined}
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={pathFilter}
                              onChange={handlePathFilter}
                              inputProps={{ "aria-label": "Filter by path" }}
                            />
                          }
                          label="By Path"
                        />
                        {pathFilter ? (
                          <TextField
                            value={pathFilterValue}
                            onChange={(e) => {
                              setPathFilterValue(e.target.value);
                              refreshAddEntity();
                            }}
                            type="text"
                            autoFocus
                            fullWidth={true}
                          />
                        ) : undefined}
                      </div>
                    </FormControl>
                  ) : undefined}
                </DialogContent>
              </form>
            </TabPanel>
            <TabPanel value={ManageEntityTabValue.CREATE_EXCLUSIVE}>
              <DialogContent
                dividers
                style={{
                  overflowY: "hidden",
                  maxWidth: "500px",
                  width: "500px",
                  minHeight: "400px",
                }}
              >
                <p className="dialog-label">
                  Create a local entity to add in <b>{manifest?.name}</b>{" "}
                  manifest.
                </p>
                <TextField
                  autoFocus={true}
                  inputRef={setNameInput}
                  margin="dense"
                  id="entityName"
                  label="Entity Name"
                  fullWidth
                  color="primary"
                  variant="filled"
                  value={entity.name}
                  onChange={(e) =>
                    setEntity(
                      new Entity(
                        -1,
                        e.target.value,
                        "",
                        CreateEntityType.EXCLUSIVE,
                        -1
                      )
                    )
                  }
                  error={!!error}
                  helperText={error}
                />
              </DialogContent>
            </TabPanel>
          </TabContext>
        ) : (
          <></>
        )}

        {page === 3 ? (
          <div>
            <p className="dialog-label">
              Select a manifest to where the entity will be injected
            </p>
            <FormControl variant="filled" fullWidth={true}>
              <PaginatedSelect
                getOptions={handleLoadManifestOptions}
                valueMapper={(value) =>
                  value ? { label: value.name, value: value.value } : null
                }
                placeholder={
                  joyrideRunning ? manifest?.name : "Select Manifest"
                }
                defaultValue={manifest}
                onValueChanged={setManifest}
                formatOptionLabel={(option) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {option.label}
                    </div>
                  );
                }}
                autoFocus={false}
              />
            </FormControl>
          </div>
        ) : (
          <></>
        )}

        {page === 4 ? (
          <div>
            <Grid container>
              {finishError && (
                <Alert severity="error" style={{ width: "100%" }}>
                  {" "}
                  {finishError}{" "}
                </Alert>
              )}
              <Grid item xs={10}>
                <TextField
                  inputRef={setNameInput}
                  autoFocus
                  margin="dense"
                  label="Type Attribute Name"
                  fullWidth
                  variant="filled"
                  value={typeAttributeName}
                  onChange={(e) => setTypeAttributeName(e.target.value)}
                  helperText={error}
                  error={!!error}
                />
              </Grid>
              <Grid item xs={2} style={{ placeSelf: "center" }}>
                <Tooltip title={"Add Type Attribute"}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      addTypeAttribute(typeAttributeName);
                      if (joyrideRunning && indexTrigger) {
                        indexTrigger(6);
                      }
                    }}
                    disabled={
                      (!!error && error.length > 0) || doNotAddTypeAttribute
                    }
                  >
                    <Add />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <Select
              isClearable
              isSearchable
              placeholder="Data Type"
              value={{ label: dataType, value: dataType }}
              onChange={(option: any) =>
                setDataType(option?.value || DataType.STRING)
              }
              options={dataTypeOptions}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPlacement="auto"
              maxMenuHeight={200}
            />
            {typeAttributes.length > 0 ? (
              <TableContainer style={{ width: "100%" }}>
                {selectedTypeAttributes.length > 0 && (
                  <Toolbar
                    style={{ marginTop: 10 }}
                    className="table-toolbar"
                    variant="dense"
                  >
                    <Typography
                      color="inherit"
                      variant="subtitle1"
                      component="div"
                    >
                      {selectedTypeAttributes.length} Selected
                    </Typography>
                    <Tooltip title="Delete">
                      <IconButton onClick={handleDeleteButton} color="inherit">
                        <NegroniIcon iconClass="delete-icon" color="#fff" />
                      </IconButton>
                    </Tooltip>
                  </Toolbar>
                )}
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "5%" }} />
                      <TableCell style={{ width: "50%" }}>Name</TableCell>
                      <TableCell style={{ width: "45%" }}>Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {typeAttributes
                      .filter((typeAttribute) =>
                        typeAttribute.name.toLowerCase()
                      )
                      .slice(
                        tablePage * rowsPerPage,
                        tablePage * rowsPerPage + rowsPerPage
                      )
                      .map((typeAttribute, index) => (
                        <TableRow
                          onClick={(_) => {
                            handleClick(typeAttribute);
                          }}
                          hover
                          key={index}
                          style={{ cursor: "pointer" }}
                          onContextMenu={(e) =>
                            handleContextMenu(e, typeAttribute)
                          }
                          selected={
                            !!selectedTypeAttributes.find(
                              (ta) => ta.name === entity.name
                            )
                          }
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isSelected(typeAttribute)}
                              inputProps={{
                                "aria-label": "Select type attribute",
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Grid container direction="row" alignItems="center">
                              {typeAttribute.name}
                            </Grid>
                          </TableCell>
                          <TableCell>{typeAttribute.dataType}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  padding: "10px",
                }}
              >
                No Attributes to display
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions id="negroni-entity-wizard-actions">
        <Button onClick={onClose} style={{ marginRight: "auto" }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={page === 1}
          onClick={backPage}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={
            (loading ||
            !!finishError ||
            (!!error && error.length > 0) ||
            (page === 3 && manifest === undefined)) &&
            (typeAttributes.length < 1 && page === 4)
          }
          disableElevation
          onClick={handleNextOrFinish}
          startIcon={loading && <CircularProgress size={18} />}
        >
          {page === 4 ? "Finish" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(EntityWizard);
