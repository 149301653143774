import { sortBy } from "lodash";
import { action, observable } from "mobx";
import React from "react";
import {
  CellEditorProps,
  TextCellEditor,
} from "../../component/properties/CellEditor";
import {
  PropertyDescriptor,
  PropertySource,
} from "../../component/properties/PropertyDescriptor";
import { AsyncSelectCellEditor } from "../../component/properties/SelectCellEditor";
import { ManifestService, mapToManifest } from "../services/ManifestService";
import CDMDocument, { CDMDocumentType } from "./CDMDocument";
import DocumentItem from "./DocumentItem";
import Entity from "./Entity";

export default class Manifest implements CDMDocument {
  static MIN_NAME_LENGTH = 2;
  static MAX_NAME_LENGTH = 50;
  @observable id: number;
  @observable name: string;
  @observable path: string;
  @observable entityPaths: DocumentItem[] = [];
  @observable subManifestPaths: DocumentItem[] = [];
  @observable entities: Entity[] = [];
  @observable subManifests: Manifest[] = [];
  @observable isDefault: boolean = true;
  @observable relationships: EntityRelationship[] = [];
  @observable parentManifestId?: number;
  @observable latestVersion: string = "1.0.0";
  @observable currentVersion: string = "1.0.0";
  private _parent: any;
  readonly documentType = CDMDocumentType.MANIFEST;

  constructor(
    id: number,
    name: string,
    path: string,
    entityPaths?: DocumentItem[],
    subManifestPaths?: DocumentItem[]
  ) {
    this.id = id;
    this.name = name;
    this.path = path;
    if (entityPaths) {
      for (let entity of entityPaths) this.addEntityPath(entity);
    }
    if (subManifestPaths) {
      for (let subManifest of subManifestPaths)
        this.addSubManifestPath(subManifest);
    }
  }

  @action
  addEntityPath(entity: DocumentItem) {
    this.entityPaths.push(entity);
  }

  @action
  addSubManifestPath(subManifest: DocumentItem) {
    this.subManifestPaths.push(subManifest);
  }

  @action
  addRelationship(relationship: EntityRelationship) {
    this.relationships.push(relationship);
  }

  set parent(parent: any) {
    this._parent = parent;
  }

  get parent() {
    return this._parent;
  }

  set setEntityPaths(entityPaths: DocumentItem[]) {
    this.entityPaths = entityPaths;
  }
}

export class ManifestPropertySource implements PropertySource {
  private readonly manifest: Manifest;
  private readonly descriptors: PropertyDescriptor[] = [];
  private manifestService: ManifestService;
  documentType = CDMDocumentType.MANIFEST;

  constructor(manifest: Manifest, manifestService: ManifestService) {
    this.manifest = manifest;
    this.manifestService = manifestService;

    async function handleManifestVersions() {
      let versions: { label: string; value: string }[] = [];
      try {
        await manifestService
          .getManifestVersionsById(manifest.id)
          .then((manifest) => {
            manifest.manifestVersions.forEach((version: any) => {
              versions.push({
                label: version.VERSION,
                value: version.VERSION,
              });
            });
            return sortBy(versions, "label");
          });
        return versions;
      } catch (_) {
        return [];
      }
    }

        this.descriptors.push({
            readOnly: true,
            defaultValue: this.manifest.name,
            displayName: 'Name',
            description: 'Name of the manifest',
            hasDefaultValue: true,
            name: 'name',
            value: this.manifest.name,
            target: this.manifest,
            getCellEditor: (props: CellEditorProps<any>) => <TextCellEditor {...props} label='Name'
                                                                            description='Name of the manifest'/>,
            validate: _ => undefined,
        }, 
        {
            readOnly: this.manifest.isDefault,
            defaultValue: this.manifest.latestVersion,
            displayName: 'Version',
            description: 'The current version of the manifest',
            hasDefaultValue: true,
            name: 'latestVersion',
            value: this.manifest.latestVersion,
            displayValue: this.manifest.latestVersion || '1.0.0',
            target: this.manifest,
            getCellEditor: (props: CellEditorProps<any>) => <AsyncSelectCellEditor
            {...props}
            loadOptions={handleManifestVersions}
          />,
        validate: (_) => undefined,
      }
    );
  }

  get iconClass(): string {
    return "";
  }

  get title(): string {
    return "Manifest";
  }

  dispose(): void {}

  getPropertyDescriptors(): PropertyDescriptor[] {
    return this.descriptors;
  }

  async setProperty(name: string, newValue: any): Promise<void> {
    switch (name) {
      case "name":
        const oldName = this.manifest.name;
        this.manifest.name = newValue as string;
        this.manifest.path.replace(oldName, newValue);
        break;
      case "latestVersion":
        const version = await this.manifestService.getManifestVersion(
          this.manifest.id,
          newValue
        );
        const manifest = JSON.parse(version.manifest.CONTENT);

        this.manifest.entities = manifest.entities.map(
          (entity: any, index: string) => ({
            id: entity,
            name: "manifest" + index,
          })
        );

        this.manifest.subManifests = manifest.subManifests.map(
          (manifest: any, index: string) => ({
            id: manifest,
            name: "manifest" + index,
          })
        );

        Object.assign(
          this.manifest,
          mapToManifest(this.manifest.id, this.manifest, newValue)
        );
        break;
    }
  }

  getModel(): CDMDocument {
    return this.manifest;
  }
}

export class EntityRelationship {
  fromEntity: string;
  fromAttribute: string;
  toEntity: string;
  toAttribute: string;

  constructor(
    fromEntity: string,
    fromAttribute: string,
    toEntity: string,
    toAttribute: string
  ) {
    this.fromEntity = fromEntity;
    this.fromAttribute = fromAttribute;
    this.toEntity = toEntity;
    this.toAttribute = toAttribute;
  }
}

export const makeAdminCreateManifest = (
  org: string,
  manifest: Manifest
): AdminCreateManifest => {
  return {
    org: org,
    manifest: {
      manifestName: manifest.name,
      importsPath: [],
      entities: manifest.entityPaths.map((e) => e.id),
      subManifests: manifest.subManifestPaths.map((sm) => sm.id),
    },
  };
};

export interface AdminCreateManifest {
  org: string;
  manifest: {
    manifestName?: string;
    importsPath: string[];
    entities: number[];
    subManifests: number[];
  };
}

export const makeCreateManifest = (manifest: Manifest): CreateManifest => {
  return {
    manifestName: manifest.name,
    importsPath: [],
    entities: manifest.entityPaths.map((e) => e.id),
    subManifests: manifest.subManifestPaths.map((sm) => sm.id),
  };
};

export const makeCreateManifestWizard = (manifest: Manifest, duplicateManifestId: number, entities: DocumentItem[], manifestProperties: MartiniProperties[], version: string, generateType: string): CreateManifestWizard => {
    let entitiesId: number[] = []
    let exclusiveEntities: string[] = []
    entities.forEach(entity => {
        if(entity.path === 'Excluse entity to be created') {
            exclusiveEntities.push(entity.name)
        } else {
            entitiesId.push(entity.id)
        }
    })
    return {
        manifestName: manifest.name,
        duplicateManifest: duplicateManifestId,
        importsPath: [],
        entities: entitiesId,
        exclusiveEntities: exclusiveEntities,
        subManifests: [],
        manifestProperties: manifestProperties,
        version: version,
        generateType: generateType,
        type: "EXCLUSIVE"
    }
}

export interface CreateManifest {
  manifestName?: string;
  importsPath: string[];
  entities: number[];
  subManifests: number[];
}

export interface CreateManifestWizard {
    manifestName: string;
    duplicateManifest: number;
    importsPath: string[];
    entities: number[];
    exclusiveEntities: string[];
    subManifests: number[];
    manifestProperties: MartiniProperties[];
    version: string;
    generateType: string;
    type: "EXCLUSIVE" | "ORGANISATIONAL"
}

export interface UpdateManifest extends CreateManifest {}

export interface ManifestBuildProp {
  name: string;
  value: string;
}

export interface BuildManifest {
  manifestProperties: MartiniProperties[];
  version: string;
  customBuild: boolean;
}

export interface MartiniProperties {
  packageProperties: PackageProperties;
  configurationBuild: ConfigurationBuild;
  manifestName: string;
}

export interface PackageProperties {
  customProperties: ManifestBuildProp[];
  connectionPoolInfo: {
    url: string;
    username: string;
    password: string;
    type: ConnectionPoolType;
    databaseName: string;
  };
}

export interface AggregateQueries {
  name: string;
  entity: string;
  column: AggregateQueriesColumn[];
}

export interface AggregateQueriesColumn {
  field: string;
  type: AggregateType;
  alias: string;
}

export interface ConfigurationBuild {
  apiConfiguration: {
    type: ApiTypes[];
    security: ApiSecurity;
    excludeOperations: string[];
  };
  sqlConfiguration: {
    applyAutoIncrement: boolean;
    applyCoalesce: boolean;
    applyLimitOffset: boolean;
    applyJoin: boolean;
  };
  serviceConfiguration: {
    applyMultiTenancy: boolean;
    applyCustomField: boolean;
    applySot: boolean;
    sotEntities: string[];
  };
  aggregateQueries: AggregateQueries[];
}

export enum ApiTypes {
  REST = "REST",
  SOAP = "SOAP",
  GRAPHQL = "GRAPHQL",
}

export enum ApiSecurity {
  NONE = "NONE",
  BASIC = "BASIC",
  OAUTH2 = "OAUTH2",
}

export enum ConnectionPoolType {
  HSQL = "hsql",
  MYSQL = "mysql",
  MSSQL = "mssql",
  ORC = "oracle",
  PSQL = "postgresql",
  DB2 = "DB2",
  H2 = "H2",
  DBY = "DBY",
  CS = "CS",
  FB = "FB",
  IDS = "IDS",
  IDB = "IDB",
  INDS = "INDS",
  IB = "IB",
  JDBC = "JDBC",
  SYB = "SYB",
  PBS = "PBS",
  MNGO = "MNGO",
  CSS = "CSS",
  CSQL = "CSQL",
  SNW = "SNW",
  ARED = "ARED",
  GBQ = "GBQ",
  AS = "AS",
  DBCK = "DBCK",
  TDTA = "TDTA",
  PDB = "PDB",
  DDB = "DDB",
  GBT = "GBT",
}

export const connectionPoolTypeToLabel = (
  label: ConnectionPoolType
): string => {
  switch (label) {
    case ConnectionPoolType.HSQL:
      return "Hypersonic Database";
    case ConnectionPoolType.MYSQL:
      return "MySQL Database";
    case ConnectionPoolType.MSSQL:
      return "Microsoft SQL Server";
    case ConnectionPoolType.ORC:
      return "Oracle Database";
    case ConnectionPoolType.PSQL:
      return "PostgreSQL Database";
    case ConnectionPoolType.DB2:
      return "IBM DB2 Database";
    case ConnectionPoolType.H2:
      return "H2 Database Engine";
    case ConnectionPoolType.DBY:
      return "Derby Database";
    case ConnectionPoolType.CS:
      return "Cloudscape Database";
    case ConnectionPoolType.FB:
      return "Firebird Database";
    case ConnectionPoolType.IDS:
      return "IDS Server";
    case ConnectionPoolType.IDB:
      return "InstantDB Database";
    case ConnectionPoolType.INDS:
      return "Informix Dynamic Server";
    case ConnectionPoolType.IB:
      return "Interbase Database";
    case ConnectionPoolType.JDBC:
      return "JDBC-ODBC Bridge";
    case ConnectionPoolType.SYB:
      return "Sybase Database";
    case ConnectionPoolType.PBS:
      return "PointBases Embedded Server";
    case ConnectionPoolType.MNGO:
      return "MongoDB Database";
    case ConnectionPoolType.CSS:
      return "Apache Cassandra";
    case ConnectionPoolType.CSQL:
      return "Couchbase NoSQL Database";
    case ConnectionPoolType.SNW:
      return "Snowflake Database";
    case ConnectionPoolType.ARED:
      return "AWS Redshift";
    case ConnectionPoolType.GBQ:
      return "Google Bigquery";
    case ConnectionPoolType.AS:
      return "Azure Synapse";
    case ConnectionPoolType.DBCK:
      return "Databricks Database";
    case ConnectionPoolType.TDTA:
      return "Teradata Database";
    case ConnectionPoolType.PDB:
      return "Presto DB";
    case ConnectionPoolType.DDB:
      return "DynamoDB Database";
    case ConnectionPoolType.GBT:
      return "Google BigTable";
  }
};

export function getConnectionPoolOptions(db: ConnectionPoolType[]) {
  // Remove other connection pool options since backend doesn't support them yet
  const allowedDbTypes = [
    ConnectionPoolType.MYSQL,
    ConnectionPoolType.HSQL,
    ConnectionPoolType.ORC,
    ConnectionPoolType.PSQL,
    ConnectionPoolType.MSSQL,
  ];
  return db
    .filter((db) => allowedDbTypes.includes(db))
    .map((connectionPool) => ({
      label: connectionPoolTypeToLabel(connectionPool),
      value: connectionPool,
    }));
}

export enum AggregateType {
  COUNT = "COUNT",
  SUM = "SUM",
  MIN = "MIN",
  MAX = "MAX",
  AVG = "AVG",
  GROUPBY = "GROUPBY",
}

export const ConnectionPoolUrl = {
  [ConnectionPoolType.MYSQL]:
    "jdbc:mysql://[HOST]:[PORT:3306]/[DB]?allowMultiQueries=true&sessionVariables=sql_mode='ANSI'",
  [ConnectionPoolType.PSQL]: "jdbc:postgresql://[HOST]:[PORT:5432]/[DB]",
  [ConnectionPoolType.HSQL]:
    "jdbc:hsqldb:file:[PATH-TO-DATABASE-DIRECTORY]/[DATABASE-NAME].db;hsqldb.tx=MVCC;sql.syntax_mys=true",
  [ConnectionPoolType.MSSQL]:
    "jdbc:sqlserver://[HOST]:[PORT:1433];databaseName=[DB];",
  [ConnectionPoolType.ORC]: "jdbc:oracle:thin:@[HOST]:[PORT:1521]:[SID]",
  [ConnectionPoolType.DB2]: "jdbc:db2://[HOST]:[PORT:50000]/[DB]",
  [ConnectionPoolType.H2]: "jdbc:h2:tcp://[HOST]:[PORT]/[DB]",
  [ConnectionPoolType.DBY]: "jdbc:derby://[HOST]:[PORT]/[DB];create=true",
  [ConnectionPoolType.CS]: "jdbc:cloudscape:rmi://[HOST]:[PORT]/[DB]",
  [ConnectionPoolType.FB]: "jdbc:firebirdsql://[HOST]/[DB]",
  [ConnectionPoolType.IDS]: "jdbc:ids://[HOST]:[PORT]/conn?dsn=[DB]",
  [ConnectionPoolType.IDB]: "jdbc:idb:[PATH_TO_DB]/[DB].prp",
  [ConnectionPoolType.INDS]:
    "jdbc:informix-sqli://[HOST]:[PORT]/[DB]:INFORMIXSERVER=[SERVER]",
  [ConnectionPoolType.IB]: "jdbc:interbase://[HOST]/[DB_PATH]",
  [ConnectionPoolType.JDBC]: "jdbc:odbc:[DSN]",
  [ConnectionPoolType.SYB]: "jdbc:sybase:Tds:[HOST]:[PORT]/[DB]",
  [ConnectionPoolType.PBS]: "jdbc:pointbase://embedded/[DB]",
  [ConnectionPoolType.MNGO]: "jdbc:mongodb://[HOST]:[PORT]/[DB]",
  [ConnectionPoolType.CSS]: "jdbc:cassandra://[HOST]:[PORT]/[KEYSPACE]",
  [ConnectionPoolType.CSQL]: "jdbc:couchbase://[HOST]:[PORT]/[BUCKET]",
  [ConnectionPoolType.SNW]:
    "jdbc:snowflake://[ACCOUNT_NAME].snowflakecomputing.com/?db=[DB]&schema=[SCHEMA]",
  [ConnectionPoolType.ARED]: "jdbc:redshift://[HOST]:[PORT]/[DB]",
  [ConnectionPoolType.GBQ]:
    "jdbc:bigquery://https://www.googleapis.com/bigquery/v2:443;ProjectId=[PROJECT];DatasetId=[DATASET]",
  [ConnectionPoolType.AS]: "jdbc:sqlserver://[HOST]:[PORT];database=[DB]",
  [ConnectionPoolType.DBCK]: "jdbc:spark://[HOST]:[PORT]/[DB]",
  [ConnectionPoolType.TDTA]: "jdbc:teradata://[HOST]/[DB]",
  [ConnectionPoolType.PDB]: "jdbc:presto://[HOST]:[PORT]/[CATALOG]/[SCHEMA]",
  [ConnectionPoolType.DDB]: "jdbc:dynamodb://[HOST]:[PORT]/[TABLE]",
  [ConnectionPoolType.GBT]: "jdbc:bigtable://[HOST]/[INSTANCE_ID]/[TABLE]",
};
