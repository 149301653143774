import { Button, createStyles, CssBaseline, IconButton, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios, { CancelTokenSource } from "axios";
import "fontsource-roboto";
import _ from "lodash";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from "react-joyride";
import { useHistory } from "react-router-dom";
import "./App.scss";
import useConfirm from "./component/confirm/useConfirm";
import useContent, { LoadContentValue } from "./component/content/useContent";
import ContentContainer from "./component/ContentContainer";
import { EntityViewSkeleton } from "./component/entity/EntityView";
import EntityViewContainer from "./component/entity/EntityViewContainer";
import { EntityWizard } from "./component/entity/EntityWizard";
import ManageEntityDialog from "./component/entity/ManageEntityDialog";
import GuidedTours from "./component/guided_tours/GuidedTours";
import { NegroniIcon } from "./component/icon/NegronIIcon";
import { LoadingProgress } from "./component/LoadingProgress";
import AddSubManifestDialog from "./component/manifest/AddSubManifestDialog";
import useBuildManifest from "./component/manifest/build/useBuildManifest";
import ManifestConfigurationContainer from "./component/manifest/configuration/ManifestConfigurationContainer";
import { ExportManifestDialog, ExportTypes } from "./component/manifest/ExportManifestDialog";
import { ManifestViewSkeleton } from "./component/manifest/ManifestView";
import ManifestViewContainer from "./component/manifest/ManifestViewContainer";
import ManifestWizard from "./component/manifest/ManifestWizard";
import MenuAction from "./component/navigator/MenuAction";
import Navigator, { MockDataItemTree, PaginatedMockItemTree } from "./component/navigator/Navigator";
import PackageCards from "./component/package/PackageCards";
import PropertiesContainer from "./component/properties/PropertiesContainer";
import useProperties from "./component/properties/useProperties";
import useQuery from "./component/router/useQuery";
import useService from "./component/service/useService";
import Sidebar from "./component/Sidebar";
import SidebarContainer from "./component/SidebarContainer";
import MainToolbar from "./component/toolbar/Toolbar";
import { ExportCdmSchemaWizard } from "./component/wizard/ExportCdmSchemaWizard";
import { ExportDatabaseStructureWizard } from "./component/wizard/ExportDatabaseStructureWizard";
import { ExportDataModelWizard } from "./component/wizard/ExportDataModelWizard";
import { ExportPackageWizard } from "./component/wizard/ExportPackageWizard";
import { ImportWizard } from "./component/wizard/ImportWizard";
import { CDMManager } from "./core/CDMManager";
import { DataType } from "./core/dataType";
import { Filter } from "./core/Filter";
import CDMDocument, { CDMDocumentType } from "./core/observables/CDMDocument";
import DocumentItem from "./core/observables/DocumentItem";
import DocumentTab from "./core/observables/DocumentTab";
import Entity, { CreateEntityType, mapToCreateEntity, mapToCreateEntityWizard } from "./core/observables/Entity";
import { UploadPackage } from "./core/observables/Instance";
import Manifest, { CreateManifestWizard, MartiniProperties } from "./core/observables/Manifest";
import ManifestWorkspace from "./core/observables/ManifestWorkspace";
import Package from "./core/observables/Package";
import TypeAttribute from "./core/observables/TypeAttribute";
import { CDMContentProvider, CDMContextMenuProvider } from "./core/observables/Workspace";
import { Purpose } from "./core/purpose";
import { mapToEntity, TypeAttributeFilter } from "./core/services/EntityService";
import { mapToManifest } from "./core/services/ManifestService";
import { PackageFilter } from "./core/services/PackageService";
import { uniqueName } from "./util";
import AddEntityToManifestDialog from "./component/entity/AddEntityToManifestDialog";

const appStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "calc(100% - 104px)",
    },
  })
);

interface AppProps {
  cdmManager: CDMManager;
}

enum GuidedToursType {
  NONE,
  CREATE_MANIFEST,
  CREATE_ENTITY,
}

const App: React.FC<AppProps> = ({ cdmManager }) => {
  const classes = appStyles();
  const [selection, setSelection] = useState<any>(null);
  const [expandContent, setExpandContent] = useState(false);
  const [expandProperties, setExpandProperties] = useState(false);
  const theme = useTheme();
  const [updateNavigator, setUpdateNavigator] = useState(0);
  const [openAddSubManifestDialog, setOpenAddSubManifestDialog] =
    useState(false);
  const [addSubManifestManifest, setAddSubManifestManifest] =
    useState<Manifest>();
  const [openAddEntityManifestDialog, setOpenAddEntityManifestDialog] =
    useState(false);
  const [openManifestWizard, setOpenManifestWizard] = useState<boolean>(false);
  const [openEntityWizard, setOpenEntityWizard] = useState<boolean>(false);
  const [
    openExportDatabaseStructureWizard,
    setOpenExportDatabaseStructureWizard,
  ] = useState<boolean>(false);
  const [openExportCdmSchemaWizard, setOpenExportCdmSchemaWizard] =
    useState<boolean>(false);
  const [openExportDataModelWizard, setOpenExportDataModelWizard] =
    useState<boolean>(false);
  const [openExportPackageWizard, setOpenExportPackageWizard] =
    useState<boolean>(false);
  const [openImportWizard, setOpenImportWizard] = useState<boolean>(false);
  const [addEntityManifest, setAddEntityManifest] = useState<Manifest>();
  const [openExportManifestDialog, setOpenExportManifestDialog] =
    useState<boolean>(false);
  const [exportManifest, setExportManifest] = useState<Manifest>();
  const [expanded, setExpanded] = useState<string[]>([]);
  const { entityService, manifestService, adminOrgService, navigatorService } =
    useService();
  const forceShowNavigator = useMediaQuery(theme.breakpoints.up("sm"));
  const confirm = useConfirm();
  const { content, setLoadContent, processSaveChanges, setDirty, busy, dirty } = useContent();
  const { enqueueSnackbar } = useSnackbar();
  const navigatorContentProvider = new CDMContentProvider(
    manifestService,
    entityService,
    adminOrgService,
    navigatorService
  );
  const { propertySource, setProperty } = useProperties();
  const openBuildManifest = useBuildManifest();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navigatorInput, setNavigatorInput] = useState<any>();
  const [cdmNavigatorInput, setCdmNavigatorInput] = useState<any>();
  const [exportMode, setExportMode] = useState<ExportTypes>(
    ExportTypes.CDM_SCHEMA
  );
  const history = useHistory();
  const pageParameters = new URLSearchParams(window.location.search);
  const query = useQuery();
  const openManifestWizardParameter =
    query?.get("manifestWizard") || pageParameters?.get("manifestWizard");
  const [refreshingManifestNavigator, setRefreshingManifestNavigator] =
    useState(false);
  const [importEntitySkipPage, setImportEntitySkipPage] = useState<number>(1);
  const [importEntityDefaultManifest, setImportEntityDefaultManifest] =
    useState<any>(undefined);
  const [currentTab, setCurrentTab] = useState<string>("0");
  const [documentTabs, setDocumentTabs] = useState<DocumentTab[]>([]);
  const [runJoyride, setRunJoyride] = useState(false);
  const [joyrideIndex, setJoyrideIndex] = useState(0);
  const [createManifestTourTrigger, setCreateManifestTourTrigger] =
    useState(false);
  const [createEntityTourTrigger, setCreateEntityTourTrigger] = useState(false);
  const addToManifestMenuAction: MenuAction =     {
    supported: (node: any) => {
      const parent = node?.parent;
      if (parent?.parent?.documentType !== CDMDocumentType.MANIFEST_WORKSPACE)
        return false;
      return (
        node?.documentType === CDMDocumentType.ENTITY
      );
    },
    action: (node: any) => {
      if (!busy.current) {
        node.targetManifest = node.parent?.parent?.id;
        setEntity(node);
        setOpenAddToManifestDialog(true);
      }
    },
    title: "Add to Manifest",
    icon: "assignment-icon",
    children: [],
  };
  const menuActions: MenuAction[] = [
    {
      supported: (node: any) => {
        return (
          (node?.documentType === CDMDocumentType.MANIFEST &&
            !node?.isDefault) ||
          node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE ||
          (node?.label === "Entities" &&
            node?.type === "paginatedMockItem" &&
            node?.parent?.documentType === CDMDocumentType.MANIFEST_WORKSPACE)
        );
      },
      action: (node: any) => {
        let manifestId: number | undefined;
        let parent: any;
        if (node?.type === "paginatedMockItem") {
          const _parent = node?.parent;
          parent = node;
          if (_parent.documentType === CDMDocumentType.MANIFEST_WORKSPACE)
            manifestId = _parent.id;
        } else {
          manifestId = node?.id;
          parent = new MockDataItemTree("Entities", node);
        }

        if (manifestId) {
          manifestService
            .getManifestById(manifestId)
            .then((manifest) => {
              manifest.parent = parent;
              handleAddEntityManifestDialog(manifest);
            })
            .catch((e) =>
              enqueueSnackbar(
                `Unable to open add entity dialog - ${e?.message}`,
                { variant: "error" }
              )
            );
        } else {
          enqueueSnackbar(
            "Unable to open add entity dialog - no manifest parent."
          );
        }
      },
      title: "Add Entity",
      icon: "description-icon",
      children: [],
    },
    {
      supported: (node: any) => {
        return (
          (node?.documentType === CDMDocumentType.MANIFEST &&
            !node?.isDefault) ||
          node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE ||
          (node?.label === "Sub-Manifests" &&
            node?.type === "mockDataItem" &&
            node?.parent?.documentType === CDMDocumentType.MANIFEST_WORKSPACE)
        );
      },
      action: (node: any) => {
        let manifestId: number | undefined;
        let parent: any;
        if (node?.type === "mockDataItem") {
          const _parent = node?.parent;
          parent = node;
          if (_parent.documentType === CDMDocumentType.MANIFEST_WORKSPACE)
            manifestId = _parent.id;
        } else {
          parent = new MockDataItemTree("Sub-Manifests", node);
          manifestId = node?.id;
        }

        if (manifestId) {
          manifestService
            .getManifestById(manifestId)
            .then((manifest) => {
              manifest.parent = parent;
              handleAddSubManifestDialog(manifest);
            })
            .catch((e) => {
              enqueueSnackbar(
                `Unable to open add sub-manifest dialog - ${e?.message}`,
                { variant: "error" }
              );
            });
        } else {
          enqueueSnackbar(
            "Unable to open add sub-manifest dialog - no manifest parent."
          );
        }
      },
      title: "Add Sub-Manifest",
      icon: "assignment-icon",
      children: [],
    },
    {
      supported: (node: any) => {
        return (
          (node?.documentType === CDMDocumentType.MANIFEST &&
            !node?.isDefault) ||
          node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE
        );
      },
      action: () => {},
      title: "Export",
      icon: "import-export-icon",
      children: [
        {
          supported: (node: any) => {
            return (
              (node?.documentType === CDMDocumentType.MANIFEST &&
                !node?.isDefault) ||
              node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE
            );
          },
          action: async (node: any) => {
            if (!busy.current) {
              manifestService
                .getManifestById(node.id)
                .then((manifest) => {
                  openBuildManifest(manifest, true, false);
                })
                .catch((e) =>
                  enqueueSnackbar(
                    `Unable to open build manifest dialog - ${e?.message}`,
                    { variant: "error" }
                  )
                );
            }
          },
          title: "Martini Package",
          icon: "wrench-icon",
          children: [],
        },
        {
          supported: (node: any) => {
            return (
              (node?.documentType === CDMDocumentType.MANIFEST &&
                !node?.isDefault) ||
              node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE
            );
          },
          action: async (node: any) => {
            setExportMode(ExportTypes.DATABASE_SCHEMA);
            if (!busy.current) {
              manifestService
                .getManifestById(node.id)
                .then((manifest) => {
                  handleExportManifestDialog(manifest);
                })
                .catch((e) =>
                  enqueueSnackbar(
                    `Unable to open export manifest dialog - ${e?.message}`,
                    { variant: "error" }
                  )
                );
            }
          },
          title: "Database Structure",
          icon: "storage-icon",
          children: [],
        },
        {
          supported: (node: any) => {
            return (
              (node?.documentType === CDMDocumentType.MANIFEST &&
                !node?.isDefault) ||
              node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE
            );
          },
          action: async (node: any) => {
            setExportMode(ExportTypes.CDM_SCHEMA);
            if (!busy.current) {
              manifestService
                .getManifestById(node.id)
                .then((manifest) => {
                  handleExportManifestDialog(manifest);
                })
                .catch((e) =>
                  enqueueSnackbar(
                    `Unable to open export manifest dialog - ${e?.message}`,
                    { variant: "error" }
                  )
                );
            }
          },
          title: "CDM Schema",
          icon: "description-icon",
          children: [],
        },
        {
          supported: (node: any) => {
            return (
              (node?.documentType === CDMDocumentType.MANIFEST &&
                !node?.isDefault) ||
              node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE
            );
          },
          action: async (node: any) => {
            setExportMode(ExportTypes.DATA_MODEL);
            if (!busy.current) {
              manifestService
                .getManifestById(node.id)
                .then((manifest) => {
                  handleExportManifestDialog(manifest);
                })
                .catch((e) =>
                  enqueueSnackbar(
                    `Unable to open export manifest dialog - ${e?.message}`,
                    { variant: "error" }
                  )
                );
            }
          },
          title: "Data Model",
          icon: "model-icon",
          children: [],
        },
      ],
    },
    {
      supported: (node: any) => {
        return (
          (node?.documentType === CDMDocumentType.MANIFEST &&
            !node?.isDefault) ||
          node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE ||
          (node?.label === "Entities" &&
            node?.type === "paginatedMockItem" &&
            node?.parent?.documentType === CDMDocumentType.MANIFEST_WORKSPACE)
        );
      },
      action: (node: any) => {
        let manifestId: number | undefined;
        let parent: any;
        if (node?.type === "paginatedMockItem") {
          const _parent = node?.parent;
          parent = node;
          if (_parent.documentType === CDMDocumentType.MANIFEST_WORKSPACE)
            manifestId = _parent.id;
        } else {
          manifestId = node?.id;
          parent = new MockDataItemTree("Entities", node);
        }

        if (manifestId) {
          manifestService
            .getManifestById(manifestId)
            .then((manifest) => {
              manifest.parent = parent;
              setImportEntitySkipPage(3);
              setImportEntityDefaultManifest(manifest);
              handleOpenImportWizard();
            })
            .catch((e) =>
              enqueueSnackbar(
                `Unable to open add entity dialog - ${e?.message}`,
                { variant: "error" }
              )
            );
        } else {
          enqueueSnackbar(
            "Unable to open add entity dialog - no manifest parent."
          );
        }
      },
      title: "Import Entity",
      icon: "import-icon",
      children: [],
    },
    {
      supported: (node: any) => {
        const parent = node?.parent;
        if (parent?.parent?.documentType !== CDMDocumentType.MANIFEST_WORKSPACE)
          return false;
        return (
          node?.documentType === CDMDocumentType.SUB_MANIFEST &&
          !node?.isDefault
        );
      },
      action: (node: any) => {
        if (!busy.current) {
          const manifestId = node.parent.parent.id;
          confirm({
            message: "Do you really want to delete the sub-manifest?",
            title: "Delete Sub-Manifest",
          }).then(() => {
            manifestService
              .deleteSubManifest(manifestId, node.id)
              .then((_) => {
                localStorage.removeItem(
                  CDMManager.NEGRONI_CURRENT_SELECTED_NODE
                );
                enqueueSnackbar("Successfully deleted a sub-manifest", {
                  variant: "success",
                });
                setSelection(null);
                setLoadContent(getEmptyView());
                setProperty(null);
                refresh();
              })
              .catch((e) => {
                enqueueSnackbar(`Failed to delete sub-manifest: ${e.message}`, {
                  variant: "error",
                });
              });
          });
        }
      },
      title: "Delete",
      icon: "delete-icon",
      className: "delete",
      children: [],
    },
    {
      supported: (node: any) => {
        return (
          (node?.documentType === CDMDocumentType.MANIFEST &&
            !node?.isDefault) ||
          node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE
        );
      },
      action: async (node: any) => {
        if (!busy.current) {
          try {
            const parentManifests = await manifestService.getParentManifests(
              node.id
            );
            let warningMessage = "Do you really want to delete the manifest?";

            if (parentManifests.length > 0) {
              let names = parentManifests
                .slice(0, 5)
                .map((p) => `<li><strong>${p.name}</strong></li>`)
                .join("");
              if (parentManifests.length > 5)
                names = names + "<li><i>and more...</i></li>";
              warningMessage = `Manifest <strong>${node.name}</strong> is being referenced as sub-manifest in:
                                                <ul>${names}</ul>
                                                Do you want to proceed deleting the manifest?`;
            }

            confirm({ message: warningMessage, title: "Delete Manifest" }).then(
              (_) => {
                manifestService
                  .deleteManifest(node.id)
                  .then((_) => {
                    localStorage.removeItem(
                      CDMManager.NEGRONI_CURRENT_SELECTED_NODE
                    );
                    enqueueSnackbar("Successfully deleted a manifest", {
                      variant: "success",
                    });
                    setSelection(null);
                    setLoadContent(getEmptyView());
                    setProperty(null);
                    refreshManifestNavigator();
                    const deleteDocumentTab = documentTabs.find(
                      (tab) => tab.name === node.name
                    );
                    if (deleteDocumentTab) handleTabClose(deleteDocumentTab);
                  })
                  .catch((e) =>
                    enqueueSnackbar(`Failed to delete manifest: ${e.message}`, {
                      variant: "error",
                    })
                  );
              }
            );
          } catch (e) {
            enqueueSnackbar("Failed to delete manifest for unknown reason", {
              variant: "error",
            });
          }
        }
      },
      title: "Delete",
      icon: "delete-icon",
      className: "delete",
      children: [],
    },
    {
      supported: (node: any) => {
        const parent = node?.parent;
        if (
          parent?.type !== "paginatedMockItem" ||
          parent?.parent?.documentType !== CDMDocumentType.WORKSPACE
        )
          return false;
        return (
          node?.documentType === CDMDocumentType.ENTITY && !node?.isDefault
        );
      },
      action: (node: any) => {
        if (!busy.current) {
          confirm({
            message: "Do you really want to delete the entity?",
            title: "Delete Entity",
          }).then(() => {
            entityService
              .deleteEntity(node.id)
              .then((_) => {
                localStorage.removeItem(
                  CDMManager.NEGRONI_CURRENT_SELECTED_NODE
                );
                enqueueSnackbar("Successfully deleted a entity", {
                  variant: "success",
                });
                setSelection(null);
                setLoadContent(getEmptyView());
                setProperty(null);
                refresh();
              })
              .catch((e) =>
                enqueueSnackbar(`Failed to delete entity: ${e.message}`, {
                  variant: "error",
                })
              );
          });
        }
      },
      title: "Delete",
      icon: "delete-icon",
      className: "delete",
      children: [],
    },
    {
      supported: (node: any) => {
        const parent = node?.parent;

        if (parent && parent.parent && parent.parent.name === "core") return false;
        if (parent?.parent?.documentType !== CDMDocumentType.MANIFEST_WORKSPACE)
          return false;
        return (
          node?.documentType === CDMDocumentType.ENTITY && !node?.isDefault
        );
      },
      action: (node: any) => {
        if (!busy.current) {
          const manifestId = node.parent.parent.id;
          const manifestName = node.parent.parent.name;
          confirm({
            message: "Do you really want to delete the entity?",
            title: "Delete Entity",
          }).then(() => {
            manifestService
              .deleteEntity(manifestId, node.id)
              .then((_) => {
                localStorage.removeItem(
                  CDMManager.NEGRONI_CURRENT_SELECTED_NODE
                );
                enqueueSnackbar("Successfully deleted a entity", {
                  variant: "success",
                });
                setSelection(null);
                setLoadContent(getEmptyView());
                setProperty(null);
                refresh();
                const deleteDocumentTab = documentTabs.find(
                  (tab) => tab.name === `${manifestName}/${node.name}`
                );
                if (deleteDocumentTab) handleTabClose(deleteDocumentTab);
              })
              .catch((e) => {
                enqueueSnackbar(`Failed to delete entity: ${e.message}`, {
                  variant: "error",
                });
              });
          });
        }
      },
      title: "Delete",
      icon: "delete-icon",
      className: "delete",
      children: [],
    },
    {
      supported: (node: any) => {
        return (
          (node?.documentType === CDMDocumentType.MANIFEST &&
            !node?.isDefault) ||
          node?.documentType === CDMDocumentType.MANIFEST_WORKSPACE
        );
      },
      action: (node: any) => {
        let manifestId: number;
        manifestId = node?.id;

        if (manifestId) {
          const manifest = (cancelToken: CancelTokenSource) =>
            manifestService.getManifestById(manifestId, cancelToken.token);
          setLoadContent(
            getViewByDocument(
              CDMDocumentType.MANIFEST_WORKSPACE,
              manifest,
              "configuration"
            )
          );
        }
      },
      title: "Configure",
      icon: "settings-icon",
      children: [],
    },
    addToManifestMenuAction
  ];

  const [openGuidedTours, setOpenGuidedTours] = useState(false);
  const [guidedToursType, setGuidedToursType] = useState<GuidedToursType>(
    GuidedToursType.NONE
  );
  const [currentStep, setCurrentStep] = useState<Step[]>([]);
  const joyrideRef = useRef<Joyride | null>(null);
  const onBoardingSteps: Step[] = [
    {
      target: "body > #root > div > header > div > div > #negroni-logo",
      content:
        "Welcome to Negroni! Please spare a minute to learn about our page",
      disableBeacon: true,
      placement: "right-end",
    },
    {
      target: "body > #root > div > header > div > #negroni-profile-button",
      content:
        "Here is your profile details, clicking it will show the details",
      disableBeacon: true,
      offset: 20,
    },
    {
      target: "body > #root > div > header > div > a > #negroni-package-button",
      content:
        "Here is the package link, clicking it will direct you to the package page to see your generated packages",
      disableBeacon: true,
    },
    {
      target:
        "body > #root > div > #negroni-toolbar-drawer > div > #negroni-toolbar > #negroni-toolbar-manifest-wizard",
      content:
        "Here is the Manifest Wizard button, clicking it will open the Manifest wizard to create your first Manifest",
      disableBeacon: true,
    },
    {
      target:
        "body > #root > div > #negroni-toolbar-drawer > div > #negroni-toolbar > #negroni-toolbar-entity-wizard",
      content:
        "Here is the Entity Wizard button, clicking it will open the Entity wizard to create your first Entity",
      disableBeacon: true,
    },
    {
      target:
        "body > #root > div > #negroni-toolbar-drawer > div > #negroni-toolbar > #negroni-toolbar-generate-database",
      content:
        "Here is the Generate Database button, clicking it will open the Generate Database wizard to generate your database schema",
      disableBeacon: true,
    },
    {
      target:
        "body > #root > div > #negroni-toolbar-drawer > div > #negroni-toolbar > #negroni-toolbar-generate-cdm",
      content:
        "Here is the Generate CDM button, clicking it will open the Generate CDM wizard to generate your CDM schema",
      disableBeacon: true,
    },
    {
      target:
        "body > #root > div > #negroni-toolbar-drawer > div > #negroni-toolbar > #negroni-toolbar-generate-martini",
      content:
        "Here is the Generate Martini button, clicking it will open the Generate Martini wizard to build your manifest",
      disableBeacon: true,
    },
    {
      target:
        "body > #root > div > #negroni-toolbar-drawer > div > #negroni-toolbar > #negroni-toolbar-import-wizard",
      content:
        "Here is the Import Wizard button, clicking it will open the Import wizard to import your documents",
      disableBeacon: true,
    },
    {
      target:
        "body > #root > div > #negroni-sidebar-container > div:nth-child(2) > div",
      content:
        "Here is the Navigator View, here is where you will see all your Manifests/Entities document",
      disableBeacon: true,
      placement: "right-start",
    },
  ];
  const createManifestSteps: Step[] = [
    {
      target:
        "body > #root > div > #negroni-toolbar-drawer > div > #negroni-toolbar > #negroni-toolbar-manifest-wizard",
      content: "Click the Manifest Wizard button to proceed.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-manifest-wizard-dialog > div > #negroni-manifest-wizard-content > div > div > div",
      content:
        "Write the desire name of the manifest you want, then please click Next to proceed.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-manifest-wizard-dialog > div > #negroni-manifest-wizard-actions > button:last-child",
      content: "Click Next to proceed to the next page.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-manifest-wizard-dialog > div > #negroni-manifest-wizard-content",
      content:
        "On this page you can manage the entities you want to put on your manifest either it's a existing one or a new one, but we will skip this for now, please click Next to proceed.",
      disableBeacon: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-manifest-wizard-dialog > div > #negroni-manifest-wizard-content > div > div > div > div > div > button:last-child",
      content:
        "We will be creating an exclusive entity, Click Next to proceed.",
      disableBeacon: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-manifest-wizard-dialog > div > #negroni-manifest-wizard-content > div > div #negroni-create-exclusive-entity-content input",
      content:
        "Write the desire name of the entity you want, then please click Next to proceed.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-manifest-wizard-dialog > div > #negroni-manifest-wizard-content > div > div #negroni-create-exclusive-entity-content button",
      content: "Click Add.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-manifest-wizard-dialog > div > #negroni-manifest-wizard-actions > button:last-child",
      content:
        "Click Finish and check the new created Manifest in your navigator after the end of this tour.",
      disableBeacon: true,
      spotlightClicks: true,
    },
  ];

  const createEntitySteps: Step[] = [
    {
      target:
        "body > #root > div > #negroni-toolbar-drawer > div > #negroni-toolbar > #negroni-toolbar-entity-wizard",
      content: "Click the Entity Wizard button to proceed.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-entity-wizard-dialog > div > #negroni-entity-wizard-content > div > div > div > div",
      content:
        "You can choose to duplicate an existing entity or create a local entity",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-entity-wizard-dialog > div > #negroni-entity-wizard-content > div:last-child > div > div",
      content:
        "On this field you will write the name of your entity, but for the meantime we will use the default one, please click Next to proceed.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-entity-wizard-dialog > div > #negroni-entity-wizard-actions > button:last-child",
      content: "Click Next to proceed to the next page.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-entity-wizard-dialog > div > #negroni-entity-wizard-content",
      content:
        "select a manifest where your entity will be create, please click Next to proceed.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-entity-wizard-dialog > div > #negroni-entity-wizard-actions > button:last-child",
      content: "Click Next to proceed.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-entity-wizard-dialog > div > #negroni-entity-wizard-content > div > div > div",
      content:
        "Write the desire type attribute name for your entity, click Next to proceed.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-entity-wizard-dialog > div > #negroni-entity-wizard-content > div > div > div:last-child > button",
      content: "Click + to add a type attribute to our Entity.",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target:
        "body > .ReactModalPortal > div > #negroni-entity-wizard-dialog > div > #negroni-entity-wizard-actions > button:last-child",
      content:
        "Now that our entity is complete, Click Finish and check the navigator your created Entity after the end of this our.",
      disableBeacon: true,
      spotlightClicks: true,
    },
  ];

  const handleEndTour = () => {
    setRunJoyride(false);
    setCurrentStep([]);
    setJoyrideIndex(0);
    setGuidedToursType(GuidedToursType.NONE);
  };

  const handleAddSubManifestDialog = (manifest: Manifest) => {
    setAddSubManifestManifest(manifest);
    setOpenAddSubManifestDialog(true);
  };

  const handleCloseAddSubManifestDialog = () => {
    setOpenAddSubManifestDialog(false);
  };

  const onAddSubManifest = (
    subManifest: DocumentItem,
    manifest: Manifest
  ): Promise<string | undefined> => {
    return manifestService
      .addSubManifest(manifest.id, [subManifest.id])
      .then((_) => {
        localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, "true");
        refresh();
        subManifest.parent = manifest.parent;
        setSelection(subManifest);
        enqueueSnackbar("Successfully added sub-manifest to manifest.", {
          variant: "success",
        });
      })
      .catch((error) => error || "Failed to add sub-manifest to manifest");
  };

  const handleAddEntityManifestDialog = (manifest: Manifest) => {
    setAddEntityManifest(manifest);
    handleOpenManageEntityDialog();
  };

  const handleExportManifestDialog = (manifest: Manifest) => {
    setExportManifest(manifest);
    setOpenExportManifestDialog(true);
  };

  const handleAddEntity = (
    entity: DocumentItem,
    manifest: Manifest
  ): Promise<string | undefined> => {
    const entityIds = manifest.entityPaths.map((item) => item.id);
    return manifestService
      .addEntity(manifest.id, [entity.id], CreateEntityType.EXCLUSIVE)
      .then((_manifest) => {
        localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, "true");
        refresh();
        const newEntity = _manifest.entityPaths.find(
          (item) => !entityIds.includes(item.id)
        );
        if (newEntity) {
          newEntity.parent = manifest.parent;
          setSelection(newEntity);
          enqueueSnackbar("Successfully added entity to manifest.", {
            variant: "success",
          });
        }
      })
      .catch((error) => error || "Failed to add entity to manifest");
  };

  const handleCreateExclusiveEntity = (
    entity: Entity,
    manifest: Manifest
  ): Promise<string | undefined> => {
    const primaryKey = new TypeAttribute("id", DataType.INTEGER);

    primaryKey.purpose = Purpose.IDENTIFIED_BY;
    entity.typeAttributes = [primaryKey];
    return entityService
      .createEntity(
        mapToCreateEntity(
          entity,
          entity?.type || CreateEntityType.EXCLUSIVE,
          manifest.id
        )
      )
      .then((entity) => {
        localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, "true");
        entity.targetManifest = manifest.id;
        entity.parent = manifest.parent;
        refresh();
        setSelection(entity);
        enqueueSnackbar("Successfully created local entity to manifest.", {
          variant: "success",
        });
      })
      .catch((error) => error || "Failed to create local entity to manifest");
  };

  const handleExportManifest = (
    manifest: Manifest,
    exportType: ExportTypes,
    databaseType: string = "postgresql",
    applyAutoIncrement: boolean
  ): Promise<string | undefined> => {
    return manifestService
      .exportManifestDatabaseStructure(
        manifest.id,
        exportType,
        databaseType,
        applyAutoIncrement
      )
      .then((data) => {
        const tempLink = document.createElement("a");
        tempLink.href = window.URL.createObjectURL(
          new Blob([data], { type: "application/zip;charset=UTF-8" })
        );
        tempLink.download = `${manifest.name}-${
          exportType === ExportTypes.DATABASE_SCHEMA ? databaseType : "cdm"
        }.zip`;
        tempLink.click();
      })
      .catch((error) => {
        return error || "Failed to export manifest";
      });
  };

  const handleCreateManifestViaWizard = (
    manifest: CreateManifestWizard
  ): Promise<string | undefined> => {
    return manifestService
      .createManifestViaWizard(manifest)
      .then((data) => {
        if (data.generateType === "Manifest") {
          localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, "true");
          const manifest = mapToManifest(data.manifest.id, data.manifest);
          enqueueSnackbar(`Manifest '${manifest.name}' successfully built`, {
            variant: "success",
          });
          refreshManifestNavigator(manifest.name);
        } else {
          localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, "true");
          const manifest = mapToManifest(data.manifest.id, data.manifest);
          enqueueSnackbar(`Manifest '${manifest.name}' successfully built`, {
            variant: "success",
          });
          refreshManifestNavigator();
          history.push({
            pathname: "/packages",
            search: `?new=${data.manifest.name}`,
          });
        }
      })
      .catch((error) => {
        return error || "Failed to create manifest";
      });
  };

  const handleImportManifest = (
    manifestFile: FormData
  ): Promise<string | undefined> => {
    return manifestService
      .importManifest(manifestFile)
      .then((data) => {
        localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, "true");
        const manifest = mapToManifest(data.manifest.id, data.manifest);
        enqueueSnackbar(`Manifest '${manifest.name}' successfully imported`, {
          variant: "success",
        });
        refreshManifestNavigator(manifest.name);
      })
      .catch((error) => {
        return error || "Failed to import manifest";
      });
  };

  const handleCreateEntityViaWizard = (
    entity: Entity,
    manifest?: Manifest,
    duplicateEntityId?: number,
    version?: string
  ): Promise<string | undefined> => {
    const primaryKey = new TypeAttribute("id", DataType.INTEGER);
    primaryKey.purpose = Purpose.IDENTIFIED_BY;
    entity.typeAttributes = [...entity.typeAttributes, primaryKey];
    entity.type = manifest
      ? CreateEntityType.EXCLUSIVE
      : CreateEntityType.TEMPLATE;
    return entityService
      .createEntityWizard(
        mapToCreateEntityWizard(
          entity,
          entity?.type || CreateEntityType.EXCLUSIVE,
          manifest?.id || -1,
          duplicateEntityId || -1,
          version || "1.0.0"
        )
      )
      .then((entity) => {
        localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, "true");
        if (manifest) {
          const manifestWorkspace = new ManifestWorkspace(
            manifest.id,
            manifest.name
          );
          entity.targetManifest = manifest.id;
          entity.parent = new PaginatedMockItemTree(
            "Entities",
            manifestWorkspace
          );
        }
        refresh();
        setSelection(entity);
        enqueueSnackbar("Successfully created entity.", { variant: "success" });
      })
      .catch((error) => error || "Failed to create entity");
  };

  const handleImportEntity = (
    entityFile: FormData,
    manifest?: Manifest
  ): Promise<string | undefined> => {
    const targetManifest = manifest?.id || -1;
    return entityService
      .importEntity(entityFile, targetManifest)
      .then((entity) => {
        localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, "true");
        if (manifest) {
          const manifestWorkspace = new ManifestWorkspace(
            manifest.id,
            manifest.name
          );
          entity.targetManifest = manifest.id;
          entity.parent = new PaginatedMockItemTree(
            "Entities",
            manifestWorkspace
          );
        }
        refresh();
        setSelection(entity);
        enqueueSnackbar("Successfully imported entity.", {
          variant: "success",
        });
      })
      .catch((error) => error || "Failed to import entity");
  };

  const upsertConfiguration = (
    manifest: Manifest,
    configuration: MartiniProperties
  ): Promise<string | undefined> => {
    return manifestService
      .createManifestConfiguration(manifest.id, configuration)
      .then((data) => {
        enqueueSnackbar("Successfully save configuration.", {
          variant: "success",
        });
      })
      .catch((error) => error || "Failed save configuration");
  };

  const fetchEntityParentManifest = (path: string): string => {
    return path.split("/")[3];
  };

  useEffect(() => {
    let manifestNavigator: ManifestWorkspace[] = [];
    setLoadContent(undefined);
    setRefreshingManifestNavigator(true);
    manifestService.getManifests(Filter.PRIVATE, -1).then((result) => {
      result.result.forEach((manifest) => {
        manifestNavigator.push(
          new ManifestWorkspace(manifest.id, manifest.name)
        );
      });
      setNavigatorInput([...manifestNavigator]);
      setRefreshingManifestNavigator(false);
    });

    // CDM Nav
    let cdmManifestNavigator: ManifestWorkspace[] = [];
    setLoadContent(undefined);
    setRefreshingManifestNavigator(true);
    manifestService
      .getManifests(Filter.DEFAULT, -1, 0, 0, undefined)
      .then((result) => {
        result.result.forEach((manifest) => {
          cdmManifestNavigator.push(
            new ManifestWorkspace(manifest.id, manifest.name)
          );
        });
        setCdmNavigatorInput([...cdmManifestNavigator]);
        setRefreshingManifestNavigator(false);
      });

    localStorage.setItem(CDMManager.NEGRONI_LAST_VISIT, "Default");
    if (
      localStorage.getItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE) !== null
    ) {
      const currentSelected = JSON.parse(
        localStorage.getItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE) || "{}"
      );
      setSelection(currentSelected);
    }
    if (openManifestWizardParameter === "open") {
      setOpenManifestWizard(true);
    }
  }, []);

  useEffect(() => {
    setExpandProperties(propertySource !== null);
  }, [propertySource]);

  const getEmptyView = (): LoadContentValue => ({
    document: undefined,
    view: (_) => <div />,
  });

  const loadContent = (
    document: CDMDocument | null,
    viewType: string = "default",
    parent: any = undefined
  ) => {
    if (!busy.current) {
      setLoadContent(
        document
          ? getViewByDocument(
              document.documentType,
              (_) => Promise.resolve(document),
              viewType,
              parent
            )
          : getEmptyView()
      );
    }
  };

  const getEntityView = (entity: Entity): React.ReactElement => {
    return (
      <EntityViewContainer
        entity={entity}
        onChange={(document, message) => {
          refresh();
          setSelection(document);
          if (message) enqueueSnackbar(message, { variant: "success" });
        }}
        refreshStructure={() => {}}
        viewMode={"Default"}
      />
    );
  };

  const getManifestView = (manifest: Manifest) => {
    return (
      <ManifestViewContainer
        manifest={manifest}
        onChange={(document, message) => {
          refreshManifestNavigator();
          setSelection(document);
          if (message) enqueueSnackbar(message, { variant: "success" });
        }}
        viewMode={"Default"}
      />
    );
  };

  const getManifestConfigurationView = (manifest: Manifest) => {
    return (
      <ManifestConfigurationContainer
        manifest={manifest}
        upsertConfiguration={upsertConfiguration}
      />
    );
  };

  const getViewByDocument = (
    documentType: CDMDocumentType,
    document: (cancelToken: CancelTokenSource) => Promise<CDMDocument>,
    viewType: string,
    parent: any = undefined
  ): LoadContentValue | undefined => {
    if (documentType === CDMDocumentType.ENTITY) {
      return {
        document,
        view: (document) => {
          document.parent = parent;
          const entityDocument = document as Entity;
          //localStorage.setItem(`entity-${entityDocument.id}`, JSON.stringify(handleForceSelection(entityDocument)))
          return getEntityView(entityDocument);
        },
        options: { loader: <EntityViewSkeleton /> },
      };
    } else if (
      documentType === CDMDocumentType.MANIFEST ||
      documentType === CDMDocumentType.MANIFEST_WORKSPACE
    ) {
      return {
        document,
        view: (document) => {
          document.parent = parent;
          const manifestDocument = document as Manifest;
          //localStorage.setItem(`manifest-${manifestDocument.id}`, JSON.stringify(handleForceSelection(manifestDocument)))
          return viewType === "configuration"
            ? getManifestConfigurationView(manifestDocument)
            : getManifestView(manifestDocument);
        },
        options: { loader: <ManifestViewSkeleton /> },
      };
    }
  };

  function handleForceSelection(node: any): any {
    const nodeClone = _.cloneDeep(node);
    if (nodeClone instanceof Entity) {
      nodeClone.typeAttributes.forEach((typeAttribute) => {
        if (typeAttribute.parent) {
          typeAttribute.parent = undefined;
        }
        if (typeAttribute._parent) {
          typeAttribute._parent = undefined;
        }
      });

      nodeClone.entityAttributes.forEach((entityAttributes) => {
        if (entityAttributes.parent) {
          entityAttributes.parent = undefined;
        }
      });
    }
    if (nodeClone.parent) {
      nodeClone.parent = makeLastParent(nodeClone.parent);
    }
    if (nodeClone._parent) {
      nodeClone._parent = makeLastParent(nodeClone._parent);
    }
    nodeClone.children = [];
    return nodeClone;
  }

  function makeLastParent(parent: any): any {
    if (Array.isArray(parent)) {
      return undefined;
    } else if (parent === undefined) {
      return undefined;
    } else {
      parent.children = [];
      if (parent.parent) {
        parent.parent = makeLastParent(parent.parent);
      }
      if (parent._parent) {
        parent._parent = makeLastParent(parent._parent);
      }
      return parent;
    }
  }

  useEffect(() => {
    if (!selection) {
      setLoadContent(getEmptyView());
    }
    if (!busy.current) {
      if (selection?.type === "documentItem") {
        if (selection.documentType === CDMDocumentType.ENTITY) {
          const selectionStorage = localStorage.getItem(
            `entity-${selection?.id}`
          );
          if (selectionStorage) {
            const parsed = JSON.parse(selectionStorage);
            const entity = mapToEntity(
              parsed,
              parsed.typeAttributes,
              parsed.entityAttributes
            );
            setExpandContent(true);
            loadContent(entity, "default", selection.parent);
          } else {
            const typeAttributeFilter = selection.isDefault
              ? TypeAttributeFilter.ALL
              : TypeAttributeFilter.UNRESOLVED;
            const entity = (cancelToken: CancelTokenSource) =>
              entityService.getEntityById(
                selection.id,
                typeAttributeFilter,
                undefined,
                cancelToken.token
              );
            setExpandContent(true);
            setLoadContent(
              getViewByDocument(
                CDMDocumentType.ENTITY,
                entity,
                "default",
                selection.parent
              )
            );
          }
        } else if (
          selection.documentType === CDMDocumentType.MANIFEST ||
          selection.documentType === CDMDocumentType.MANIFEST_WORKSPACE ||
          selection.documentType === CDMDocumentType.SUB_MANIFEST
        ) {
          const selectionStorage = localStorage.getItem(
            `manifest-${selection?.id}`
          );
          if (selectionStorage) {
            loadContent(JSON.parse(selectionStorage));
          } else {
            const manifest = (cancelToken: CancelTokenSource) =>
              manifestService.getManifestById(selection.id, cancelToken.token);
            setExpandContent(true);
            setLoadContent(
              getViewByDocument(
                CDMDocumentType.MANIFEST,
                manifest,
                "default",
                selection.parent
              )
            );
          }
        }
        if (localStorage.getItem(CDMManager.NEGRONI_RESET_SELECTED_NODE)) {
          localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
          localStorage.removeItem(CDMManager.NEGRONI_RESET_SELECTED_NODE);
        } else {
          localStorage.setItem(
            CDMManager.NEGRONI_CURRENT_SELECTED_NODE,
            JSON.stringify(handleForceSelection(selection))
          );
        }
      } else if (selection?.documentType) {
        loadContent(selection);
        if (localStorage.getItem(CDMManager.NEGRONI_RESET_SELECTED_NODE)) {
          localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
          localStorage.removeItem(CDMManager.NEGRONI_RESET_SELECTED_NODE);
        } else {
          localStorage.setItem(
            CDMManager.NEGRONI_CURRENT_SELECTED_NODE,
            JSON.stringify(handleForceSelection(selection))
          );
        }
      }
    }
  }, [selection]);

  useEffect(() => {
    if (content.props?.entity) {
      const entityManifestName =
        content.props?.entity?.parent?.parent?.name ||
        fetchEntityParentManifest(selection?.path || "");
      if (
        documentTabs.find(
          (document) =>
            document.name === `${entityManifestName}/${selection?.name}`
        ) === undefined
      ) {
        setDocumentTabs([
          ...documentTabs,
          {
            name: `${entityManifestName}/${selection?.name}`,
            content: content,
            icon: "description-icon",
          },
        ]);
        setCurrentTab(`${entityManifestName}/${selection?.name}`);
      } else {
        setCurrentTab(`${entityManifestName}/${selection?.name}`);
      }
    }
    if (content.props?.manifest) {
      if (content.props?.upsertConfiguration) {
        if (
          documentTabs.find(
            (document) =>
              document.name === `${content.props?.manifest?.name}/Configuration`
          ) === undefined
        ) {
          setDocumentTabs([
            ...documentTabs,
            {
              name: `${content.props?.manifest?.name}/Configuration`,
              content: content,
              icon: "settings-icon",
            },
          ]);
          setCurrentTab(`${content.props?.manifest?.name}/Configuration`);
        } else {
          setCurrentTab(`${content.props?.manifest?.name}/Configuration`);
        }
      } else {
        if (
          documentTabs.find((document) => document.name === selection?.name) ===
          undefined
        ) {
          setDocumentTabs([
            ...documentTabs,
            {
              name: selection?.name,
              content: content,
              icon: "assignment-icon",
            },
          ]);
          setCurrentTab(selection?.name);
        } else {
          setCurrentTab(selection?.name);
        }
      }
    }
  }, [content]);

  function refreshManifestNavigator(selectWorkspace?: string) {
    let workspaceDocument: ManifestWorkspace;
    setRefreshingManifestNavigator(true);
    let manifestNavigator: ManifestWorkspace[] = [];
    setLoadContent(undefined);
    manifestService.getManifests(Filter.PRIVATE, -1).then((result) => {
      result.result.forEach((manifest) => {
        const manifestWorkspace = new ManifestWorkspace(
          manifest.id,
          manifest.name
        );
        manifestNavigator.push(manifestWorkspace);
        if (selectWorkspace && selectWorkspace === manifest.name)
          workspaceDocument = manifestWorkspace;
      });
      setNavigatorInput([...manifestNavigator]);
      setRefreshingManifestNavigator(false);
      if (selectWorkspace) setSelection(workspaceDocument);
    });
  }

  function refresh() {
    setUpdateNavigator(updateNavigator + 1);
  }

  const handleCloseManageEntityDialog = () => {
    setOpenAddEntityManifestDialog(false);
  };

  const handleOpenManageEntityDialog = () => {
    setOpenAddEntityManifestDialog(true);
  };

  const handleCloseManifestWizard = () => {
    setOpenManifestWizard(false);
  };

  const handleOpenManifestWizard = () => {
    setOpenManifestWizard(true);
    if (runJoyride && guidedToursType === GuidedToursType.CREATE_MANIFEST) {
      setJoyrideIndex(1);
    }
  };

  const handleCloseEntityWizard = () => {
    setOpenEntityWizard(false);
  };

  const handleOpenEntityWizard = () => {
    setOpenEntityWizard(true);
    if (runJoyride && guidedToursType === GuidedToursType.CREATE_ENTITY) {
      setJoyrideIndex(1);
    }
  };

  const handleCloseExportDatabaseStructureWizard = () => {
    setOpenExportDatabaseStructureWizard(false);
  };

  const handleOpenExportDatabaseStructureWizard = () => {
    setOpenExportDatabaseStructureWizard(true);
  };

  const handleCloseExportCdmSchemaWizard = () => {
    setOpenExportCdmSchemaWizard(false);
  };

  const handleOpenExportCdmSchemaWizard = () => {
    setOpenExportCdmSchemaWizard(true);
  };

  const handleCloseExportPackageWizard = () => {
    setOpenExportPackageWizard(false);
  };

  const handleOpenExportPackageWizard = () => {
    setOpenExportPackageWizard(true);
  };

  const handleCloseImportWizard = () => {
    setOpenImportWizard(false);
    setImportEntitySkipPage(1);
    setImportEntityDefaultManifest(undefined);
  };

  const handleOpenImportWizard = () => {
    setOpenImportWizard(true);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleTabChange = (document: DocumentTab) => {
    setCurrentTab(document?.name);
  };

    const handleTabClose = async (document: DocumentTab) => {    
        const cancelToken = axios.CancelToken.source();    
        if (dirty) {
            try {
                await processSaveChanges(getEmptyView(), cancelToken);
            } catch (error) {
                setDirty(false)
            }
            
        }
        let temporaryTabs = documentTabs
        const tabIndex = documentTabs.indexOf(document, 0)
        temporaryTabs.splice(tabIndex, 1)

        let newActiveDocument: any = undefined
        if(tabIndex === 0) {
            if(temporaryTabs.length > 0) {
                newActiveDocument = temporaryTabs[tabIndex]
            } else {
                newActiveDocument = undefined
            }
        } else {
            newActiveDocument = temporaryTabs[tabIndex - 1]
        }
        handleTabChange(newActiveDocument)
        setDocumentTabs([...temporaryTabs])
    }

  const handleTabCloseAll = () => {
    const newActiveDocument: any = undefined;
    handleTabChange(newActiveDocument);
    setDocumentTabs([]);
  };

  const handleTabCloseOthers = (document: DocumentTab) => {
    handleTabChange(document);
    setDocumentTabs([document]);
  };

  const handleTabCloseTabsRight = (
    document: DocumentTab,
    current: DocumentTab
  ) => {
    const tabIndex = documentTabs.indexOf(document, 0);
    const currentTabIndex = documentTabs.indexOf(current, 0);
    let temporaryTabs = documentTabs;
    let tabsIndexRemove: number[] = [];

    for (let i = tabIndex + 1; i < temporaryTabs.length; i++) {
      tabsIndexRemove.push(i);
    }

    temporaryTabs = temporaryTabs.filter((value, index) => {
      return !tabsIndexRemove.includes(index);
    });

    if (tabsIndexRemove.includes(currentTabIndex)) {
      handleTabChange(temporaryTabs[temporaryTabs.length - 1]);
    }

    setDocumentTabs([...temporaryTabs]);
  };

  const handleTablCloseTabsLeft = (
    document: DocumentTab,
    current: DocumentTab
  ) => {
    const tabIndex = documentTabs.indexOf(document, 0);
    const currentTabIndex = documentTabs.indexOf(current, 0);
    let temporaryTabs = documentTabs;
    let tabsIndexRemove: number[] = [];

    for (let i = tabIndex - 1; i >= 0; i--) {
      tabsIndexRemove.push(i);
    }

    temporaryTabs = temporaryTabs.filter((value, index) => {
      return !tabsIndexRemove.includes(index);
    });

    if (tabsIndexRemove.includes(currentTabIndex)) {
      handleTabChange(temporaryTabs[0]);
    }

    setDocumentTabs([...temporaryTabs]);
  };

  const startOnBoarding = () => {
    setRunJoyride(true);
    setCurrentStep(onBoardingSteps);
  };

  const startCreateManifestTour = () => {
    setRunJoyride(true);
    setCurrentStep(createManifestSteps);
    setGuidedToursType(GuidedToursType.CREATE_MANIFEST);
    setCreateManifestTourTrigger(true);
  };

  const startCreateEntityTour = () => {
    setRunJoyride(true);
    setCurrentStep(createEntitySteps);
    setGuidedToursType(GuidedToursType.CREATE_ENTITY);
    setCreateEntityTourTrigger(true);
  };

  const joyrideCallBack = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].findIndex((s) => s === status) !== -1
    ) {
      setRunJoyride(false);
      setCurrentStep([]);
      setJoyrideIndex(0);
      setGuidedToursType(GuidedToursType.NONE);
      setCreateManifestTourTrigger(false);
      setCreateEntityTourTrigger(false);
    } else if (
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].findIndex(
        (e) => e === type
      ) !== -1
    ) {

      setRunJoyride(false);
      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyrideIndex(nextIndex);

      if (
        nextIndex === 1 &&
        guidedToursType === GuidedToursType.CREATE_MANIFEST
      ) {
        setOpenManifestWizard(true);
      }

      if (
        nextIndex === 1 &&
        guidedToursType === GuidedToursType.CREATE_ENTITY
      ) {
        setOpenEntityWizard(true);
      }

      setTimeout(() => {
        setRunJoyride(true);
      }, 100);
    }
  };

  const handleCloseGuidedTours = () => {
    setOpenGuidedTours(false);
  };

  const handleOpenGuidedTours = () => {
    setOpenGuidedTours(true);
  };

  const joyrideIndexTrigger = (index: number) => {
    setJoyrideIndex(index);
  };

  const handleStartGuidedTours = (type: string) => {
    if (type === "CreateManifestTour") {
      startCreateManifestTour();
    }
    if (type === "CreateEntityTour") {
      startCreateEntityTour();
    }
  };

  const generateUniqueNameFromNavigator = (base: string): string => {
    if (Array.isArray(navigatorInput)) {
      return uniqueName(
        base,
        navigatorInput.map((item) => item.name)
      );
    }
    return base;
  };

  enum NavigatorTab {
    MANIFESTS = "MANIFESTS",
    PACKAGES = "PACKAGES",
    CDM = "CDM",
  }

  const [selectedNavigator, setSelectedNavigator] = React.useState(
    NavigatorTab.MANIFESTS
  );

  const handleTabClick = (value: NavigatorTab) => {
    setSelectedNavigator(value);
  };

  const {packageService, workspaceService} = useService();
  const [packages, setPackages] = useState<Package[]>([]);
  const [privatePackageSwitch, setPrivatePackageSwitch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const loadPackagesToken = useRef<CancelTokenSource>();


  useEffect(() => {
      const cancelTokenSource = loadPackages();
      return () => cancelTokenSource.cancel();
  }, [privatePackageSwitch]);

  const loadPackages = (): CancelTokenSource => {
      setIsLoading(true);
      const cancelTokenSource = axios.CancelToken.source();
      packageService.getPackage(privatePackageSwitch ? PackageFilter.PRIVATE : PackageFilter.PUBLIC, cancelTokenSource.token)
          .then(packages => {
              setPackages(packages)
              setIsLoading(false);
          });
      return cancelTokenSource;
  }
  ;

  const handlePackageStatusChanged = () => {
    if (loadPackagesToken.current) loadPackagesToken.current?.cancel();
    loadPackagesToken.current = loadPackages();
}

const handleUploadPackage = (packageId: number, uploadPackage: UploadPackage): Promise<string | undefined> => {
    return workspaceService.uploadPackage(packageId, uploadPackage)
        .then(response => {
            enqueueSnackbar(`${response.message}`, {variant: 'success'});
            return response.result;
        })
        .catch(error => {
            enqueueSnackbar(`Failed to upload package: ${error}`, {variant: 'error'});
            return error || 'Failed to upload package';
        });
}

const [entity, setEntity] = React.useState<Entity | undefined>();
const [openAddToManifestDialog, setOpenAddToManifestDialog] =
  React.useState(false);

function handleCloseDialog() {
  setOpenAddToManifestDialog(false);
}

const handleAddEntityToManifest = (
  manifest: DocumentItem,
  entity: Entity
): Promise<string | undefined> => {
  const token = axios.CancelToken.source();
  return manifestService
    .addEntity(manifest.id, [entity.id], CreateEntityType.EXCLUSIVE,  token?.token)
    .then((manifest) => {
      enqueueSnackbar("Successfully added entity to manifest.", {
        variant: "success",
      });
    })

    .catch((error) => error || "Failed to add entity to manifest");
};

  return (
    <>
      <Joyride
        ref={joyrideRef}
        callback={joyrideCallBack}
        steps={currentStep}
        run={runJoyride}
        continuous
        hideCloseButton
        showProgress
        showSkipButton
        disableOverlayClose={true}
        stepIndex={joyrideIndex}
        styles={{
          options: {
            arrowColor: "#9b59b6",
            primaryColor: "#9b59b6",
            zIndex: 99999,
          },
          spotlight: {
            borderRadius: 0,
            pointerEvents: "none",
          },
          tooltipContainer: {
            pointerEvents: "auto",
          },
        }}
      />
      <ExportManifestDialog
        open={openExportManifestDialog}
        onClose={() => {
          setOpenExportManifestDialog(false);
        }}
        exportMode={exportMode}
        manifest={exportManifest || new Manifest(-1, "1.0.0", "")}
        onExportManifest={handleExportManifest}
      />
      <AddSubManifestDialog
        open={openAddSubManifestDialog && !!addSubManifestManifest}
        onClose={handleCloseAddSubManifestDialog}
        manifest={addSubManifestManifest}
        manifestService={manifestService}
        adminOrgService={adminOrgService}
        onFinish={onAddSubManifest}
        viewMode={"Default"}
      />
      <ManageEntityDialog
        open={openAddEntityManifestDialog}
        onClose={handleCloseManageEntityDialog}
        manifest={addEntityManifest}
        entityService={entityService}
        adminOrgService={adminOrgService}
        onFinishAddEntity={handleAddEntity}
        onFinishCreateExclusiveEntity={handleCreateExclusiveEntity}
        viewMode={"Default"}
      />
      {entity && (
        <AddEntityToManifestDialog
          entity={entity}
          open={openAddToManifestDialog}
          onClose={handleCloseDialog}
          onFinish={handleAddEntityToManifest}
          manifestService={manifestService}
          adminOrgService={adminOrgService}
          viewMode="Default"
          manifestOrg="TORO"
        />
      )}
      <ManifestWizard
        open={openManifestWizard}
        manifestSize={navigatorInput?.length || 0}
        initialManifestName={generateUniqueNameFromNavigator("new_manifest")}
        onClose={handleCloseManifestWizard}
        onCreateManifestViaWizard={handleCreateManifestViaWizard}
        joyrideRunning={runJoyride}
        joyrideIndex={joyrideIndex}
        indexTrigger={joyrideIndexTrigger}
        joyrideTrigger={createManifestTourTrigger}
        endTour={handleEndTour}
      />
      <EntityWizard
        open={openEntityWizard}
        onClose={handleCloseEntityWizard}
        onCreateEntityViaWizard={handleCreateEntityViaWizard}
        joyrideRunning={runJoyride}
        joyrideIndex={joyrideIndex}
        indexTrigger={joyrideIndexTrigger}
        joyrideTrigger={createEntityTourTrigger}
        endTour={handleEndTour}
        viewMode={"Default"}
      />
      <ExportDatabaseStructureWizard
        open={openExportDatabaseStructureWizard}
        onClose={handleCloseExportDatabaseStructureWizard}
        onExportManifest={handleExportManifest}
      />
      <ExportCdmSchemaWizard
        open={openExportCdmSchemaWizard}
        onClose={handleCloseExportCdmSchemaWizard}
        onExportManifest={handleExportManifest}
      />
      <ExportDataModelWizard
        open={openExportDataModelWizard}
        onClose={() => setOpenExportDataModelWizard(false)}
        onExportManifest={handleExportManifest}
      />
      <ExportPackageWizard
        open={openExportPackageWizard}
        onClose={handleCloseExportPackageWizard}
      />
      <ImportWizard
        open={openImportWizard}
        onClose={handleCloseImportWizard}
        importEntity={handleImportEntity}
        importManifest={handleImportManifest}
        skipPage={importEntitySkipPage}
        defaultManifest={importEntityDefaultManifest}
      />
      <GuidedTours
        open={openGuidedTours}
        onClose={handleCloseGuidedTours}
        startTour={handleStartGuidedTours}
      />
      <div className={classes.root}>
        <CssBaseline />

        <MainToolbar
          manifestSize={navigatorInput?.length || 0}
          openManifestWizard={handleOpenManifestWizard}
          openEntityWizard={handleOpenEntityWizard}
          openExportDatabaseStructure={handleOpenExportDatabaseStructureWizard}
          openExportCdmSchema={handleOpenExportCdmSchemaWizard}
          openExportDataModel={() => setOpenExportDataModelWizard(true)}
          openExportPackage={handleOpenExportPackageWizard}
          openImportWizard={handleOpenImportWizard}
          onStartOnBoarding={startOnBoarding}
          onGuidedTours={handleOpenGuidedTours}
          onDrawerToggle={handleDrawerToggle}
        />

        <div
          id="negroni-sidebar"
          style={{
            marginTop: "49px",
            background: "#2B263A",
            width: "50px",
            height: "100vh",
            flexDirection: "column",
          }}
        >
          <IconButton
            id="negroni-toolbar-manifest-wizard"
            onClick={() => handleTabClick(NavigatorTab.MANIFESTS)}
            color="primary"
            title="Project Navigator"
          >
            <NegroniIcon
              iconClass={
                selectedNavigator === NavigatorTab.MANIFESTS
                  ? "assignment-icon active"
                  : "assignment-icon"
              }
              color="#DDF1F0"
              size="large"
            />
          </IconButton>

          <IconButton
            id="negroni-toolbar-manifest-wizard"
            onClick={() => handleTabClick(NavigatorTab.CDM)}
            color="primary"
            title="CDM Library"
          >
            <NegroniIcon
              iconClass={
                selectedNavigator === NavigatorTab.CDM
                  ? "description-icon active"
                  : "description-icon"
              }
              color="#DDF1F0"
              size="large"
            />
          </IconButton>

          <IconButton
            id="negroni-toolbar-manifest-wizard"
            onClick={() => handleTabClick(NavigatorTab.PACKAGES)}
            color="primary"
            title="Packages"
          >
            <NegroniIcon
              iconClass={
                selectedNavigator === NavigatorTab.PACKAGES
                  ? "card-travel-icon active"
                  : "card-travel-icon"
              }
              color="#DDF1F0"
              size="large"
            />
          </IconButton>
        </div>

        <SidebarContainer
          mobile={mobileOpen}
          onMobileClose={() => setMobileOpen(false)}
        >
          {(openPersistentDrawer) => (
            <Sidebar
              showSearch={
                Array.isArray(navigatorInput) && navigatorInput.length > 0
              }
            >
              {(search) => (
                <>
                  {Array.isArray(navigatorInput) &&
                  navigatorInput.length <= 0 ? (
                    <div
                      style={{
                        height: "80%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p>No manifest to display.</p>
                      <Button
                        color="secondary"
                        onClick={handleOpenManifestWizard}
                        size="small"
                        variant="contained"
                        disableElevation
                      >
                        <NegroniIcon
                          iconClass="add-square-icon"
                          color="#fff"
                          extraClass="negroni-menu-icon"
                        />{" "}
                        New Manifest
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        overflow: "auto",
                        display:
                          openPersistentDrawer || !forceShowNavigator
                            ? "flex"
                            : "none",
                        height: "100%",
                        overflowX: "hidden",
                      }}
                    >
                      {selectedNavigator === NavigatorTab.MANIFESTS ||
                      selectedNavigator === NavigatorTab.CDM ? (
                        <Navigator
                          contextMenuProvider={
                            selectedNavigator === NavigatorTab.MANIFESTS
                              ? new CDMContextMenuProvider(menuActions)
                              : new CDMContextMenuProvider([addToManifestMenuAction])
                          }
                          defaultSelected={selection}
                          update={updateNavigator}
                          search={search}
                          input={
                            selectedNavigator === NavigatorTab.MANIFESTS
                              ? navigatorInput
                              : cdmNavigatorInput
                          }
                          contentProvider={navigatorContentProvider}
                          onSelect={(node: any) => {
                            if (
                              node.documentType === CDMDocumentType.ENTITY ||
                              node.documentType === CDMDocumentType.MANIFEST ||
                              node.documentType ===
                                CDMDocumentType.MANIFEST_WORKSPACE ||
                              node.documentType === CDMDocumentType.SUB_MANIFEST
                            )
                              setSelection(node || null);
                          }}
                          expanded={expanded}
                          setExpanded={setExpanded}
                          isLoading={refreshingManifestNavigator}
                          isLoadingText="Loading Navigator"
                        />
                      ) : (
                        <div style={{ width: "100%", padding: "0.5rem" }}>
                          {packages.length > 0 ? (
                            isLoading ? (
                              <LoadingProgress />
                            ) : (
                              <PackageCards
                                selection={query.get("new")}
                                defaultPackages={packages}
                                onPackageStatusChanged={
                                  handlePackageStatusChanged
                                }
                                loadPackages={loadPackages}
                                onPackageUpload={handleUploadPackage}
                                viewMode="Nav"
                              />
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                                padding: "10px",
                              }}
                            >
                              No packages to display
                            </div>
                          )}{" "}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </Sidebar>
          )}
        </SidebarContainer>
        {Array.isArray(navigatorInput) && navigatorInput.length > 0 ? (
          <ContentContainer
            propertiesView={<PropertiesContainer />}
            expandContent={expandContent}
            expandProperties={expandProperties}
            setExpandContent={setExpandContent}
            setExpandProperties={setExpandProperties}
            currentTab={currentTab}
            tabs={documentTabs}
            handleTabChange={handleTabChange}
            handleTabClose={handleTabClose}
            handleTablCloseAll={handleTabCloseAll}
            handleTabCloseOthers={handleTabCloseOthers}
            handleTabCloseRight={handleTabCloseTabsRight}
            handleTabCloseLeft={handleTablCloseTabsLeft}
          >
            {content}
          </ContentContainer>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export const AppLogo = () => {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <img
        id="negroni-logo"
        src="/images/negroni-logo.png"
        height={20}
        alt="Negroni"
      />
    </div>
  );
};

export default observer(App);
