import React, {ReactNode} from "react";
import {action, observable} from "mobx";
import Entity from "./Entity";
import Manifest from "./Manifest";
import CDMDocument, {CDMDocumentType} from "./CDMDocument";
import {MockDataItem, PaginatedMockItem} from "../../component/navigator/Navigator";
import DocumentItem from "./DocumentItem";
import {CDMManager} from "../CDMManager";
import {NavigatorContentProvider, PaginatedChildren} from "../../component/navigator/NavigatorContentProvider";
import {ManifestService} from "../services/ManifestService";
import {EntityService} from "../services/EntityService";
import {Filter} from "../Filter";
import NavigatorContextMenuProvider from "../../component/navigator/NavigatorContextMenuProvider";
import {MenuItem} from "@material-ui/core";
import MenuAction from "../../component/navigator/MenuAction";
import {CancelTokenSource} from "axios";
import { AdminOrganisationService } from "../services/admin/AdminOrganisationService";
import { NavigatorService } from "../services/NavigatorService";
import NestedMenu from "../../component/navigator/NestedMenu";
import { NegroniIcon } from "../../component/icon/NegronIIcon";

export default class Workspace implements CDMDocument {
    @observable name: string;
    @observable entityPaths: DocumentItem[] = [];
    @observable manifestPaths: DocumentItem[] = [];
    @observable entities: Entity[] = [];
    @observable manifests: Manifest[] = [];
    isDefault: boolean;
    private _parent: any;
    readonly documentType = CDMDocumentType.WORKSPACE;

    constructor(name: string, isDefault: boolean) {
        this.name = name;
        this.isDefault = isDefault;
        this._parent = null;
    }

    @action
    addEntityPath(path: DocumentItem) {
        this.entityPaths.push(path);
    }

    @action
    addManifestPath(path: DocumentItem) {
        this.manifestPaths.push(path);
    }

    @action
    deleteEntityPath(path: DocumentItem) {
        const removeIndex = this.entityPaths.indexOf(path);
        this.entityPaths.splice(removeIndex, 1);
    }

    @action
    deleteManifestPath(path: DocumentItem) {
        const removeIndex = this.manifestPaths.indexOf(path);
        this.manifestPaths.splice(removeIndex, 1);
    }

    /**
     * Adds an entity, it overrides the old entity.
     * @param entity    The entity to add.
     */
    @action
    addEntity(entity: Entity) {
        const oldEntityIndex = this.entities.findIndex(e => e.path === entity.path);
        if (oldEntityIndex > 0)
            this.entities.slice(oldEntityIndex, 1);
        this.entities.push(entity);
    }

    @action
    addManifest(manifest: Manifest) {
        this.manifests.push(manifest);
    }

    set parent(parent: any) {
        this._parent = parent;
    }

    get parent() {
        return this._parent;
    }

    get label() {
        return this.name;
    }
}

export class CDMContentProvider implements NavigatorContentProvider {

    readonly ENTITIES_SUFFIX = '-entityPaths';
    readonly MANIFESTS_SUFFIX = '-manifestPaths';
    readonly SUB_MANIFESTS_SUFFIX = '-subManifestPaths';
    readonly FOLDER_SUFFIX = '-folderPaths'

    readonly manifestService: ManifestService;
    readonly entityService: EntityService;
    readonly adminOrgService: AdminOrganisationService;
    readonly navigatorService: NavigatorService

    constructor(manifestService: ManifestService, entityService: EntityService, adminOrgService: AdminOrganisationService, navigatorService: NavigatorService) {
        this.manifestService = manifestService;
        this.entityService = entityService;
        this.adminOrgService = adminOrgService;
        this.navigatorService = navigatorService;
    }

    async children(node: any, page: number, limit: number, search: string, sourceCancelToken: CancelTokenSource): Promise<any[]> {
        if (Array.isArray(node)) {
            return node;
        }
        if (node.documentType === CDMDocumentType.WORKSPACE) {
            const workspace = node as Workspace;
            let filter = Filter.ALL;
            switch (workspace.name) {
                case CDMManager.PRE_DEFINED:
                    filter = Filter.DEFAULT;
                    break;
                case CDMManager.USER_DEFINED:
                    filter = Filter.PRIVATE;
                    break;
            }
            if (workspace.isDefault) {
                let coreChild = [] as any;
                if(localStorage.getItem(CDMManager.NEGRONI_CORE_SCHEMA_KEY) === null) {
                    await this.navigatorService.getNavigator(sourceCancelToken.token).then(response => {
                        let coreNavigator = this.navigatorService.constructNavigator(response.coreSchema?.nodes, search);
                        localStorage.setItem(CDMManager.NEGRONI_CORE_SCHEMA_KEY, JSON.stringify(response?.coreSchema?.nodes));
                        localStorage.setItem(CDMManager.NEGRONI_CORE_SCHEMA_VERSION_KEY, response?.coreSchema?.buildVersion);
                        coreChild = coreNavigator.children;
                    });
                } else {
                    const storedNodes = JSON.parse(localStorage.getItem(CDMManager.NEGRONI_CORE_SCHEMA_KEY) || '{}');
                    const version = localStorage.getItem(CDMManager.NEGRONI_CORE_SCHEMA_VERSION_KEY) || '';
                    await this.navigatorService.getVersion(sourceCancelToken.token).then(async response => {
                        if(response?.version !== version) {
                            await this.navigatorService.getNavigator(sourceCancelToken.token).then(response => {
                                let coreNavigator = this.navigatorService.constructNavigator(response.coreSchema?.nodes, search);
                                localStorage.setItem(CDMManager.NEGRONI_CORE_SCHEMA_KEY, JSON.stringify(response?.coreSchema?.nodes));
                                localStorage.setItem(CDMManager.NEGRONI_CORE_SCHEMA_VERSION_KEY, response?.coreSchema?.buildVersion);
                                coreChild = coreNavigator.children;
                            });
                        } else {
                            let coreNavigator = this.navigatorService.constructNavigator(storedNodes, search);
                            coreChild = coreNavigator.children;
                        }
                    });
                }
                return coreChild;
            } else {
                return [
                    new PaginatedMockItem(workspace.name + this.ENTITIES_SUFFIX,
                        this._getNameByType(CDMDocumentType.ENTITY),
                        '',
                        (page, limit) => {
                            return this.entityService.getEntities(filter, limit, page, search, -1, undefined, undefined, sourceCancelToken.token)
                                .then(result => ({
                                    children: result.result,
                                    totalElements: result.totalElements,
                                } as PaginatedChildren))
                        }, workspace),
                    new PaginatedMockItem(workspace.name + this.MANIFESTS_SUFFIX,
                        this._getNameByType(CDMDocumentType.MANIFEST),
                        '',
                        (page, limit) => {
                            return this.manifestService.getManifests(filter, undefined, limit, page, search, sourceCancelToken.token)
                                .then(result => ({
                                    children: result.result,
                                    totalElements: result.totalElements,
                                } as PaginatedChildren))
                        }, workspace),
                ];
            }
        } else if (node.documentType === CDMDocumentType.MANIFEST ||
            node.documentType === CDMDocumentType.MANIFEST_WORKSPACE ||
            node.documentType === CDMDocumentType.SUB_MANIFEST) {
            const manifest = await this.manifestService.getManifestById(node.id, sourceCancelToken.token);
            const manifestChild = [];

            if (manifest?.entityPaths.length > 0)
                manifestChild.push(new PaginatedMockItem(node.id + this.ENTITIES_SUFFIX,
                    this._getNameByType(CDMDocumentType.ENTITY),
                    '',
                    (page, limit) => {
                        return this.manifestService.getEntitiesByManifest(node.id, -1, limit, page, search, sourceCancelToken.token)
                            .then(result => ({
                                children: result.result,
                                totalElements: result.totalElements,
                            } as PaginatedChildren))
                    }, node
                ))     
            if (manifest?.subManifestPaths.length > 0)
                manifestChild.push(new MockDataItem(node.id + this.SUB_MANIFESTS_SUFFIX,
                    this._getNameByType(CDMDocumentType.SUB_MANIFEST),
                    '',
                    manifest?.subManifestPaths,
                    node))
            return manifestChild;
        } else if (node.documentType === CDMDocumentType.MANIFEST_FOLDER) {
            const folderManifests = await this.manifestService.getManifestsByFolder(node.name, limit, page, search, sourceCancelToken.token);
            return folderManifests.result;
        } else if (node instanceof MockDataItem) {
            return node.children;
        }
        return [];
    }

    getIcon(node: any): string {
        if (node.documentType === CDMDocumentType.WORKSPACE)
            return 'work-icon';
        if (node.documentType === CDMDocumentType.MANIFEST_WORKSPACE)
            return 'assignment-icon'
        if (node.documentType === CDMDocumentType.MANIFEST || node.documentType === CDMDocumentType.SUB_MANIFEST)
            return 'assignment-icon';
        if (node.documentType === CDMDocumentType.ENTITY)
            return 'description-icon';
        if (node.type === 'paginatedMockItem' ||
            node.type === 'mockDataItem' ||
            node.documentType === CDMDocumentType.ENTITY_FOLDER ||
            node.documentType === CDMDocumentType.MANIFEST_FOLDER)
            return 'folder-icon';
        return '';
    }

    getLabel(node: any): string {
        if (node.documentType === CDMDocumentType.WORKSPACE)
            return node?.name === CDMManager.PRE_DEFINED ? 'Standard' : 'Custom';
        if (node.documentType === CDMDocumentType.ENTITY ||
            node.documentType === CDMDocumentType.MANIFEST ||
            node.documentType === CDMDocumentType.MANIFEST_WORKSPACE ||
            node.documentType === CDMDocumentType.SUB_MANIFEST ||
            node.documentType === CDMDocumentType.ENTITY_FOLDER ||
            node.documentType === CDMDocumentType.MANIFEST_FOLDER)
            return node.name === "core" ? "core (Lonti)" : node.name;
        if (node.label)
            return node.label;
        return '';
    }

    getPath(node: any): string | undefined {
        return node?.path;
    }

    getId(node: any): string {
        if (node.parent) return this.getId(node.parent) ? (this.getId(node.parent) + '~' + this._getId(node)) :
            this._getId(node);
        return this._getId(node);
    }

    getNodeIds(node: any, current: string[]): string[] {
        if (!this.getId(node)) return current;
        const nodeIds = [...current, this.getId(node)];
        if (node?.parent) return this.getNodeIds(node.parent, nodeIds)
        return nodeIds;
    }

    _getId(node: any): string {
        if (node.documentType === CDMDocumentType.ENTITY ||
            node.documentType === CDMDocumentType.MANIFEST ||
            node.documentType === CDMDocumentType.SUB_MANIFEST)
            return node?.id;
        return this.getLabel(node);
    }

    _getSuffixByType(type: CDMDocumentType) {
        switch (type) {
            case CDMDocumentType.MANIFEST:
                return this.MANIFESTS_SUFFIX;
            case CDMDocumentType.ENTITY:
                return this.ENTITIES_SUFFIX;
            case CDMDocumentType.SUB_MANIFEST:
                return this.SUB_MANIFESTS_SUFFIX;
        }
    }

    _getNameByType(type: CDMDocumentType) {
        switch (type) {
            case CDMDocumentType.MANIFEST:
                return 'Manifests';
            case CDMDocumentType.ENTITY:
                return 'Entities';
            case CDMDocumentType.SUB_MANIFEST:
                return 'Sub-Manifests';
            case CDMDocumentType.ENTITY_FOLDER:
                return 'Entities';
            case CDMDocumentType.MANIFEST_FOLDER:
                return 'Manifests';
        }
        return '';
    }

    getParent(node: any): any {
        return node?.parent;
    }

    hasChildren(node: any): boolean {
        if (Array.isArray(node))
            return node.length > 0;
        if (node instanceof MockDataItem)
            return (node as MockDataItem).children.length > 0;
        if (node instanceof PaginatedMockItem)
            return true;
        if (node.documentType === CDMDocumentType.WORKSPACE ||
            node.documentType === CDMDocumentType.MANIFEST_WORKSPACE ||
            node.documentType === CDMDocumentType.MANIFEST ||
            node.documentType === CDMDocumentType.SUB_MANIFEST ||
            node.documentType === CDMDocumentType.ENTITY_FOLDER ||
            node.documentType === CDMDocumentType.MANIFEST_FOLDER)
            return true;
        return false;
    }

    supported(node: any): boolean {
        return Array.isArray(node) ||
            (node?.documentType &&
                (node.documentType === CDMDocumentType.MANIFEST ||
                    node.documentType === CDMDocumentType.SUB_MANIFEST ||
                    node.documentType === CDMDocumentType.WORKSPACE ||
                    node.documentType === CDMDocumentType.MANIFEST_WORKSPACE ||
                    node.documentType === CDMDocumentType.ENTITY_FOLDER ||
                    node.documentType === CDMDocumentType.MANIFEST_FOLDER)) ||
            node instanceof MockDataItem ||
            node instanceof PaginatedMockItem;
    }

    paginatedChildren(node: any, page: number, limit: number, search: string, cancelTokenSource: CancelTokenSource): Promise<PaginatedChildren> | undefined {
        if (node.type === 'paginatedMockItem')
            return node.paginatedChildren(page, limit, search);
    }

    isPaginated(node: any): boolean {
        return node?.type === 'paginatedMockItem';
    }

}

export class CDMContextMenuProvider implements NavigatorContextMenuProvider {

    readonly menuActions: MenuAction[];

    constructor(menuActions: MenuAction[]) {
        this.menuActions = menuActions;
    }

    getContextMenu(node: any, onClose: () => void): React.ReactNode | undefined {
        const menus: ReactNode[] = [];
        this.menuActions.forEach((menuAction, index) => {
            if (menuAction.supported(node)) {
                if(menuAction.children.length > 0) {
                    menus.push(<NestedMenu
                        label={menuAction.title}
                        styleType='Context'
                        leftIcon={<NegroniIcon iconClass={menuAction.icon!} extraClass="negroni-menu-icon" />}
                    >
                        {menuAction.children.map((subMenu, index) => (
                            <MenuItem
                                dense
                                key={index}
                                onClick={_ => {
                                    onClose();
                                    subMenu.action(node);
                                }}
                                title={subMenu.description}
                                disabled={subMenu.disabled}
                            >
                                {subMenu.icon && <NegroniIcon iconClass={subMenu.icon} extraClass="negroni-menu-icon" />}
                                {subMenu.title}
                            </MenuItem>
                        ))}
                    </NestedMenu>)
                } else {
                    menus.push(<MenuItem
                        dense
                        key={index}
                        onClick={_ => {
                            onClose();
                            menuAction.action(node);
                        }}
                        title={menuAction.description}
                        className={menuAction.className}
                        disabled={menuAction.disabled}
                    >
                        {menuAction.icon && <NegroniIcon iconClass={menuAction.icon} extraClass="negroni-menu-icon" />}
                        {menuAction.title}
                    </MenuItem>)
                }
            }
        });
        return menus;
    }

    supported(node: any): boolean {
        for (const menuAction of this.menuActions) {
            if (menuAction.supported(node))
                return true;
        }
        return false;
    }

}