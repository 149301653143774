import React, {useEffect, useRef} from "react";
import {Dialog, DialogContent, DialogTitle,} from "@material-ui/core";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import OrganisationList from "./OrganisationList";
import useContent from "../content/useContent";
import {Org, OrgPermissions} from "../auth/User";
import {_noop} from "../../util";
import useSession from "../session/useSession";
import { CDMManager } from "../../core/CDMManager";

interface SelectOrganisationDialogProp {
    open: boolean;
    onClose: () => void;
}


const SelectOrganisationDialog: React.FC<SelectOrganisationDialogProp> = ({open, onClose}) => {
    const history = useHistory();
    const {setOnCancelWindow} = useContent();
    const {setOrg, user} = useSession();
    const currentOrg = useRef<Org | undefined>(undefined);

    useEffect(() => {
        setOnCancelWindow(() => {
            if (currentOrg.current) setOrg(currentOrg.current.code, currentOrg.current.name);
            onClose();
        });
        return () => {
            setOnCancelWindow(_noop);
            currentOrg.current = undefined;
        }
    }, []);

    const handleSwitchOrg = (orgName: string, orgCode: string) => {
        const _user = user();
        if (_user) currentOrg.current = {code: _user.organisationCode, name: _user.organisationName};
        setOrg(orgCode, orgName);
        if(localStorage.getItem(CDMManager.NEGRONI_LAST_VISIT) === 'Default') {
            const currentSelected = JSON.parse(localStorage.getItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE) || '{}');
            if(!currentSelected?.isDefault) {
                localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
            }
        }
        history.go(0);
    };

    const orgs:OrgPermissions[] = JSON.parse(user()!.negroniPermission).map((per: any) => ({ name: per.on, code: per.oc, ng: !!parseInt(per.ng) })) || []

    return <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle>Select Organisation</DialogTitle>
        <DialogContent dividers>
            <OrganisationList
                orgs={orgs?.filter(org => org.code !== user()?.organisationCode).sort((a, b) => (b.ng ? 1 : 0) - (a.ng ? 1 : 0)) || []}
                handleSwitchOrg={handleSwitchOrg}
                currentOrg={{
                    name: user()?.organisationName,
                    code: user()?.organisationCode,
                }}
            />
        </DialogContent>
    </Dialog>
}

export default observer(SelectOrganisationDialog)