import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    CircularProgress,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Tooltip,
    Toolbar,
    IconButton,
} from "@material-ui/core";
import Manifest from "../../core/observables/Manifest";
import DocumentItem from "../../core/observables/DocumentItem";
import {Skeleton} from "@material-ui/lab";
import Entity from "../../core/observables/Entity";
import AddSubManifestDialog from "./AddSubManifestDialog";
import ExportManifestDialog, { ExportTypes } from "./ExportManifestDialog";
import ManageEntityDialog from "../entity/ManageEntityDialog";
import useConfirm from "../confirm/useConfirm";
import * as yup from "yup";
import {NAME_PATTERN} from "../../core/constants";
import useService from "../service/useService";
import {makeStyles} from "@material-ui/core/styles";
import useSession from "../session/useSession";
import NestedMenu from "../navigator/NestedMenu";
import { ImportExport } from "@material-ui/icons";
import { NegroniIcon } from "../icon/NegronIIcon";
import ManageManifestVersionDialog from "./ManageManifestVersionDialog";
import { useSnackbar } from "notistack";
import { observer } from "mobx-react";

const ManifestViewMenuStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignContent: 'end',
            marginTop: '5px',
            '& > *': {
                margin: theme.spacing(1),
            }
        },
        toolBar: {
            minHeight: 36,
            paddingLeft: 0,
        },
    }),
);

interface ManifestViewProps {
    manifest: Manifest;
    setSelection: (property: any) => void;
    onAddEntity: (entity: DocumentItem, manifest: Manifest) => Promise<string | undefined>;
    onCreateExclusiveEntity: (entity: Entity, manifest: Manifest) => Promise<string | undefined>;
    onAddSubManifest: (subManifest: DocumentItem, manifest: Manifest) => Promise<string | undefined>;
    onDeleteManifest: (manifest: Manifest) => void;
    onBuildManifestClicked: (manifest: Manifest, hideConfiguration: boolean, customBuild: boolean) => void;
    onReload: (manifest: Manifest) => Promise<void>;
    onExportManifest: (manifest: Manifest, exportType: ExportTypes, databaseType: string, applyAutoIncrement: boolean) => Promise<string | undefined>;
    viewMode: string;
    manifestOrg?: string;
    orgLabel?: string;
}

const ManifestView: React.FC<ManifestViewProps> = ({
    manifest,
    setSelection,
    onAddEntity,
    onCreateExclusiveEntity,
    onAddSubManifest,
    onDeleteManifest,
    onBuildManifestClicked,
    onReload,
    onExportManifest,
    viewMode,
    manifestOrg,
    orgLabel,
}) => {
    const classes = ManifestViewMenuStyles();
    const {entityService, manifestService, adminOrgService} = useService();
    const confirm = useConfirm();
    const [openAddSubManifestDialog, setOpenAddSubManifestDialog] = React.useState(false);
    const [openManageManifestDialog, setOpenManageManifestDialog] = React.useState(false);
    const [openAddManifestVersion, setOpenAddManifestVersion] = useState(false);
    const [versions, setVersions] = useState<{ version: string, entities: number, subManifests: number }[]>([]);
    const [isReadonly, setIsReadonly]  = useState(false);
    const [entityPaths, setEntityPaths] = useState<DocumentItem[]>(manifest.entityPaths);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setSelection(manifest);
        setEntityPaths(manifest.entityPaths);
        handleVersions()
    }, [manifest]);

    useEffect(() => {
        setEntityPaths(manifest.entityPaths);
    }, [manifest.entityPaths]);

    useEffect(() => {
        setIsReadonly(manifest.currentVersion !== manifest.latestVersion || manifest.isDefault)
    }, [manifest.currentVersion, manifest.latestVersion]);

    function handleManageEntityDialog() {
        setOpenManageManifestDialog(true);
    }

    function handleAddSubManifest() {
        setOpenAddSubManifestDialog(true);
    }

    function handleCloseManageEntityDialog() {
        setOpenManageManifestDialog(false);
    }

    function handleCloseAddSubManifestDialog() {
        setOpenAddSubManifestDialog(false);
    }

    const handleDeleteManifest = () => {
        manifestService.getParentManifests(manifest.id)
            .then(parentManifests => {
                let warningMessage = 'Do you really want to delete the manifest?';

                if (manifest.parentManifestId === undefined && parentManifests.length > 0) {
                    let names = parentManifests.slice(0, 5).map(p => `<li><strong>${p.name}</strong></li>`).join('');
                    if (parentManifests.length > 5)
                        names = names + '<li><i>and more...</i></li>';
                    warningMessage = `Manifest <strong>${manifest.name}</strong> is being referenced as sub-manifest in:
                                        <ul>${names}</ul>
                                        Do you want to proceed deleting the manifest?`;
                }

                confirm({message: warningMessage, title: 'Delete Manifest'})
                    .then(_ => {
                        onDeleteManifest(manifest);
                    });
            });
    };

    const handleAddManifestVersion = (version: string) : Promise<string | undefined> => {
        return manifestService.createManifestVersion(manifest.id, version)
            .then(() => {
                enqueueSnackbar('Successfully created manifest version.', {variant: 'success'});
            })
            .catch(error => error || 'Failed to create manifest version');
    }

    const handleOpenCreateManifestVersion = async () => {
        await handleVersions();
        setOpenAddManifestVersion(true);
    }

    const handleCloseCreateEntityVersion = () => {
        setOpenAddManifestVersion(false);
    }

    async function handleVersions() {
        let versions: { label: string, value: string }[] = []
        try {
            await manifestService.getManifestVersionsById(manifest.id)
                .then(manifest => {
                    setVersions(manifest.manifestVersions.map((v:any) => {
                        const content = JSON.parse(v.CONTENT)
                        return {
                            version: v.VERSION,
                            entities: content.entities.length,
                            subManifests: content.subManifests.length
                        }
                    })
                    );
                    manifest.manifestVersions.forEach((version: any) => {
                        versions.push({
                            label: version.VERSION,
                            value: version.VERSION,
                        })
                    })
                    return versions;
                });
            return versions
        } catch (_) {
            return [];
        }
    }

    return <div>
        <Grid container>
            <Grid item xs={12}>
                <Toolbar className={classes.toolBar}>
                    <Tooltip title='Manage Entity'>
                        <IconButton
                            color='primary'
                            onClick={handleManageEntityDialog}
                            disabled={isReadonly}
                        >
                             <NegroniIcon iconClass="account-tree-icon" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Add Sub-Manifest'>
                        <IconButton
                            color='primary'
                            onClick={handleAddSubManifest}
                            disabled={isReadonly}
                        >
                            <NegroniIcon iconClass="folder-copy-icon" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Manage Versions'>
                    <IconButton
                            color='primary'
                            onClick={handleOpenCreateManifestVersion}
                            disabled={manifest.isDefault}
                        >
                             <NegroniIcon iconClass="filter-9-plus-icon" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete Manifest'>
                        <IconButton
                            style={{color: 'var(--light-error-color)'}}
                            onClick={handleDeleteManifest}
                            disabled={manifest.isDefault}
                        >
                             <NegroniIcon iconClass="delete-icon" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                <h3 style={{margin: '5px', cursor: 'pointer', flexGrow: 1}}
                    onClick={_ => setSelection(manifest)}>{manifest.name}</h3>
            </Grid>
        </Grid>
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={4}>Information</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow hover>
                    <TableCell>Entities</TableCell>
                    <TableCell>{entityPaths.length}</TableCell>
                    <TableCell>Sub-Manifests</TableCell>
                    <TableCell>{manifest.subManifestPaths.length}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <AddSubManifestDialog
            open={openAddSubManifestDialog}
            onClose={handleCloseAddSubManifestDialog}
            manifest={manifest}
            manifestService={manifestService}
            adminOrgService={adminOrgService}
            onFinish={subManifest => onAddSubManifest(subManifest, manifest)}
            viewMode={viewMode}
            manifestOrg={manifestOrg}
            orgLabel={orgLabel}
        />
        <ManageEntityDialog
            open={openManageManifestDialog}
            onClose={handleCloseManageEntityDialog}
            manifest={manifest}
            entityService={entityService}
            adminOrgService={adminOrgService}
            onFinishAddEntity={onAddEntity}
            onFinishCreateExclusiveEntity={onCreateExclusiveEntity}
            viewMode={viewMode}
            manifestOrg={manifestOrg}
            orgLabel={orgLabel}
        />
        <ManageManifestVersionDialog
            manifest={manifest}
            open={openAddManifestVersion}
            onClose={handleCloseCreateEntityVersion}
            onFinish={handleAddManifestVersion}
            versions={versions}
            manifestService={manifestService}
        />
    </div>
}

export const ManifestViewSkeleton = () => {
    return <div style={{padding: '10px'}}>
        <Skeleton animation='pulse' height={30} width={200}/>
        <Skeleton animation='pulse' height={60}/>
        <Skeleton animation='pulse' height={40} width={220}/>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 2, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 1, paddingRight: '10px'}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
            <div style={{flexGrow: 2}}>
                <Skeleton animation='pulse' height={20}/>
            </div>
        </div>
    </div>;
}

interface NewManifestProps {
    manifestName: string;
    setManifestName: (manifestName: string) => void;
    error: string | null;
    onSubmit?: () => void;
    autoFocus?: boolean;
}

export const NewManifest: React.FC<NewManifestProps> = ({
    manifestName,
    setManifestName,
    error,
    onSubmit,
    autoFocus
}) => {
    const nameInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        nameInput.current?.select();
    }, []);

    return <div>
        <form autoComplete='off' onSubmit={e => {
            e.preventDefault();
            if (onSubmit)
                onSubmit();
        }}>
            <TextField
                inputRef={nameInput}
                autoFocus={autoFocus}
                margin="dense"
                id="entityName"
                label="Manifest Name"
                fullWidth
                color='primary'
                variant='filled'
                value={manifestName}
                onChange={e => setManifestName(e.target.value)}
                error={!!error}
                helperText={error}
            />
        </form>
    </div>
};

interface CreateManifestDialogProps {
    open: boolean;
    onClose: () => void;
    onFinish: (manifestName: string) => Promise<string | undefined>;
    onCancel: () => void;
}

export const CreateManifestDialog: React.FC<CreateManifestDialogProps> = ({open, onClose, onFinish, onCancel}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [manifestName, setManifestName] = useState('new_manifest');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (open) {
            setManifestName('new_manifest');
            validateName();
        }
    }, [open]);

    useEffect(() => validateName(), [manifestName]);

    const validateName = () => {
        try {
            yup.string()
                .required()
                .min(Manifest.MIN_NAME_LENGTH)
                .max(Manifest.MAX_NAME_LENGTH)
                .matches(NAME_PATTERN, 'this must only contain alphanumeric and no underscore consecutively')
                .validateSync(manifestName);
            setErrorMessage('');
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    const handleCreateManifest = () => {
        setIsLoading(true);
        onFinish(manifestName)
            .then(error => {
                setIsLoading(false);
                setErrorMessage(null);
                if (error && error.length > 0) {
                    setErrorMessage(error);
                } else {
                    onClose();
                    setManifestName('new_manifest');
                }
            });
    };

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle id="form-dialog-title">Create Manifest</DialogTitle>
        <DialogContent dividers style={{minHeight: '200px', minWidth: '450px'}}>
            <div>Create a custom manifest</div>
            <NewManifest
                manifestName={manifestName}
                setManifestName={setManifestName}
                error={errorMessage}
                onSubmit={() => {
                    if (!isLoading)
                        handleCreateManifest();
                }}
                autoFocus={true}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={_ => onCancel()}>
                Cancel
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={isLoading || (!!errorMessage && errorMessage.length > 0)}
                disableElevation
                onClick={handleCreateManifest}
                startIcon={isLoading && <CircularProgress size={18}/>}
            >
                Finish
            </Button>
        </DialogActions>
    </Dialog>
}

interface ManifestViewMenuProps {
    currentManifest: Manifest;
    onAddEntity: (entity: DocumentItem, manifest: Manifest) => Promise<string | undefined>;
    onCreateExclusiveEntity: (entity: Entity, manifest: Manifest) => Promise<string | undefined>;
    onSubManifestAdd: (subManifest: DocumentItem) => Promise<string | undefined>;
    onDeleteManifest: (manifest: Manifest) => void;
    onBuildManifestClicked: (manifest: Manifest) => void;
    onReload: (manifest: Manifest) => Promise<void>;
    onExportManifest: (manifest: Manifest, exportType: ExportTypes, databaseType: string, allowAutoIncrement: boolean) => Promise<string | undefined>;
    viewMode: string;
    manifestOrg?: string;
    orgLabel?: string;
}

export const ManifestViewMenu: React.FC<ManifestViewMenuProps> = ({
    currentManifest,
    onCreateExclusiveEntity,
    onAddEntity,
    onSubManifestAdd,
    onDeleteManifest,
    onBuildManifestClicked,
    onReload,
    onExportManifest,
    viewMode,
    manifestOrg,
    orgLabel,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [openAddSubManifestDialog, setOpenAddSubManifestDialog] = React.useState(false);
    const [openManageManifestDialog, setOpenManageManifestDialog] = React.useState(false);
    const [openExportManifestDialog, setOpenExportManifestDialog] = React.useState(false);
    const {entityService, manifestService, adminOrgService} = useService();
    const confirm = useConfirm();
    const classes = ManifestViewMenuStyles();
    const [isReloading, setIsReloading] = useState<boolean>(false);
    const {user} = useSession();
    const [exportMode, setExportMode] = useState<ExportTypes>(ExportTypes.CDM_SCHEMA);

    function handleOpen() {
        setOpen((prevOpen) => !prevOpen);
    }

    function handleNavigatorMenuClose(event: React.MouseEvent<EventTarget>) {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current && !open)
            anchorRef.current!.focus();

        prevOpen.current = open;
    }, [open]);

    function handleManageEntityDialog() {
        setOpenManageManifestDialog(true);
        setOpen(false);
    }

    function handleAddSubManifest() {
        setOpenAddSubManifestDialog(true);
        setOpen(false);
    }

    function handleExportDatabaseDialog() {
        setExportMode(ExportTypes.DATABASE_SCHEMA);
        setOpenExportManifestDialog(true);
        setOpen(false);
    }

    function handleExportCdmDialog() {
        setExportMode(ExportTypes.CDM_SCHEMA);
        setOpenExportManifestDialog(true);
        setOpen(false);
    }

    function handleExportModelDialog() {
        setExportMode(ExportTypes.DATA_MODEL);
        setOpenExportManifestDialog(true);
        setOpen(false);
    }

    function handleCloseManageEntityDialog() {
        setOpenManageManifestDialog(false);
    }

    function handleCloseAddSubManifestDialog() {
        setOpenAddSubManifestDialog(false);
    }

    function handleCloseExportEntityDialog() {
        setOpenExportManifestDialog(false);
    }

    function handleBuildManifest() {
        onBuildManifestClicked(currentManifest);
        setOpen(false);
    }

    const handleDeleteManifest = () => {
        setOpen(false);
        manifestService.getParentManifests(currentManifest.id)
            .then(parentManifests => {
                let warningMessage = 'Do you really want to delete the manifest?';

                if (currentManifest.parentManifestId === undefined && parentManifests.length > 0) {
                    let names = parentManifests.slice(0, 5).map(p => `<li><strong>${p.name}</strong></li>`).join('');
                    if (parentManifests.length > 5)
                        names = names + '<li><i>and more...</i></li>';
                    warningMessage = `Manifest <strong>${currentManifest.name}</strong> is being referenced as sub-manifest in:
                                        <ul>${names}</ul>
                                        Do you want to proceed deleting the manifest?`;
                }

                confirm({message: warningMessage, title: 'Delete Manifest'})
                    .then(_ => {
                        onDeleteManifest(currentManifest);
                    });
            });

    };

    return (
        <>
            <div className={classes.root}>
                {currentManifest.isDefault && user()?.isAdmin ? <>
                    <Tooltip title='Reload Manifest'>
                        <Button
                            variant='contained'
                            size='small'
                            color='secondary'
                            disableElevation
                            onClick={_ => {
                                setIsReloading(true);
                                onReload(currentManifest)
                                    .finally(() => setIsReloading(false));
                            }}
                            disabled={isReloading}
                        >
                            {isReloading ? <CircularProgress size={23}/> : <NegroniIcon iconClass="refresh-icon" />}
                        </Button>
                    </Tooltip>
                </> : <>
                    <Button
                        ref={anchorRef}
                        onClick={handleOpen}
                        variant='contained'
                        size='small'
                        disableElevation
                    >
                        <NegroniIcon iconClass="settings-icon" />
                    </Button>
                    <Button
                        onClick={_ => onBuildManifestClicked(currentManifest)}
                        title='Build Manifest'
                        variant='contained'
                        size='small'
                        disableElevation
                        color='secondary'
                        startIcon={ <NegroniIcon iconClass="wrench-icon" />}
                    >
                        Build Manifest
                    </Button>
                </>}
            </div>
            <Menu
                anchorEl={anchorRef.current}
                open={open}
                onClose={handleNavigatorMenuClose}
            >
                <MenuItem onClick={handleManageEntityDialog}><NegroniIcon iconClass="description-icon" /> Add Entity</MenuItem>
                <MenuItem onClick={handleAddSubManifest}><NegroniIcon iconClass="assignment-icon" /> Add Sub-Manifest</MenuItem>
                <NestedMenu
                    label='Export'
                    leftIcon={<ImportExport/>}
                >
                    <MenuItem onClick={handleBuildManifest}> <NegroniIcon iconClass="wrench-icon" /> Martini Package</MenuItem>
                    <MenuItem onClick={handleExportDatabaseDialog}> <NegroniIcon iconClass="storage-icon" />Database Structure</MenuItem>
                    <MenuItem onClick={handleExportCdmDialog}><NegroniIcon iconClass="description-icon" /> CDM Schema</MenuItem>
                    <MenuItem onClick={handleExportModelDialog}><NegroniIcon iconClass="model-icon" /> Data Model</MenuItem>
                </NestedMenu>
                <MenuItem onClick={handleDeleteManifest}
                          className='delete'> <NegroniIcon iconClass="delete-icon" /> Delete {currentManifest?.parentManifestId ? 'Sub-Manifest' : 'Manifest'}
                </MenuItem>
            </Menu>
            <AddSubManifestDialog
                open={openAddSubManifestDialog}
                onClose={handleCloseAddSubManifestDialog}
                manifest={currentManifest}
                manifestService={manifestService}
                adminOrgService={adminOrgService}
                onFinish={onSubManifestAdd}
                viewMode={viewMode}
                manifestOrg={manifestOrg}
                orgLabel={orgLabel}
            />
            <ManageEntityDialog
                open={openManageManifestDialog}
                onClose={handleCloseManageEntityDialog}
                manifest={currentManifest}
                entityService={entityService}
                adminOrgService={adminOrgService}
                onFinishAddEntity={onAddEntity}
                onFinishCreateExclusiveEntity={onCreateExclusiveEntity}
                viewMode={viewMode}
                manifestOrg={manifestOrg}
                orgLabel={orgLabel}
            />
            <ExportManifestDialog
                manifest={currentManifest}
                open={openExportManifestDialog}
                exportMode={exportMode}
                onClose={handleCloseExportEntityDialog}
                onExportManifest={onExportManifest}
            />
        </>
    );
}

export default observer(ManifestView);