import React, {FC, useEffect, useState} from "react";
import {AppBar, CircularProgress, createStyles, IconButton, Theme, Toolbar, Tooltip, Avatar, Divider, Typography} from "@material-ui/core";
import {AppLogo} from "../App";
import {useHistory, useLocation, Link} from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SelectOrganisationDialog from "./organisation/SelectOrganisationDialog";
import {makeStyles} from "@material-ui/core/styles";
import useConfirm from "./confirm/useConfirm";
import useSession from "./session/useSession";
import { CDMManager } from "../core/CDMManager";
import useContent from "./content/useContent";
import { TriggerType } from "./content/ContentProvider";
import { NegroniIcon } from "./icon/NegronIIcon";

const headerStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100%)`,
            },
            minHeight: 48
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        navigatorIcon: {
            minWidth: 35,
        },
        imageCircle: {
            borderRadius: '50%',
            width: 20,
        },
        toolBar: {
            backgroundColor: '#221E2E',
            minHeight: 36,
        }
    })
);

interface HeaderProps {
    onStartOnBoarding: () => void;
    onGuidedTours: () => void;
    onDrawerToggle?: () => void;
}

const Header: FC<HeaderProps> = ({children, onStartOnBoarding, onGuidedTours, onDrawerToggle}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openSelectOrgDialog, setOpenSelectOrgDialog] = useState(false);
    const classes = headerStyles();
    const {user} = useSession();
    const confirm = useConfirm();
    const history = useHistory();
    const location = useLocation();
    const [avatarLoaded, setAvatarLoaded] = useState(false);
    const [avatar, setAvatar] = useState<string>('/images/default-avatar.png');
    const {setTriggerType, dirty} = useContent();

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleLogoutClick = () => {
        setAnchorEl(null);
        const confirmOptions = dirty ?
        {message: 'There are unsaved changes, are you sure you want to logout?', title: 'Logout'} :
        {message: 'Are you sure you want to logout?', title: 'Logout'}
        confirm(confirmOptions)
            .then(() => {
                if(dirty) {
                    setTriggerType(TriggerType.LOGOUT);
                }
                localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                localStorage.removeItem(CDMManager.NEGRONI_LAST_VISIT);
                history.push('/sso/logout');
            });
    };

    const handleOrganizationClick = () => {
        setOpenSelectOrgDialog(true)
        setAnchorEl(null);
    };

    const handleOrganizationClose = () => {
        setOpenSelectOrgDialog(false)
    };

    const handleOnboarding = () => {
        onStartOnBoarding()
        setAnchorEl(null)
    }

    const handleGuidedTours = () => {
        onGuidedTours()
        setAnchorEl(null)
    }

    useEffect(() => {
        setAvatarLoaded(false);
    }, []);

    return <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={onDrawerToggle}
                className={classes.menuButton}
            >
                <NegroniIcon iconClass="menu-icon" color="#DDD1F0" />
            </IconButton>
            <AppLogo/>
            {children}
            { user()?.isAdmin && location.pathname !== '/admin' ? <Tooltip title='Admin'>
                <Link
                    onClick={() => {
                        if(localStorage.getItem(CDMManager.NEGRONI_LAST_VISIT) === 'Default') {
                            localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                            localStorage.removeItem(CDMManager.NEGRONI_LAST_VISIT);
                        }
                    }}
                    to='/admin'
                    style={{textDecoration: 'none'}}
                >
                    <IconButton color='primary'>
                        <NegroniIcon iconClass="supervisor-account-icon" color="#DDD1F0" />
                    </IconButton>
                </Link>
            </Tooltip> : undefined }
                <IconButton
                    id="negroni-profile-button"
                    aria-controls="main-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                >
                    <Tooltip title='Your Profile'>
                        <div className={classes.imageCircle}>
                            {avatarLoaded ?
                                <img
                                    id="negroni-profile"
                                    src={avatar}
                                    alt='User'
                                    className={classes.imageCircle}
                                /> :
                                <CircularProgress size={20}/>}
                            <img
                                style={{display: 'none'}}
                                alt='User'
                                onLoad={_ => {
                                    const _user = user();
                                    if (_user && _user?.avatar) {
                                        setAvatarLoaded(true);
                                        setAvatar(_user.avatar);
                                    }
                                }}
                                src={user?.()?.avatar}
                                onError={_ => {
                                    setAvatarLoaded(true);
                                    setAvatar('/images/default-avatar.png');
                                }}
                            />
                        </div>
                    </Tooltip>
                </IconButton>
            <Menu
                id="main-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem
                    disabled={true}
                    style={{opacity: 1}}
                >
                    <ListItemIcon className={classes.navigatorIcon} style={{padding: '10px'}}>
                        <Avatar src={avatar}/>
                    </ListItemIcon>
                    <ListItemText primary={user()?.fullname} secondary={
                        <>
                            <Typography
                                style={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                            >
                                {user()?.email}
                            </Typography>
                      </>
                    }/>
                </MenuItem>
                <Divider/>
                <MenuItem
                    onClick={handleOrganizationClick}
                >
                    <ListItemIcon className={classes.navigatorIcon}>
                        <NegroniIcon iconClass="public-icon" />
                    </ListItemIcon>
                    <ListItemText primary={`Organizations (${user()?.organisationName})`}/>
                </MenuItem>
                <MenuItem
                    onClick={handleOnboarding}
                >
                    <ListItemIcon className={classes.navigatorIcon}>
                        <NegroniIcon iconClass="info-icon" />
                    </ListItemIcon>
                    <ListItemText primary="Onboarding"/>
                </MenuItem>
                <MenuItem
                    onClick={handleGuidedTours}
                >
                    <ListItemIcon className={classes.navigatorIcon}>
                        <NegroniIcon iconClass="flag-icon" />
                    </ListItemIcon>
                    <ListItemText primary="Guided Tours"/>
                </MenuItem>
                <MenuItem
                    onClick={handleLogoutClick}
                >
                    <ListItemIcon className={classes.navigatorIcon}>
                        <NegroniIcon iconClass="exit-to-app-icon" />
                    </ListItemIcon>
                    <ListItemText primary="Logout"/>
                </MenuItem>
            </Menu>
            <SelectOrganisationDialog
                open={openSelectOrgDialog}
                onClose={handleOrganizationClose}
            />
        </Toolbar>
    </AppBar>;
};

export default Header;