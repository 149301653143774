import React, {createContext, createRef} from "react";
import {LoadContentValue} from "./useContent";
import CDMDocument, { CDMDocumentType } from "../../core/observables/CDMDocument";
import {_noop} from "../../util";
import { TriggerType } from "./ContentProvider";
import { CancelTokenSource } from "axios";

export default createContext({
    content: <div/>,
    setLoadContent: (value: LoadContentValue | undefined) => {
    },
    setTriggerType: (value: TriggerType | undefined) => {
    },
    dirty: false,
    setDirty: (dirty: boolean) => {
    },
    busy: createRef(),
    addOnSaveChangesListener: (onSaveChangesListener: ((document: CDMDocument) => Promise<boolean>)) => {
    },
    removeOnSaveChangesListener: (onSaveChangesListener: (document: CDMDocument) => Promise<boolean>) => {
    },
    clearOnSaveChangesListener: _noop,
    setOnCancelWindow: (onCancelWindow: () => void) => {
    },
    // Change processSaveChanges to return a Promise<void>
    processSaveChanges: (loadContent: LoadContentValue, cancelToken: CancelTokenSource): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            try {
                // Add the actual logic for saving changes here
                // Example (this would depend on your actual implementation)
                console.log("Processing save changes...");
                
                // Simulate a successful async operation
                setTimeout(() => {
                    console.log("Save changes complete.");
                    resolve(); // Resolve the Promise when done
                }, 1000);
            } catch (error) {
                console.error("Error while processing save changes:", error);
                reject(error); // Reject the Promise if an error occurs
            }
        });
    }
});