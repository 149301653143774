import React, { FC } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import useQuery from "../router/useQuery";

const Error: FC = () => {
    const query = useQuery();
    const params = new URLSearchParams(window.location.search)
    const errorCode = query.get('code') || params.get('code');
    const error = query.get('error') || params.get('error');
    const errorMessage = query.get('message') || params.get('message');

    const handleRedirectHomepage = () => {
        const signOutUrl = process.env.REACT_APP_SSO_LOGOUT_URL;
        if ( signOutUrl ) {
            window.location.href = signOutUrl
        }
    }

    return <Grid 
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh', background: "#1E0D30", color: "#fff" }}
    >
        <Grid>
        <Typography variant="h1" style={{fontWeight: "bold"}}>
            {errorCode + " - " + error?.toUpperCase()}
        </Typography>
        </Grid>
        <Grid>
        <Typography variant="h6">
            {errorMessage}
        </Typography>
        </Grid>
        <Grid
            style={{ marginTop: '20px' }}
        >
            <Button
                onClick={handleRedirectHomepage}
                variant='contained'
                color='secondary'
            >
                Go To Homepage
            </Button>
        </Grid>
    </Grid>
}

export default Error;