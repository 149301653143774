import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import Package from "../../core/observables/Package";
import {
    Card,
    CardActions,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    Icon,
    IconButton,
    Tooltip
} from "@material-ui/core";
import {PackageStatus} from "../../core/services/PackageService";
import {Alert} from "@material-ui/lab";
import {Link} from "react-router-dom";
import Select from "react-select";
import useService from "../service/useService";
import { PackageUploadDialog } from "./PackageUploadDialog";
import { UploadPackage } from "../../core/observables/Instance";
import useConfirm from "../confirm/useConfirm";
import { useSnackbar } from "notistack";
import { NegroniIcon } from "../icon/NegronIIcon";

interface PackageCardsProps {
    selection: string | null;
    defaultPackages: Package[];
    onPackageStatusChanged: () => void;
    onPackageUpload: (packageId: number, uploadPackage: UploadPackage) => Promise<string | undefined>;
    loadPackages: () => void;
    viewMode?: string
}

export const PackageCards: React.FC<PackageCardsProps> = ({
    selection,
    defaultPackages,
    onPackageStatusChanged,
    onPackageUpload,
    loadPackages,
    viewMode
}) => {
    const [packages, setPackages] = useState<Package[]>([]);
    const [packageStatuses, setPackageStatuses] = useState<{ packageId: number, status?: PackageStatus }[]>([]);
    const [isBusy, setIsBusy] = useState(false);
    const [newPackage, setNewPackage] = useState<Package>();
    const {packageService} = useService();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setPackageStatuses(defaultPackages.filter(p => p.status === PackageStatus.PROCESSING)
            .map(p => ({packageId: p.id, status: p.status})));
        setPackages(defaultPackages);
    }, [defaultPackages]);

    useEffect(() => {
        const eccPackage = packages.find(eccPackage => selection?.toLowerCase() === eccPackage.manifestName.toLowerCase());
        setNewPackage(eccPackage);
    }, [packages]);

    useEffect(() => {
        const pollPackageStatuses = setInterval(() => {
            if (packageStatuses.length > 0 && !isBusy) {
                setIsBusy(true);
                if (packageStatuses.find(p => p.status === PackageStatus.PROCESSING)) {
                    packageService.getPackageStatus(packageStatuses.map(eccPackage => eccPackage.packageId))
                        .then(eccPackageStatuses => {
                            setIsBusy(false);
                            const packageStatuses = [];
                            let update = false; // Checks whether to update the package
                            let moreProcessing = false; // Checks whether there's more processing package to poll for package status
                            for (const eccPackageStatus of eccPackageStatuses) {
                                packageStatuses.push({packageId: eccPackageStatus.id, status: eccPackageStatus.status});
                                const eccPackage = packages.find(p => p.id === eccPackageStatus.id && p.status !== eccPackageStatus.status);
                                if (eccPackageStatus.status === PackageStatus.PROCESSING)
                                    moreProcessing = true;
                                if (eccPackage) {
                                    update = true;
                                    eccPackage.status = eccPackageStatus.status;
                                }
                            }
                            setPackageStatuses(packageStatuses);
                            if (update)
                                onPackageStatusChanged();
                            if (!moreProcessing)
                                clearInterval(pollPackageStatuses);
                        });
                } else {
                    clearInterval(pollPackageStatuses);
                }
            }
        }, 5000);

        return () => clearInterval(pollPackageStatuses);
    }, [packageStatuses]);

    const handleDownloadPackage = (version: string, eccPackage: Package) => {
        packageService.downloadPackage(eccPackage.id, version)
            .then(data => {
                const tempLink = document.createElement('a');
                tempLink.href = window.URL.createObjectURL(new Blob([data], {type: 'application/zip;charset=UTF-8'}));
                tempLink.download = `${eccPackage.manifestName}.zip`
                tempLink.click();
            });
    }

    const handleDeletePackage  = (version: string, eccPackage: Package) => {
        packageService.deletePackage(eccPackage.id, version)
            .then(_ => {
                loadPackages();
                enqueueSnackbar('Successfully deleted a package', {variant: 'success'});
            });
    }

    return <Grid container spacing={2}>
        {newPackage ? <>
            <Grid item xs={12}>
                <Alert severity='info'>
                    You are currently viewing the new package. <Link to='/packages'
                                                                     onClick={_ => setNewPackage(undefined)}>View
                    all</Link> packages that you built.
                </Alert>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} key={newPackage.id}>
                <PackageCardItem
                    eccPackage={newPackage}
                    onDownloadPackage={handleDownloadPackage}
                    onDeletePackage={handleDeletePackage}
                    onUploadPackage={onPackageUpload}
                />
            </Grid>
        </> : packages.map(eccPackage => (
            <Grid item xs={12} {...viewMode === 'Admin' ? {
                sm: 12,
                md: 6,
                lg: 6,
            } : {...viewMode === 'Nav' ? undefined : {
                sm: 6,
                md: 3,
                lg: 3,
            }}} key={eccPackage.id}>
                <PackageCardItem
                    eccPackage={eccPackage}
                    onDownloadPackage={handleDownloadPackage}
                    onDeletePackage={handleDeletePackage}
                    onUploadPackage={onPackageUpload}
                    {...viewMode === 'Admin' ? {viewMode: 'Admin'} : { viewMode: ''}}
                />
            </Grid>
        ))}
    </Grid>;
};

interface PackageCardItemProps {
    eccPackage: Package;
    onDownloadPackage: (version: string, eccPackage: Package) => void;
    onDeletePackage: (version: string, eccPackage: Package) => void;
    onUploadPackage: (packageId: number, uploadPackage: UploadPackage) => Promise<string | undefined>;
    viewMode?: string;
}

export const PackageCardItem: React.FC<PackageCardItemProps> = ({eccPackage, onDownloadPackage, onDeletePackage, onUploadPackage, viewMode}) => {
    const [version, setVersion] = useState<string>();
    const [versions, setVersions] = useState<{ label: string, value: string }[]>();
    const [uploadPackageDialog, setUploadPackageDialog] = useState<boolean>(false);
    const confirm = useConfirm();

    useEffect(() => {
        const theVersions = eccPackage.versions.map(version => ({label: version, value: version}));
        setVersions(theVersions);
        if (theVersions.length > 0) setVersion(theVersions[0].value);
    }, []);

    console.log(versions)

    const handleUploadPackageOpen = () => {
        setUploadPackageDialog(true);
    }

    const handleDeletePackage = () => {
        confirm({message: `Do you really want to delete the package ${version}?`, title: `Delete Package ${version}`})
            .then(_ => {
                if (version)
                    onDeletePackage(version, eccPackage)
            });
    }

    return <Card>
        <CardHeader avatar={<Icon color='primary'>assignment</Icon>} title={eccPackage.manifestName}/>
        <Divider/>
        <CardActions style={{display: 'flex', flexGrow: 1}}>
            <Select
                styles={{
                    container: base => ({
                        ...base,
                        flex: 1
                    })
                }}
                placeholder='Select version...'
                options={versions}
                value={!!version ? {label: version, value: version} : null}
                onChange={(value: any) => setVersion(value?.value)}
                menuPosition="fixed"
            />
            <Tooltip title={`Download version ${version}`}>
                <IconButton
                    disabled={!version}
                    onClick={_ => {
                        if (version) onDownloadPackage(version, eccPackage)
                    }}
                >
                    {eccPackage.status === 'PROCESSING' ? <CircularProgress size={12}/> :
                        <NegroniIcon
                            iconClass="cloud-download-icon"
                            color={version && (eccPackage.versions.length > 0 || eccPackage.status === PackageStatus.SUCCESS) ? 'secondary' : 'inherit'}
                        />}
                </IconButton>
            </Tooltip>
            {viewMode !== 'Admin' && <Tooltip title='Upload'>
                <IconButton
                    disabled={!version}
                    onClick={handleUploadPackageOpen}>
                        <NegroniIcon
                            iconClass="publish-icon"
                            color={version && (eccPackage.versions.length > 0 || eccPackage.status === PackageStatus.SUCCESS) ? 'secondary' : 'inherit'}
                            />
                </IconButton>
            </Tooltip>}
            <Tooltip title={`Delete version ${version}`}>
                <IconButton
                    onClick={handleDeletePackage}
                >
                    <NegroniIcon
                        iconClass="delete-icon"
                        color='var(--light-error-color)'
                    / >
                </IconButton>
            </Tooltip>
        </CardActions>
        <PackageUploadDialog
            manifestName={eccPackage.manifestName}
            packageId={eccPackage.id}
            version={version || '1.0.0'}
            open={uploadPackageDialog}
            onClose={() => {setUploadPackageDialog(false)}}
            onUpload={onUploadPackage}
        />
    </Card>
};

export default observer(PackageCards);