import {createContext} from "react";
import AuthState from "./AuthState";

export interface AuthContextValue {
    logout: () => void;
    generateState: () => string;
    storeState: (stateToken: string) => void;
    getState: () => string;
    getAuthState: () => AuthState;
    updateAuthState: (authState: AuthState) => void;
    ssoSignIn: (code: string) => Promise<{id: number, permissions: string}>;
    ssoRefreshToken: (refreshToken: string) => Promise<string>;
}

export default createContext<AuthContextValue>({} as AuthContextValue);