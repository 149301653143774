import React from "react";
import {
    Avatar,
    Badge,
    Button,
    createStyles,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    withStyles,
} from "@material-ui/core";
import {observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import {Org, OrgPermissions} from "../auth/User"
import LaunchIcon from '@material-ui/icons/Launch';

interface OrganisationListProp {
    orgs: OrgPermissions[];
    handleSwitchOrg: (orgName: string, orgCode: string) => void;
    currentOrg: Partial<Org>;
}

const orgStyle = makeStyles((theme: Theme) =>
    createStyles({
        orgText: {
            fontSize: '3em !important',
            paddingLeft: 10,
        },
        largeAvatar: {
            width: theme.spacing(9),
            height: theme.spacing(9),
            backgroundImage: 'linear-gradient(315deg,var(--dark-secondary-color) 0,var(--data-heading-color) 50%)',
        },
    }),
);

const StyledBadge = withStyles(theme => ({
    badge: {
        backgroundColor: "var(--light-success-color)",
        color: "(--light-success-color)",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            border: "1px solid var(--light-success-color)",
            content: '""'
        }
    }
}))(Badge);

const OrganisationList: React.FC<OrganisationListProp> = ({orgs, handleSwitchOrg, currentOrg}) => {
    const classes = orgStyle();
    const orgAcronym = (orgName: string): string => orgName?.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');

    return <List>
        {currentOrg.code !== undefined ? <ListItem key={currentOrg.code}>
            {currentOrg.name &&
            <ListItemAvatar>
                <StyledBadge
                    overlap='circle'
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    badgeContent=' '
                >
                    <Avatar className={classes.largeAvatar}>
                        {orgAcronym(currentOrg.name)}
                    </Avatar>
                </StyledBadge>
            </ListItemAvatar>}
            <ListItemText
                className={classes.orgText}
                primary={currentOrg.name}
            />
            <Button
                style={{margin: '10px'}}
                size='small'
                color='secondary'
                variant='contained'
                disabled
            >
                <LaunchIcon style={{paddingRight: '5px'}}/> Select
            </Button>
        </ListItem> : undefined}
        {orgs?.map(org => (
            <ListItem key={org.code}>
                <ListItemAvatar>
                    <Avatar className={classes.largeAvatar}>
                        {orgAcronym(org.name)}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    className={classes.orgText}
                    primary={org.name}
                />
                <Button
                    onClick={_ => {
                        handleSwitchOrg(org.name, org.code)
                    }}
                    style={{margin: '10px'}}
                    size='small'
                    color='secondary'
                    variant='contained'
                    disabled={org.ng === false}
                    >
                        <LaunchIcon style={{ paddingRight: '5px' }} /> {org.ng === false ? 'No subscription' : 'Select'}
                </Button>
            </ListItem>
        ))}
    </List>
}

export default observer(OrganisationList);