import React, { useState, useEffect, useCallback } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, FormControl, FormHelperText } from "@material-ui/core";
import { observer } from "mobx-react";
import Manifest, { ConnectionPoolType } from "../../core/observables/Manifest";
import { ExportTypes } from "../manifest/ExportManifestDialog";
import useService from "../service/useService";
import { Filter } from "../../core/Filter";
import { PaginatedSelect, SelectOption, SelectGroupOption } from "../select/PaginatedSelect";
import { CancelTokenSource } from "axios";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import { LoadingProgress } from "../LoadingProgress";
import { Alert } from "@material-ui/lab";

export interface ExportDataModelWizardProp {
    open: boolean;
    onClose: () => void;
    onExportManifest: (manifest: Manifest, exportType: ExportTypes, databaseType: string, applyAutoIncrement: boolean) => Promise<string | undefined>;
}

export const ExportDataModelWizard: React.FC<ExportDataModelWizardProp> = ({
    open,
    onClose,
    onExportManifest,
}) => {
    const { manifestService } = useService();
    const [manifest, setManifest] = useState<Manifest>();
    const [page, setPage] = useState<Number>(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [isPreLoading, setIsPreLoading] = useState(false);

    useEffect(() => {
        if(open) {
            setManifest(undefined)
            setPage(1)
            setLoading(false)
            setError('')
            setIsPreLoading(false)
        }
    }, [open]);

    const nextPage = () => {
        if(page === 1) {
            setPage(2)
            setIsPreLoading(true)
            if(manifest) {
                manifestService.validateManifest(manifest.id)
                    .then(_ => {
                        setError('')
                    })
                    .catch(setError)
                    .finally(() => setIsPreLoading(false))
            }
        }
    }

    const backPage = () => {
        if(page === 2) {
            setPage(1)
            setError('')
        }
    }

    const handleNextOrFinish = () => {
        if(page === 2) {
            setLoading(true);
            if(manifest) {
                onExportManifest(manifest, ExportTypes.DATA_MODEL, ConnectionPoolType.PSQL, false)
                    .then(error => {
                        setLoading(false);
                        if (error && error.length > 0) {
                            setError(error);
                        } else {
                            onClose();
                        }
                    })
            }
        } else {
            nextPage();
        }
    }

    const handleLoadManifestOptions = useCallback((page: number, limit: number, search?: string, cancelTokenSource?: CancelTokenSource): Promise<PaginatedResponse<SelectOption[] | SelectGroupOption[]>> => {
        return manifestService.getManifests(Filter.PRIVATE, undefined, limit, page, search, cancelTokenSource?.token)
            .then(response => ({
                ...response,
                result: response.result.map(manifest => ({
                    label: manifest.name,
                    value: manifest,
                }))
            }))
    }, []);

    const hasError = (): boolean => {
        return error !== null && error.length > 0;
    }

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="form-dialog-title">Export Manifest as Data Model</DialogTitle>
        <DialogContent dividers style={{minHeight: '350px', minWidth: '550px'}}>
            {page === 1 ? <div>
                <p className='dialog-label'>Select a manifest to export</p>
                <FormControl variant='filled' fullWidth={true}>
                    <PaginatedSelect
                        getOptions={handleLoadManifestOptions}
                        valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                        placeholder='Select Manifest'
                        defaultValue={manifest}
                        onValueChanged={setManifest}
                        formatOptionLabel={option => {
                            return <div style={{display: 'flex', flexDirection: 'column'}}>
                                {option.label}
                            </div>;
                        }}
                        autoFocus={true}
                    />
                </FormControl>
            </div> : <></> }
            {page === 2 ? <div>
                {isPreLoading ? <>
                    <h3 style={{textAlign: 'center'}}>Validating...</h3>
                    <LoadingProgress/>
                </> : <>
                {!!error && error.length > 0 ?
                    <Alert severity='error'> {error} </Alert> :
                    <>
                        <p className='dialog-label'>Export <b>{manifest?.name}</b> into a Data Model JSON format.</p>
                        <FormControl variant='filled' fullWidth={true} error={hasError()}>
                            <FormHelperText>{error}</FormHelperText>
                        </FormControl>
                    </> }
                </>}
            </div> : <></> }
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} style={{marginRight: 'auto'}}>
                Cancel
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={page === 1}
                onClick={backPage}
            >
                Back
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={loading || (!!error && error.length > 0) || (page === 1 && manifest === undefined) }
                disableElevation
                onClick={handleNextOrFinish}
                startIcon={loading && <CircularProgress size={18}/>}
            >
                {page === 2 ? "Finish" : "Next" }
            </Button>
        </DialogActions>
    </Dialog>
}

export default observer(ExportDataModelWizard);