import React, {useCallback, useEffect, useRef} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl
} from "@material-ui/core";
import {Filter} from "../../core/Filter";
import {ManifestService} from "../../core/services/ManifestService";
import DocumentItem from "../../core/observables/DocumentItem";
import Entity from "../../core/observables/Entity";
import {PaginatedSelect, SelectGroupOption, SelectOption} from "../select/PaginatedSelect";
import {CancelTokenSource} from "axios";
import PaginatedResponse from "../../core/services/PaginatedResponse";
import { AdminOrganisationService } from "../../core/services/admin/AdminOrganisationService";

interface AddEntityToManifestDialogProps {
    entity: Entity;
    open: boolean;
    onClose: () => void;
    onFinish: (manifest: DocumentItem, entity: Entity) => Promise<string | undefined>;
    manifestService: ManifestService;
    adminOrgService: AdminOrganisationService;
    defaultManifest?: DocumentItem;
    viewMode?: string;
    manifestOrg?: string;
    orgLabel?: string;
}

export const AddEntityToManifestDialog: React.FC<AddEntityToManifestDialogProps> = ({
    entity,
    open,
    onClose,
    onFinish,
    manifestService,
    adminOrgService,
    defaultManifest,
    viewMode,
    manifestOrg,
    orgLabel,
}) => {
    const [manifest, setManifest] = React.useState<DocumentItem | null>(defaultManifest || null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>();
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (open) {
            setErrorMessage(null);
            setManifest(null);
        }
    }, [open]);

    const handleLoadManifestOptions = useCallback((page: number, limit: number, search?: string, cancelTokenSource?: CancelTokenSource): Promise<PaginatedResponse<SelectOption[] | SelectGroupOption[]>> => {
        if(viewMode === 'Admin') {
            return adminOrgService.getManifestsByOrg(manifestOrg || 'TORO', limit, page, search, -1, cancelTokenSource?.token)
                .then(response => ({
                    ...response,
                    result: response.result.map(manifest => ({
                        label: manifest.name,
                        value: manifest,
                    }))
                }))
        }
        return manifestService.getManifests(Filter.PRIVATE, undefined, limit, page, search, cancelTokenSource?.token)
            .then(response => ({
                ...response,
                result: response.result.filter(res => entity.targetManifest !== res.id).map(manifest => ({
                    label: manifest.name,
                    value: manifest,
                }))
            }))
    }, []);

    const handleFinish = () => {
        if (manifest) {
            setIsLoading(true);
            setErrorMessage(null);
            onFinish(manifest, entity)
                .then(error => {
                    if (isMounted.current) {
                        setIsLoading(false);
                        if (error && error.length > 0) {
                            setErrorMessage(error);
                        } else {
                            onClose();
                            setManifest(null);
                        }
                    }
                });
        }
    };

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Add Entity To Manifest</DialogTitle>
        <DialogContent dividers style={{overflowY: 'hidden', maxWidth: '500px', width: '500px', minHeight: '400px'}}>
            { viewMode === 'Admin' ?
                <p className='dialog-label'>Select a manifest from <b>{orgLabel}</b> organisation to add <b>{entity?.name}</b> entity.</p> :
                <p className='dialog-label'>Select a manifest to add <b>{entity?.name}</b> entity.</p> }
            <FormControl variant='filled' fullWidth={true}>
                <PaginatedSelect
                    getOptions={handleLoadManifestOptions}
                    valueMapper={value => value ? ({label: value.name, value: value.value}) : null}
                    placeholder='Select Manifest'
                    onValueChanged={setManifest}
                    formatOptionLabel={option => {
                        return <div style={{display: 'flex', flexDirection: 'column'}}>
                            {option.label}
                        </div>;
                    }}
                    autoFocus={true}
                />
                <div className='invalid-feedback'
                     hidden={!errorMessage || errorMessage.length === 0}>{errorMessage}</div>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                Close
            </Button>
            <Button
                disableElevation
                color='secondary'
                variant='contained'
                disabled={isLoading || manifest === null}
                onClick={handleFinish}
                startIcon={isLoading && <CircularProgress size={18}/>}
            >
                Finish
            </Button>
        </DialogActions>
    </Dialog>;
}

export default AddEntityToManifestDialog;