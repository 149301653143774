import React, {useState, useEffect} from "react";
import Manifest, { ConnectionPoolType, connectionPoolTypeToLabel, getConnectionPoolOptions } from "../../core/observables/Manifest";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    CircularProgress,
    FormHelperText,
    FormControl,
    makeStyles,
    createStyles,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import Select from "react-select";
import { observer } from "mobx-react";
import { Alert } from "@material-ui/lab";
import useService from "../service/useService";
import { LoadingProgress } from "../LoadingProgress";
import useSession from "../session/useSession";

export enum ExportManifestTabValue {
    CDM_SCHEMA = 'cdmSchema',
    DATABASE_SCHEMA = 'databaseSchema',
}

export enum ExportTypes {
    CDM_SCHEMA = 'CDM_SCHEMA',
    DATABASE_SCHEMA = 'DB_STRUCTURE',
    DATA_MODEL = 'ENTITY_GLOOP_MODEL'
}

const dialogStyles = makeStyles(() =>
    createStyles({
        groupStyles: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        groupBadgeStyles: {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        },
    }),
);

export interface ExportManifestDialogProp {
    manifest: Manifest;
    open: boolean;
    exportMode: ExportTypes;
    onClose: () => void;
    onExportManifest: (manifest: Manifest, exportType: ExportTypes, databaseType: string, applyAutoIncrement: boolean) => Promise<string | undefined>;
}

export const ExportManifestDialog: React.FC<ExportManifestDialogProp> = ({
    manifest,
    open,
    exportMode,
    onClose,
    onExportManifest,
}) => {
    const classes = dialogStyles();
    const {manifestService} = useService();
    const { limits } = useSession();
    const allowedDb = limits().allowedDb;
    const [databaseType, setDatabaseType] = useState<ConnectionPoolType>(allowedDb[0]);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [connectionTypeOptions, setConnectionTypeOptions] = useState<any>([]);
    const [preErrorMessage, setPreErrorMessage] = useState<string>('');
    const [isPreLoading, setIsPreLoading] = useState(false);
    const [applyAutoIncrement, setApplyAutoIncrement] = useState(true);
    

    useEffect(() => {
        setConnectionTypeOptions(getConnectionPoolOptions(allowedDb));
    }, []);

    useEffect(() => {
        if (open) {
            setErrorMessage('');
            setIsPreLoading(true);

            manifestService.validateManifest(manifest.id)
                .then(_ => {
                    setPreErrorMessage('');
                })
                .catch(setPreErrorMessage)
                .finally(() => setIsPreLoading(false));
        }
    }, [open]);

    const formatGroupLabel = (data: any) => (
        <div className={classes.groupStyles}>
            <h2>{data.label}</h2>
            <span className={classes.groupBadgeStyles}>{data.options.length}</span>
        </div>
    );
    
    const hasError = (): boolean => {
        return errorMessage !== null && errorMessage.length > 0;
    }

    const needInfo = (connectionType: ConnectionPoolType) => {
        return connectionType === ConnectionPoolType.HSQL ||
        connectionType === ConnectionPoolType.MYSQL;
    }

    const recommendProperty = (connectionType: ConnectionPoolType) => {
        switch(connectionType) {
            case ConnectionPoolType.HSQL:
                return 'sql.syntax.mys=true';
            case ConnectionPoolType.MYSQL:
                return "sessionVariables=sql_mode='ANSI'";
            default:
                return '';
        }
    }

    const handleAutoIncrement = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApplyAutoIncrement(event.target.checked);
    }

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Export Manifest as {exportMode === ExportTypes.CDM_SCHEMA ? 'CDM Schema' : (exportMode === ExportTypes.DATA_MODEL ? 'Data Model' : 'Database Structure')}
        </DialogTitle>
        <form onSubmit={e => {
            e.preventDefault();
            setErrorMessage(null);
            setIsLoading(true);
            onExportManifest(manifest, exportMode, databaseType, applyAutoIncrement)
                .then(error => {
                    setIsLoading(false);
                    if (error && error.length > 0) {
                        setErrorMessage(error);
                    } else {
                        setDatabaseType(allowedDb[0]);
                        onClose();
                    }
                })
        }}>
            {isPreLoading ?
                <DialogContent dividers>
                    <h3 style={{textAlign: 'center'}}>Validating...</h3>
                    <LoadingProgress/>
                </DialogContent> :
                <>{exportMode === ExportTypes.DATABASE_SCHEMA ?
                    <DialogContent dividers style={{overflowY: 'hidden', maxWidth: '500px', width: '500px', minHeight: !!preErrorMessage && preErrorMessage.length > 0 ? '100px' : '200px'}}>
                        {!!preErrorMessage && preErrorMessage.length > 0 ?
                            <Alert severity='error'> {preErrorMessage} </Alert> : <>
                            <p className='dialog-label'>Select a database type to be used in exporting <b>{manifest?.name}</b> database structure.</p>
                            {needInfo(databaseType) && <Alert severity="info" style={{marginBottom: '10px'}}>
                                We recommend adding this property <strong>{recommendProperty(databaseType)}</strong> to your connection URL
                            </Alert>}
                            <Select
                                autoFocus={true}
                                options={connectionTypeOptions}
                                placeholder='Database Type'
                                value={databaseType && ({
                                    label: connectionPoolTypeToLabel(databaseType),
                                    value: databaseType,
                                    parent: null,
                                })}
                                onChange={(option: any) => {
                                    setDatabaseType(option?.value);
                                }}
                                formatGroupLabel={formatGroupLabel}
                                formatOptionLabel={option => {
                                    return <div style={{display: 'flex'}}>
                                        {option?.parent !== null ? <div style={{paddingLeft: '10px'}}>
                                            {option.label}
                                        </div> : <div>
                                            {option.label}
                                        </div>}
                                    </div>;
                                }}
                                menuPortalTarget={document.body}
                                styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                                menuPlacement='auto'
                                maxMenuHeight={200}
                            />
                            <FormControl variant='filled' fullWidth={true} error={hasError()}>
                                <FormHelperText>{errorMessage}</FormHelperText>
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox checked={applyAutoIncrement} onChange={handleAutoIncrement} />}
                                label="Apply Auto-Increment"
                            />
                            </>
                        }
                    </DialogContent> : <DialogContent dividers style={{overflowY: 'hidden', maxWidth: '500px', width: '500px', minHeight: '100px'}}>
                        {!!preErrorMessage && preErrorMessage.length > 0 ?
                            <Alert severity='error'> {preErrorMessage} </Alert> : <>
                            <p className='dialog-label'>Export <b>{manifest?.name}</b> into a {exportMode === ExportTypes.DATA_MODEL ? 'Data Model' : 'CDM schema JSON format' }.</p>
                            <FormControl variant='filled' fullWidth={true} error={hasError()}>
                                <FormHelperText>{errorMessage}</FormHelperText>
                            </FormControl></>
                        }
                    </DialogContent>}
                </>}
            <DialogActions>
                {!!preErrorMessage && preErrorMessage.length > 0 ?
                <Button onClick={onClose}>
                    Close
                </Button>  : <>
                <Button onClick={onClose}>
                    Close
                </Button>
                <Button
                    type='submit'
                    color='secondary'
                    variant='contained'
                    disableElevation
                    disabled={isLoading}
                    onClick={() => {
                        setErrorMessage(null);
                        setIsLoading(true);
                        onExportManifest(manifest, exportMode, databaseType, applyAutoIncrement)
                            .then(error => {
                                setIsLoading(false);
                                if (error && error.length > 0) {
                                    setErrorMessage(error);
                                } else {
                                    setDatabaseType(allowedDb[0]);
                                    onClose();
                                }
                            })
                    }}
                    startIcon={isLoading && <CircularProgress size={18}/>}
                >
                    Finish
                </Button>
            </>}
            </DialogActions>
        </form>
    </Dialog>
}

export default observer(ExportManifestDialog);