import React, {FC} from "react";
import User, {Org, LicenseLimits} from "../auth/User";
import SessionContext, {SessionContextValue} from "./SessionContext";
import Cookies from "js-cookie";
import { ConnectionPoolType } from "../../core/observables/Manifest";

export const USER_KEY = 'NEGRONI_USER';
export const SESSION_KEY = 'NEGRONI_SESSION';
export const REFRESH_TOKEN = 'NEGRONI_REFRESH_TOKEN';
export const ID_TOKEN = 'NEGRONI_ID_TOKEN';
export const LAST_NEGRONI_USER = 'LAST_NEGRONI_USER';
export const LAST_NEGRONI_ORG_CODE = 'LAST_NEGRONI_ORG_CODE';
export const LAST_NEGRONI_ORG_NAME = 'LAST_NEGRONI_ORG_NAME';

interface SessionProviderProps {
    defaultUser?: User;
}

const SessionProvider: FC<SessionProviderProps> = ({defaultUser, children}) => {
    const isLoggedIn = (): boolean => {
        return (
            !!Cookies.get(SESSION_KEY) &&
            !!Cookies.get(REFRESH_TOKEN) &&
            !!Cookies.get(ID_TOKEN)
        )
    }

    const hasRefreshToken = (): boolean => {
        return !!Cookies.get(REFRESH_TOKEN);
    }

    const getRefreshToken = (): string  => {
        return Cookies.get(REFRESH_TOKEN) || "";
    }

    const hasSessionToken = (): boolean => {
        return !!Cookies.get(SESSION_KEY);
    }

    const getSessionToken = (): string => {
        return Cookies.get(SESSION_KEY) || "";
    }

    const user = () => defaultUser || JSON.parse(localStorage.getItem(USER_KEY)!) as User;

    const getUser = (): User | undefined => {
        const user = Cookies.getJSON(USER_KEY);

        if (user)
            return user;
    }

    const setUser = (user: User | undefined) => {
        if (user) {
            localStorage.setItem(USER_KEY, (JSON.stringify(user)));
            Cookies.set(LAST_NEGRONI_USER, user.id.toString())
            Cookies.set(LAST_NEGRONI_ORG_CODE, user.organisationCode)
            Cookies.set(LAST_NEGRONI_ORG_NAME, user.organisationName)
        }
        else Cookies.remove(USER_KEY);
    };

    const org = () => {
        if (user())
            return {code: user().organisationCode, name: user().organisationName} as Org;
    };

    const setOrg = (code: string, name: string) => {
        const _user = user();

        if (_user) {
            _user.organisationCode = code;
            _user.organisationName = name;
            setUser(_user);
        }
    }
      
    const limits = () => {
      if (user() && org()) {
        interface ValueObject {
          [key: string]: any;
        }

        interface ParsedObject {
          [key: string]: ValueObject;
        }

        const limit = user().negroniLicenseLimits;
        const parts = limit.split("|");
        const result: ParsedObject = {};

        parts.forEach((part) => {
          const [key, values] = part.split("=");
          const valueObject: ValueObject = values
            .split(",")
            .reduce((obj: ValueObject, item: string) => {
              const [k, v] = item.split(":");
              obj[k] = v;
              return obj;
            }, {});
          result[key] = valueObject;
        });
        const orgLicenseLimit = result[org()!.code];
        return {
            mc: parseInt(orgLicenseLimit.mc),
            rs: orgLicenseLimit.rs && orgLicenseLimit.rs === '0' ? false : true,
            gqls: orgLicenseLimit.gqls && orgLicenseLimit.gqls === '0' ? false : true,
            // For QA TESTING - See Manifest.tsx Line 358 for other options
            // allowedDb: [ConnectionPoolType.MYSQL, ConnectionPoolType.ARED, ConnectionPoolType.AS]
            allowedDb: orgLicenseLimit.db ? orgLicenseLimit.db.split("~").map((db: string) => ConnectionPoolType[db as keyof typeof ConnectionPoolType]).filter(Boolean) : []
        } as LicenseLimits;
      }
    };

    return <>
        <SessionContext.Provider value={{
            isLoggedIn,
            hasRefreshToken,
            getRefreshToken,
            hasSessionToken,
            getSessionToken,
            user,
            getUser,
            setUser,
            org,
            setOrg,
            limits
        } as SessionContextValue}>
            {children}
        </SessionContext.Provider>
    </>
};

export default SessionProvider;