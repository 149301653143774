import Workspace from "./observables/Workspace";
import {action, observable} from "mobx";

export class CDMManager {
    static readonly HOST = 'localhost:8080';
    static readonly PRE_DEFINED = 'github';
    static readonly USER_DEFINED = 'local';
    static readonly NEGRONI_CORE_SCHEMA_KEY = 'NEGRONI_CORE_SCHEMA';
    static readonly NEGRONI_CORE_SCHEMA_VERSION_KEY = 'NEGRONI_CORE_SCHEMA_VERSION';
    static readonly NEGRONI_CURRENT_SELECTED_NODE = 'NEGRONI_CURRENT_SELECTED_NODE';
    static readonly NEGRONI_RESET_SELECTED_NODE = 'NEGRONI_RESET_SELECTED_NODE';
    static readonly NEGRONI_LAST_VISIT = 'NEGRONI_LAST_VISIT';
    static readonly NEGRONI_LOGIN = 'NEGRONI_LOGIN';
    @observable
    workspaces: Workspace[] = [];
    private workspaceLoaded: boolean = false;

    init() {
        const coreWorkspace = new Workspace(CDMManager.PRE_DEFINED, true);
        this.addWorkspace(coreWorkspace);
        const customWorkspace = new Workspace(CDMManager.USER_DEFINED, false);
        this.addWorkspace(customWorkspace);
        this.workspaceLoaded = true;
    }

    /**
     * Returns the workspace by name else create the workspace with the name.
     * @param name  The workspace to return.
     */
    getWorkspace(name: string): Workspace {
        return this.workspaces.find(w => w.name === name) || this.addWorkspace(new Workspace(name, false));
    }

    @action
    addWorkspace(workspace: Workspace) {
        this.workspaces.push(workspace);
        return workspace;
    }

    private checkWorkspace(): void {
        if (!this.workspaceLoaded)
            throw new Error('Workspace is not loaded yet.');
    }

}