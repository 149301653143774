import {CDMDocumentType} from "../observables/CDMDocument";
import {CDMManager} from "../CDMManager";
import {Filter} from "../Filter";
import config from "../../config";
import DocumentItem from "../observables/DocumentItem";
import Entity, {CreateEntity, UpdateEntity, CreateEntityWizard} from "../observables/Entity";
import EntityAttribute from "../observables/EntityAttribute";
import HttpClient, {CancelToken} from "axios";
import PaginatedResponse from "./PaginatedResponse";
import TypeAttribute from "../observables/TypeAttribute";
import { isEmpty } from "lodash";

export enum TypeAttributeFilter {
    ALL = 'ALL',
    UNRESOLVED = 'UNRESOLVED',
}

/**
 * Makes an http request to CDM api
 */
export class EntityService {

    readonly PATH = `/${config.api_version}/cdm/entity`;

    deleteEntity(entityId: number, cancelToken?: CancelToken) {
        return HttpClient.delete(`${this.PATH}/${entityId}`, {cancelToken});
    }

    getEntityById(id: number, filter: TypeAttributeFilter = TypeAttributeFilter.ALL, version?: string | undefined, cancelToken?: CancelToken) {
        return HttpClient.get(`${this.PATH}/${id}`, {
            params: {typeAttributeFilter: filter, version},
            cancelToken
        }).then(({data}) => mapToEntity(data.entity));
    }

    getEntitiesByOrg(org: string, filter: Filter = Filter.ALL, limit: number = 1000, page: number = 0, search: string = '', cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get('/api/entities.json', {
            params: {
                filter,
                limit,
                page,
                search,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.entity.map(rawPathToEntityPath),
        }));
    }

    getEntities(filter: Filter = Filter.ALL, limit: number = 1000, page: number = 0, search: string = '', excludedEntity: number = -1, folderFilter?: string, pathFilter?: string, cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(this.PATH, {
            params: {
                filter,
                limit,
                page,
                search,
                excludedEntity,
                folderFilter,
                pathFilter,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.entity.map(rawPathToEntityPath),
        }));
    }

    getFolders(limit: number = 20, page: number = 0, search: string = '', cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.PATH}/folders`, {
            params: {
                limit,
                page,
                search,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.folders.map(mapToFolder),
        }));
    }

    getEntitiesByFolder(folder: string, limit: number = 20, page: number = 0, search: string = '', cancelToken?: CancelToken): Promise<PaginatedResponse<DocumentItem[]>> {
        return HttpClient.get(`${this.PATH}/folders/${folder}`, {
            params: {
                limit,
                page,
                search,
            },
            cancelToken,
        }).then(({data}) => ({
            totalElements: data.totalElements,
            size: data.size,
            page: data.page,
            status: data.result,
            message: data.message,
            result: data.entity.map(rawPathToEntityPath),
        }));
    }

    createEntity(entity: CreateEntity, cancelToken?: CancelToken): Promise<Entity> {
        return HttpClient.post(this.PATH, entity, {
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => mapToEntity(data.entity));
    }

    createEntityWizard(entity: CreateEntityWizard, cancelToken?: CancelToken): Promise<Entity> {
        return HttpClient.post(`${this.PATH}/wizard`, entity, {
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => mapToEntity(data.entity));
    }

    updateEntity(entityId: number, entity: UpdateEntity, cancelToken?: CancelToken) {
        return HttpClient.put(`${this.PATH}/${entityId}`, entity, {
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => mapToEntity(data.entity));
    }

    createEntityAttribute(entityId: number, entityAttribute: Partial<CreateEntityAttribute>, cancelToken?: CancelToken): Promise<EntityAttribute> {
        return HttpClient.post(`${this.PATH}/${entityId}/associatedEntity`, entityAttribute, {
            headers: {'Content-Type': 'application/json'},
            cancelToken,
        }).then(({data}) => mapToEntityAttribute(data.entityAttribute));
    }

    deleteEntityAttribute(entityId: number, associatedEntityId: number, associatedEntityName: string, cancelToken?: CancelToken): Promise<void> {
        return HttpClient.delete(`${this.PATH}/${entityId}/associatedEntity/${associatedEntityId}`, {
            params: { associatedAttributeName: associatedEntityName },
            cancelToken})
            .then(({data}) => data);
    }

    updateEntityAttribute(entityId: number, associatedEntityId: number, entityAttribute: Partial<UpdateEntityAttribute>, oldAssociatedEntityName: string,  cancelToken?: CancelToken): Promise<EntityAttribute> {
        return HttpClient.put(`${this.PATH}/${entityId}/associatedEntity/${associatedEntityId}`, entityAttribute, {
            headers: {'Content-Type': 'application/json'},
            params: { associatedAttributeName: oldAssociatedEntityName },
            cancelToken,
        }).then(({data}) => mapToEntityAttribute(data.entityAttribute));
    }

    importEntity(entityFile: FormData, targetManifest: number, cancelToken?: CancelToken): Promise<Entity> {
        return HttpClient.post(`${this.PATH}/import`, entityFile, {
            params: {
                targetManifest
            },
            headers: {'Content-Type': 'multipart/form-data'},
            cancelToken,
        }).then(({data}) => mapToEntity(data.entity));
    }

    createEntityVersion(id: number, version: string, cancelToken?: CancelToken): Promise<void> {
        return HttpClient.post(`${this.PATH}/custom/${id}/version`, {}, {
            params: {
                version
            },
            cancelToken,
        }).then(({data}) => data)
    }

    getEntityVersion(id: number, version: string, cancelToken?: CancelToken) {
        return HttpClient.get(`${this.PATH}/version/content/${id}`, {
            params: {
                version
            },
            cancelToken
        }).then(({data}) => data);
    }

    getEntityVersionsById(id: number, cancelToken?: CancelToken) {
        return HttpClient.get(`${this.PATH}/custom/${id}/version`, {
            cancelToken
        }).then(({data}) => data);
    }

    async deleteEntityVersion(entityId: number, version: string,  cancelToken?: CancelToken) {
        try {
            await HttpClient.delete(`${this.PATH}/custom/${entityId}/version`, {
                params: {
                    version
                },
                cancelToken
            });
        }
        catch (e) {
            return console.error(e);
        }
    }
}

export const entityToEntityPath = (entity: Entity): DocumentItem => {
    return {
        name: entity.name,
        path: entity.path,
        isDefault: entity.isDefault,
        documentType: CDMDocumentType.ENTITY,
        id: entity.id,
    } as DocumentItem;
}

export const rawPathToEntityPath = (raw: any): DocumentItem => {
    return {
        name: raw.name,
        path: raw.location,
        folder: raw.folder,
        isDefault: raw.location.startsWith(CDMManager.PRE_DEFINED),
        documentType: CDMDocumentType.ENTITY,
        id: raw.id,
        type: 'documentItem',
    } as DocumentItem;
}

export const mapToEntity = (raw: any, typeAttributes?: TypeAttribute[], entityAttributes?: EntityAttribute[], version?: string): Entity => {
    const entity = new Entity(raw.id, raw.name, raw.path);
    entity.description = raw.description;
    if (!isEmpty(raw.extendsEntity)) entity.extendsEntity = raw.extendsEntity;
    entity.imports = raw.referenceImportPaths;
    entity.isDefault = raw?.isDefault || !raw?.path || raw?.path?.startsWith(CDMManager.PRE_DEFINED);
    entity.type = raw.type;
    entity.targetManifest = raw?.targetManifest;
    entity.primaryKey = raw?.primaryKey;
    entity.content = raw?.content;
    entity.versions = raw?.versions || [];
    entity.latestVersion = raw?.latestVersion || '1.0.0';
    if (typeAttributes) entity.typeAttributes = typeAttributes;
    else for (const ta of raw.typeAttributes) {
        const typeAttr = new TypeAttribute(ta.name, ta.dataType);
        typeAttr.isPrimaryKey = ta.purpose === 'identifiedBy' ? true : false;
        typeAttr.purpose = ta.purpose;
        typeAttr.description = ta.description;
        typeAttr.nullable = ta.isNullable;
        typeAttr.isDefault = entity.isDefault;
        typeAttr.choices = ta?.choices || [];
        if (ta.dataType === "string") typeAttr.maxLength = ta.maxLength;
        entity.addTypeAttribute(typeAttr);
    }
    if (entityAttributes) entity.entityAttributes = entityAttributes;
    else for (const ea of raw.entityAttributes) {
        entity.addEntityAttribute(mapToEntityAttribute(ea, entity.isDefault));
    }
    if (raw.parent) entity.parent = raw.parent;
    entity.currentVersion = version ? version : entity.latestVersion;
    
    return entity;
}

export const mapToFolder = (raw: any) => {
    return {
        name: raw,
        documentType: CDMDocumentType.ENTITY_FOLDER,
    }
}

export const mapToEntityAttribute = (raw: any, isDefault: boolean = false): EntityAttribute => {
    return new EntityAttribute({
            id: raw.associatedEntityId,
            path: raw.associatedEntityPath,
            name: raw.associatedEntityName
        },
        raw.associatedEntityTypeAttribute,
        raw.description,
        raw.foreignKeyAttribute.typeAttributeName,
        raw.foreignKeyAttribute.dataType,
        isDefault)
};

export interface CreateEntityAttribute {
    associatedEntityId: number;
    associatedEntityName: string;
    associatedEntityTypeAttribute: string;
    associatedEntityPath: string;
    description: string;
    traitReference: string;
    simple: string;
    foreignKeyAttribute: Partial<{
        typeAttributeName: string;
        purpose: string;
        dataType: string;
    }>
    version?: string;
}

export interface UpdateEntityAttribute extends CreateEntityAttribute {

}