import React, {useEffect, useRef, useState} from "react";
import {CssBaseline, FormControlLabel, IconButton, Switch, Tooltip} from "@material-ui/core";
import {observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import {PackageFilter} from "../../core/services/PackageService";
import Package from "../../core/observables/Package";
import {Link} from "react-router-dom";
import PackageCards from "./PackageCards";
import {LoadingProgress} from "../LoadingProgress";
import axios, {CancelTokenSource} from "axios";
import SelectOrganisationDialog from "../organisation/SelectOrganisationDialog";
import useQuery from "../router/useQuery";
import Header from "../Header";
import useService from "../service/useService";
import { UploadPackage } from "../../core/observables/Instance";
import {useSnackbar} from "notistack";
import { CDMManager } from "../../core/CDMManager";
import { NegroniIcon } from "../icon/NegronIIcon";

interface PackageViewProps {
}

const packageStyles = makeStyles({
    root: {
        display: 'block',
    },
    imageCirle: {
        borderRadius: '50%',
        width: 40,
    },
    navigatorIcon: {
        minWidth: 35,
    },
    content: {
        margin: '10px',
        marginTop: '74px',
        height: 'calc(100% - 74px)',
    }
});

export const PackageView: React.FC<PackageViewProps> = () => {
    const classes = packageStyles();
    const {packageService, workspaceService} = useService();
    const [packages, setPackages] = useState<Package[]>([]);
    const [privatePackageSwitch, setPrivatePackageSwitch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [openSelectOrgDialog, setOpenSelectOrgDialog] = useState(false);
    const loadPackagesToken = useRef<CancelTokenSource>();
    const query = useQuery();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        const cancelTokenSource = loadPackages();
        return () => cancelTokenSource.cancel();
    }, [privatePackageSwitch]);

    const loadPackages = (): CancelTokenSource => {
        setIsLoading(true);
        const cancelTokenSource = axios.CancelToken.source();
        packageService.getPackage(privatePackageSwitch ? PackageFilter.PRIVATE : PackageFilter.PUBLIC, cancelTokenSource.token)
            .then(packages => {
                setPackages(packages)
                setIsLoading(false);
            });
        return cancelTokenSource;
    }

    const handlePrivatePackageSwitch = () => {
        setPrivatePackageSwitch(!privatePackageSwitch);
    }

    const handleOrganizationClose = () => {
        setOpenSelectOrgDialog(false)
    };

    const handlePackageStatusChanged = () => {
        if (loadPackagesToken.current) loadPackagesToken.current?.cancel();
        loadPackagesToken.current = loadPackages();
    }

    const handleUploadPackage = (packageId: number, uploadPackage: UploadPackage): Promise<string | undefined> => {
        return workspaceService.uploadPackage(packageId, uploadPackage)
            .then(response => {
                enqueueSnackbar(`${response.message}`, {variant: 'success'});
                return response.result;
            })
            .catch(error => {
                enqueueSnackbar(`Failed to upload package: ${error}`, {variant: 'error'});
                return error || 'Failed to upload package';
            });
    }

    return <div className={classes.root}>
        <CssBaseline/>
        <SelectOrganisationDialog
            open={openSelectOrgDialog}
            onClose={handleOrganizationClose}
        />
        <Header
            onStartOnBoarding={() => {}}
            onGuidedTours={() => {}}
        >
            <Tooltip title='Home'>
                    <Link
                    onClick={() => {
                        if(localStorage.getItem(CDMManager.NEGRONI_LAST_VISIT)  === 'Admin' ) {
                            localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                        }
                    }}
                    to='/'
                    style={{textDecoration: 'none'}}
            >
                    <IconButton color='primary'>
                        <NegroniIcon iconClass="home-icon" color="#ddd1f0" />
                    </IconButton>
                </Link>
            </Tooltip>
        </Header>
        <div className={classes.content}>
            <FormControlLabel
                style={{display: 'flex', justifyContent: 'flex-end'}}
                control={
                    <Switch
                        checked={privatePackageSwitch}
                        onChange={handlePrivatePackageSwitch}
                        color="primary"
                    />
                }
                label="Private Package"
            />
            {packages.length > 0 ? (isLoading ? <LoadingProgress/> : <PackageCards
                selection={query.get('new')}
                defaultPackages={packages}
                onPackageStatusChanged={handlePackageStatusChanged}
                loadPackages={loadPackages}
                onPackageUpload={handleUploadPackage}
            />) : <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                padding: '10px'
            }}>No packages to display</div>}
        </div>
    </div>
}

export default observer(PackageView);