import React, {useRef, useState, useEffect} from "react";
import {observer} from "mobx-react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogContentText,
    CircularProgress,
    FormControl,
    FormHelperText,
    TextField,
    Switch,
    Grid,
} from "@material-ui/core";
import CreatableSelect from 'react-select/creatable';
import useService from "../service/useService";
import axios, { CancelTokenSource } from "axios";
import { mapToUploadPackage, PackageState, UploadPackage } from "../../core/observables/Instance";
import useConfirm from "../confirm/useConfirm";

interface PackageUploadDialogProps {
    manifestName: string;
    packageId: number;
    version: string;
    open: boolean;
    onClose: () => void;
    onUpload: (packageId: number, uploadPackage: UploadPackage) => Promise<string | undefined>;
}

export const PackageUploadDialog: React.FC<PackageUploadDialogProps> = ({
    manifestName,
    packageId,
    version,
    open,
    onClose,
    onUpload,
}) => {
    const {workspaceService} = useService();
    const workspaceOptionsCancelToken = useRef<CancelTokenSource | undefined>(undefined);
    const [instance, setInstance] = useState<string>('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [password, setPassword] = useState<string>('');
    const [started, setStarted] = useState(false);
    const [state, setState] = useState<PackageState>(PackageState.LOADED);
    const [manual, setManual] = useState(false);
    const [validUrl, setValidUrl] = useState(true);
    const [urlErrorMessage, setUrlErrorMessage] = React.useState<string | null>(null);
    const confirm = useConfirm();


    useEffect(() => {
       setIsLoading(false);
       setErrorMessage(null);
    }, []);


    useEffect(() => {
        setState(started ? PackageState.STARTED : PackageState.LOADED);
    }, [started]);

    useEffect(() => {
        if(!validUrl) {
            setUrlErrorMessage('Must be a valid HTTP URL')
        } else {
            setUrlErrorMessage('')
        }
    }, [validUrl])

    const isUrlValid = (url: string) => {
        let givenUrl
        try {
            givenUrl = new URL(url)
        } catch (_) {
            return false
        }
        return givenUrl.protocol === "http:" || givenUrl.protocol === "https:"
    }

    const handleWorkspacesOptions = (page: number, size: number) => {
        if( workspaceOptionsCancelToken.current ) workspaceOptionsCancelToken.current?.cancel();
        workspaceOptionsCancelToken.current = axios.CancelToken.source();

        return workspaceService.getWorkspaces(page, size, workspaceOptionsCancelToken.current.token)
            .then(response => {
                workspaceOptionsCancelToken.current = undefined;
                return {
                    ...response,
                    result: response.result.map(instance => ({
                        label: instance.name,
                        value: instance.path,
                    }))
                }
            });
    };

    const hasError = (): boolean => {
        return errorMessage !== null && errorMessage.length > 0;
    }

    const handlePackageStateSwitch = () => {
        setStarted(!started);
    }

    const handleUploadPackage = (password: string, instance: string, version: string, state: PackageState) => {
        if(!manual) {
            instance = 'https://' + instance;
        }
        if(isUrlValid(instance)) {
            setIsLoading(true);
            workspaceService.getWorkspacePackages( mapToUploadPackage(password, instance, version, state) )
                .then(response => {
                    let workspacePackages: any[] = response?.getWorkspacePackages?.items
                    if(workspacePackages.find(workspacePackage => workspacePackage?.name === manifestName)) {
                        confirm({message: 'The manifest being uploaded already exists, this will be override the following package. Do you wish to proceeed', title: 'Package Override'})
                            .then(() => {
                                onUpload(packageId, mapToUploadPackage(password, instance, version, state))
                                    .then(response => {
                                        setIsLoading(false);
                                        if (response !== 'Ok') {
                                            setPassword('');
                                        } else {
                                            setPassword('');
                                            setInstance('');
                                            setManual(false);
                                            setStarted(false);
                                            setValidUrl(true);
                                            setUrlErrorMessage('');
                                            onClose();
                                        }
                                    }).catch(error => {
                                        setPassword('');
                                    })
                            }).finally(() => {
                                setPassword('');
                                setInstance('');
                                setManual(false);
                                setStarted(false);
                                setValidUrl(true);
                                setUrlErrorMessage('');
                                onClose();
                            });
                    } else {
                        onUpload(packageId, mapToUploadPackage(password, instance, version, state))
                            .then(response => {
                                setIsLoading(false);
                                if (response !== 'Ok') {
                                    setPassword('');
                                } else {
                                    setPassword('');
                                    setInstance('');
                                    setManual(false);
                                    setStarted(false);
                                    setValidUrl(true);
                                    setUrlErrorMessage('');
                                    onClose();
                                }
                            }).catch(error => {
                                setPassword('');
                            })
                    }
                })
        } else {
            setValidUrl(false)
        }
    }

    return <Dialog
        open={open}
        onClose={onClose}
    >
        <form onSubmit={e => {
            e.preventDefault();
            handleUploadPackage(password, instance, version, state);
        }}>
            <DialogTitle>Upload Martini Package</DialogTitle>
            <DialogContent dividers style={{minHeight: '200px', minWidth: '450px'}}>
                <FormControl variant='filled' fullWidth={true} error={hasError() || !validUrl}>
                    <FormHelperText style={{margin: 0}}>{errorMessage}</FormHelperText>
                    <FormHelperText style={{margin: 0}}>{urlErrorMessage}</FormHelperText>
                    <DialogContentText>Instance</DialogContentText>
                    <Grid container alignItems='center' spacing={3}>
                        <Grid xs={12} item>
                            <CreatableSelect
                            placeholder="Choose or enter instance URL"
                            formatCreateLabel={input => input}
                                disableServerSearch={true}
                                autoFocus={true}
                                isClearable={true}
                                getOptions={handleWorkspacesOptions}
                                valueMapper={(value: any) => value ? ({label: value, value}) : null}
                                onChange={(v:any, a: any) => {
                                   if (a.action === "create-option") setManual(true);
                                    setErrorMessage(null);
                                    if(v) {
                                        setInstance(v.value);
                                    } else {
                                        setErrorMessage('You must select an instance');
                                    }
                                }}
                                onValueChanged={(instance: React.SetStateAction<string>) => {
                                    setErrorMessage(null);
                                    if(instance) {
                                        setInstance(instance);
                                    } else {
                                        setErrorMessage('You must select an instance');
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <DialogContentText style={{marginTop: '20px', marginBottom: '0'}}>Package State</DialogContentText>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item>Loaded</Grid>
                        <Grid item>
                            <Switch
                                size='medium'
                                checked={started}
                                onChange={handlePackageStateSwitch}
                                color='primary'
                            />
                        </Grid>
                        <Grid item>Started</Grid>
                    </Grid>
                    <TextField
                        label='Account Password'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type='password'
                        required
                        fullWidth
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setInstance('');
                    setPassword('');
                    setStarted(false);
                    setManual(false);
                    setValidUrl(true);
                    setUrlErrorMessage('');
                    onClose();
                }}>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    color='secondary'
                    variant='contained'
                    disableElevation
                    disabled={instance === '' || hasError() || isLoading || password === ''}
                    onClick={() => {
                        handleUploadPackage(password, instance, version, state);
                    }}
                    startIcon={isLoading && <CircularProgress size={18}/>}
                >
                    Upload
                </Button>
            </DialogActions>
        </form>
    </Dialog>
}

export default observer(PackageUploadDialog);